import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { orderManagementActions } from '../../action/SVTOrderManagementAction';
import { menuManagementAction } from '../../action/SVTMenuManagementAction';
import SVTProductItems from './SVTProductItems';
import { isSameDay, subDays, isBefore } from 'date-fns';
import { sort } from 'fast-sort';

export const allFilterHelper = (productList, filterType) => {
  switch (filterType) {
    case 'Today':
      return getAllTodayProduct(productList);
    case 'Yesterday':
      return getAllYesterdayProduct(productList);
    case 'This Week':
      return getAllLastWeekProduct(productList);
    case 'Older':
      return getAllOlderProduct(productList);
    default:
      console.warn(
        'Some unexpected filterType passed in allFilterHelper options'
      );
      return [];
  }
};

/**
 * It will filter product that updated on today
 * @param {Array<object>} productList
 * @returns {Array<object>}
 */
export const getAllTodayProduct = (productList) => {
  const todayTime = new Date();

  return productList.filter((product) =>
    isSameDay(todayTime, new Date(product.lastUpdated))
  );
};

/**
 * It will filter product that updated on yesterday
 * @param {Array<object>} productList
 * @returns {Array<object>}
 */
export const getAllYesterdayProduct = (productList) => {
  const todayTime = new Date();
  const yesterdayTime = subDays(todayTime, 1);

  return productList.filter((product) =>
    isSameDay(yesterdayTime, new Date(product.lastUpdated))
  );
};

/**
 * It will filter product that updated on last week except today and yesterday
 * @param {Array<object>} productList
 * @returns {Array<object>}
 */
export const getAllLastWeekProduct = (productList) => {
  return productList.filter((product) =>
    getAllLastWeekProductFilterHelper(product)
  );
};

/**
 *  It will filter product which is older than 7 days
 * @param {Array<object} productList
 * @returns {Array<object>}
 */
export const getAllOlderProduct = (productList) => {
  return productList.filter((product) =>
    getAllOlderProductFilterHelper(product)
  );
};

export const getAllLastWeekProductFilterHelper = (productItem) => {
  const todayTime = new Date();
  const yesterdayTime = subDays(todayTime, 1);
  const lastWeekTime = subDays(todayTime, 6);
  const productItemTime = new Date(productItem.lastUpdated);
  if (isSameDay(todayTime, productItemTime)) {
    return false;
  }
  if (isSameDay(yesterdayTime, productItemTime)) {
    return false;
  }
  if (isBefore(productItemTime, lastWeekTime)) {
    return false;
  }
  return true;
};

export const getAllOlderProductFilterHelper = (productItem) => {
  const todayTime = new Date();
  const lastWeekTime = subDays(todayTime, 6);
  const productItemTime = new Date(productItem.lastUpdated);
  if (isBefore(productItemTime, lastWeekTime)) {
    return true;
  }
  return false;
};

const allSection = ['Today', 'Yesterday', 'This Week', 'Older'];
export const convertTZ = (date, tzString) => {
  const currentTime = new Date(
    (typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', {
      timeZone: tzString,
    })
  );
  return new Date(currentTime.getTime() + 64000);
};

function SVTMenuMarkedUnavailable(props) {
  const { currentActiveTab, allUnavailableProductsList } = props;
  return (
    <div
      className='product-list-container'
      data-test='menuUnavailableContainer'
    >
      <section>
        <div className='fix-block-for-sticky'></div>
        {allUnavailableProductsList &&
          allSection.map((section) => {
            const currentProductList = sort(
              allFilterHelper(allUnavailableProductsList, section)
            ).desc((product) => new Date(product.lastUpdated));
            if (currentProductList.length === 0) {
              return null;
            }
            return (
              <>
                <div
                  data-test={section}
                  className='ml-20 fw-bold letter-spacing-O50 dark-shade-gray sticky-header mb-20'
                >
                  <div className='sticky-header-right-rectangle'></div>
                  <div className='sticky-header-text'>{section}</div>
                </div>
                <SVTProductItems
                  currentProductList={currentProductList}
                  currentActiveTab={currentActiveTab}
                />
              </>
            );
          })}
      </section>
    </div>
  );
}

export function mapState(state) {
  const { orderManagementRelated, menuManagementRelated } = state;
  const { showMenuPopUp } = orderManagementRelated;
  const { productStatus, totalUnavailableCount, allUnavailableProductsList } =
    menuManagementRelated;
  return {
    showMenuPopUp,
    productStatus,
    totalUnavailableCount,
    allUnavailableProductsList,
  };
}
const actionCreators = {
  setShowMenuPopUp: orderManagementActions.setShowMenuPopUp,
  updateProductStatus: menuManagementAction.updateProductStatus,
  setUnavailableItemsCount: menuManagementAction.setUnavailableItemsCount,
  getTotalUnavailableItemList: menuManagementAction.getTotalUnavailableItemList,
  getTotalUnavailableCount: menuManagementAction.getTotalUnavailableCount,
  setMenuModifiers: menuManagementAction.setMenuModifiers,
  setCurrentActiveProduct: menuManagementAction.setCurrentActiveProduct,
  setCurrentActiveModifier: menuManagementAction.setCurrentActiveModifier,
  setShowTopMsg: orderManagementActions.setShowTopMsg,
  getRecentTabProductList: menuManagementAction.getRecentTabProductList,
  setModifierProductKey: menuManagementAction.setModifierProductKey,
  setOptionName: menuManagementAction.setOptionName,
};

export default compose(
  withRouter,
  connect(mapState, actionCreators)
)(SVTMenuMarkedUnavailable);
