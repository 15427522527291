import React, { useState } from 'react';
import search from '../../images/search.svg';
import arrow_up from '../../images/arrow_up.svg';
import arrow_down from '../../images/arrow_down.svg';
import Xcross from '../../images/cross_mark.svg';
import { Col, Modal, Row, Dropdown } from 'react-bootstrap';
import SVTHeaderComponent from './SVTHeaderComponent';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { orderManagementActions } from '../../action/SVTOrderManagementAction';
import cross_icon from '../../images/cross_icon.svg';
import loading_svg from '../../images/loading_svg.svg';
import Risk_ticket from '../../images/Risk_ticket.svg';
import ticket_logged from '../../images/ticket_logged.svg';

const breakLineTag = '<p><br></p>\n';
const orderHelp1 =
  '<p><strong>Uber Eats:</strong></p>\n' +
  '<p>If you forgot an item and the food has not been delivered, you can contact the Home Delivery Aggregator directly for support.&nbsp;</p>\n' +
  '<p>If the order has been delivered or the driver cannot return, contact Uber Support 800-253-9377.&nbsp;' +
  'Uber will reach out to the customer to see if they still want the missing item. ' +
  'Uber will adjust the total sale of the order &amp; refund the customer for the missing item &ndash; if they do not want the missing item.&nbsp;</p>\n' +
  '<p></p>\n' +
  '<p><strong>Deliveroo:&nbsp;</strong></p>\n' +
  '<p>For any live order issues, including a missing item for a customer, you will need to contact Deliveroo directly on 0203699 9977 or email support@deliveroo.co.uk</p>';

const orderHelp2 =
  '<p><strong>Uber Eats:</strong></p>\n' +
  `<p>Reach out directly to coordinate a return of the order to the restaurant.&nbsp;</p>\n${breakLineTag}` +
  '<p>If you are unable to contact the delivery driver person directly, you may call Uber Support 800-253-9377,&nbsp;' +
  `Uber will advise the customer on a potential delay due to mix up.</p>\n${breakLineTag}` +
  '<p><strong>Deliveroo:&nbsp;</strong></p>\n' +
  '<p>For any live order issues, including a wrong delivery person, you will need to contact Deliveroo directly on 0203 699 9977 or email support@deliveroo.co.uk</p>';

const orderHelp3 =
  '<ol>\n' +
  '    <li>When an order is placed by a customer, you will be notified by a bell notification in Order In&nbsp;</li>\n' +
  '    <li>To acknowledge orders from delivery partners, tap &ldquo;Accept&rdquo;.&nbsp;Once accepted, the new order will flow in the Active Order panel. ' +
  'You will then be able to print a receipt &nbsp;</li>\n' +
  '    <li>Use the receipt to pick and pack your orders and staple it to the bag.</li>\n' +
  '</ol>';

const orderHelp4 = `
  <p>Should you need to temporarily pause Order In for some unexpected reason you can do so in Order In.</p>
  <ol>
    <li>Click on the settings icon on the top right of the screen.</li>
    <li>Scroll to the bottom of the page where &lsquo;Temporarily Pause Orders&rsquo; is located.&nbsp;</li>
    <li>Select the reason for pausing. You must select an option to proceed.&nbsp;</li>
    <li>Tap &lsquo;Pause Order&rsquo;.</li>
    <li>You will be prompted to confirm whether you would like to go ahead, in which you will need to tap &lsquo;Pause Orders&rsquo;.&nbsp;</li>
    <li>Your store&rsquo;s status will now appear as &rdquo;Offline&rdquo; for delivery partners.</li>
  </ol>
`;

const orderHelp5 =
  '<p>You can directly cancel an order in Order In by clicking on &ldquo;Order Help&rdquo; &gt;&gt; &ldquo;Cancel order&rdquo;</p>\n' +
  '<p>Alternatively, you can process with the following:</p>\n' +
  '<p>Locating the Home Delivery Partner support number&nbsp;</p>\n' +
  '<ol>\n' +
  '    <li>You can access the Home Delivery Partner&rsquo;s support team through the &lsquo;Order Help&rsquo; button ' +
  'which is available within the order detail view or the incoming orders view.&nbsp;</li>\n' +
  '    <li>Once selected, click on &lsquo;Contact Support&rsquo;&nbsp;</li>\n' +
  '    <li>The relevant partner&rsquo;s contact number for that order will pop up in a separate window. Tap &lsquo;close&rsquo; to exit the view.</li>\n' +
  '</ol>\n' +
  '<p>Contacts a customer to cancel an order (Uber Eats)&nbsp;</p>\n' +
  '<ol>\n' +
  '    <li>Customers details are provided in the incoming orders screen in the section below the order number or below the driver status in the order details page.&nbsp;</li>\n' +
  `</ol>\n${breakLineTag}` +
  '<p>For more information, please refer to your Order In Home Delivery manual&nbsp;</p>';

const orderHelp6 =
  '<p>Editing an Order an out of stock / unavailable item ordered by a customer.</p>\n' +
  '<ol>\n' +
  '  <li>To edit an incoming order, select &ldquo;Order Help&rdquo; at the bottom of the incoming orders screen</li>\n' +
  '  <li>Select &ldquo;Edit Order&rdquo;</li>\n' +
  '  <li>When the edit order button is clicked, you will be able to see additional editing options such as a check box a &ldquo;+&rdquo; or &ldquo;-&ldquo; button.&nbsp;</li>\n' +
  '</ol>';

const orderHelp7 = `
  <p>You will see a countdown associated to orders in incoming Order Tab. The countdown displays the your processing time.</p>
  <ol>
    <li>The countdown will varies for Deliveroo Orders based on the size of the Order.</li>
    <li>The countdown will have a static 15 mins for Uber Eat orders.</li>
  </ol>
`;

const orderHelp8 = `
  <p>Diebold is the first point of contact when you experience any issues related to OrderIn or to the printer.
  Please log the issue and the right team will get in touch with you.</p>
  <p>
    DN: 02037 884 658<br />
    DN: <a target="_blank" href="https://dnallconnectlive2.dieboldnixdorf.com">https://dnallconnectlive2.dieboldnixdorf.com</a>
  </p>
`;

const orderHelp9 = `
  <p>To help better with your inquiry please follow the below:</p>
  <ol>
    <li>Store Name</li>
    <li>Title of the issue</li>
    <li>Describe the issue</li>
    <li>Support the description with a pic and or video if possible</li>
  </ol>
`;

const orderHelp10 =
  `<p>&nbsp;In your Settings Tab on Order In:</p>\n${breakLineTag}` +
  '<p>1. Once you have paused all orders, your status will appear as &lsquo;offline&rsquo; as shown on the top right section of your app.&nbsp;</p>\n' +
  '<p>2. To take your store offline and resume orders, select the &lsquo;Resume Orders&rsquo; button in the setting page.&nbsp;</p>\n' +
  '<p>3. Your store&rsquo;s status will now change to &lsquo;Online&rsquo; and customers will be able to order from your store</p>';

const orderHelp11 =
  `<p>Please perform the below:</p>\n${breakLineTag}` +
  '<p>1. Return the tablet to the charger and check the charging LED illuminates.</p>\n' +
  '<p>2. If the LED does not illuminate, check the power unit is plugged into the back of the dock and into the wall socket.</p>\n' +
  '<p>3. If the tablet still does not power on, contact the IT Service Desk.</p>\n' +
  '<p>&nbsp;</p>\n' +
  '<p>Service Desk phone: 02037 884 658</p>\n' +
  '<p>Support Portal: <a href="https://dnallconnectlive2.dieboldnixdorf.com" target="_blank">https://dnallconnectlive2.dieboldnixdorf.com</a> </p>';

const orderHelp12 =
  '<p><strong>Record what&rsquo;s happening</strong>:&nbsp;</p>\n' +
  '<ul>\n' +
  '    <li>Take note of the date, time and order number (provide this to the store manager if not on site when issue occurs</li>\n' +
  '    <li>Share a picture of both tablets to the store manager.</li>\n' +
  '</ul>\n' +
  '<p><strong>Check the connection&nbsp;</strong></p>\n' +
  '<ul>\n' +
  '    <li>Try and check that you are connected to the internet on the Retail tablet.</li>\n' +
  '    <li>Refresh the browser in chrome.</li>\n' +
  '    <li>Close the browser and click the &ldquo;Order in&rdquo; icon to log back in (requires store credentials).</li>\n' +
  `</ul>\n${breakLineTag}` +
  '<p><strong>Swap to other tablet:&nbsp;</strong></p>\n' +
  '<p>If Order does not prompt in BP tablet, ' +
  'accept and proceed using the Home Delivery tablet to complete the order (leaving a note with the details of the issues faced for the store manager)</p>';
const orderHelp13 =
  '<ul>\n' +
  '    <li>Log back in with store credentials (if known)</li>\n' +
  '    <li>Follow up with the store manager for the store credentials</li>\n' +
  '    <li>Contact Store Comms &amp; contact TSOL&nbsp;</li>\n' +
  '</ul>';

const orderHelp14 =
  '<p><strong>Record what&rsquo;s happening:</strong></p>\n' +
  '<ul>\n' +
  '    <li>Note down the error message details, date and time that it occurred.&nbsp;</li>\n' +
  '    <li>Was the tablet in use when the message appeared? If so, what actions were being performed?</li>\n' +
  '</ul>\n' +
  '<p><strong>Check the Connection:</strong></p>\n' +
  '<ul>\n' +
  '    <li>Check error message instructions, potential prompt to log in again</li>\n' +
  '    <li>Check the restaurant is online in Order In</li>\n' +
  '    <li>If Order In is no longer able to log in, please use the Home Delivery until the issue can be resolved.</li>\n' +
  '</ul>';

const orderHelp15 = `
  <ul>
    <li>When there is no internet connection, an error screen with a BIP will appears on OrderIn; the BIP is to alert you of the internet issue.</li>
    <li>Your store will be closed to customers after the first auto rejection.</li>
    <li>When the internet is back, the error screen will automatically disappear.</li>
    <li>You will see OrderIn screen with a notification banner. Please follow the instruction on the banner to open the store to customers..</li>
  </ul>
`;

const orderHelp16 =
  '<p>Please contact the IT Service Desk if you experience any technical issues relating to:</p>\n' +
  '<p>- Android tablet</p>\n' +
  '<p>- Wi-Fi network or internet connectivity</p>\n' +
  '<p>- Home Delivery printer</p>\n' +
  '<p>&nbsp;</p>\n' +
  '<p>Service Desk phone: 02037 884 658</p>\n' +
  '<p>Support Portal: <a href="https://dnallconnectlive2.dieboldnixdorf.com" target="_blank">https://dnallconnectlive2.dieboldnixdorf.com</a> </p>';

const orderHelp17 =
  '<p>If you experience any issues relating to:&nbsp;</p>\n' +
  '<ul>\n' +
  '    <li>Order Fulfillment</li>\n' +
  '    <li>Order Cancellations</li>\n' +
  '    <li>Permanent item removals</li>\n' +
  '</ul>\n' +
  '<p></p>\n' +
  '<p>Please contact TSOL (Trade Support OnLine)</p>';

const orderHelp18 =
  '<p>If you experience any issues relating to:&nbsp;</p>\n' +
  '<ul>\n' +
  '    <li>Menu or menu item enquiry&nbsp;</li>\n' +
  '    <li>Updating store hours</li>\n' +
  '    <li>Permanent store closures&nbsp;</li>\n' +
  '</ul>\n' +
  '<p></p>\n' +
  '<p>Please contact TSOL (Trade Support OnLine)</p>';

const padding = ['20px 20px 0px 20px', '0px 20px 0px 20px'];
const ticketCategory = 'Ticket Category';
const categoryBtnClassName = [
  'category-buttons category-buttons-focus ',
  'category-buttons ',
];
const category = [
  'Order Support',
  'Managing Orders',
  'Technical Issues',
  'Support Summary',
];
const data = [
  {
    id: 1,
    show: false,
    header: 'What if we forgot an item for an order?',
    data: orderHelp1,
    category: category[0],
  },
  {
    id: 2,
    show: false,
    header: 'What if we gave the delivery person the wrong order?',
    data: orderHelp2,
    category: category[0],
  },

  {
    id: 3,
    show: false,
    header: 'What happens when an order comes in?',
    data: orderHelp3,
    category: category[1],
  },
  {
    id: 4,
    show: false,
    header: 'How do I pause incoming orders?',
    data: orderHelp4,
    category: category[1],
  },
  {
    id: 5,
    show: false,
    header: 'How do I cancel incoming orders?',
    data: orderHelp5,
    category: category[1],
  },
  {
    id: 6,
    show: false,
    header: 'How do I edit an order?',
    data: orderHelp6,
    category: category[1],
  },
  {
    id: 7,
    show: false,
    header: 'What is the countdown on the Order?',
    data: orderHelp7,
    category: category[1],
  },

  {
    id: 8,
    show: false,
    header: 'What is the first point of contact when or if have issues?',
    data: orderHelp8,
    category: category[2],
  },
  {
    id: 9,
    show: false,
    header: 'What I should include in my ticket with Diebold?',
    data: orderHelp9,
    category: category[2],
  },
  {
    id: 10,
    show: false,
    header: 'Resuming Orders & going Online ',
    data: orderHelp10,
    category: category[2],
  },
  {
    id: 11,
    show: false,
    header: "What do I do when my tablet won't power on?",
    data: orderHelp11,
    category: category[2],
  },
  {
    id: 12,
    show: false,
    header:
      'I’m unable to process orders through the Order In App, but I can hear the notifications',
    data: orderHelp12,
    category: category[2],
  },
  {
    id: 13,
    show: false,
    header:
      'I’ve restarted the tablet and now Order In is asking for store credentials',
    data: orderHelp13,
    category: category[2],
  },
  {
    id: 14,
    show: false,
    header: 'Order In is displaying an error message',
    data: orderHelp14,
    category: category[2],
  },
  {
    id: 15,
    show: false,
    header: 'What happens when I have no internet connection?',
    data: orderHelp15,
    category: category[2],
  },

  {
    id: 16,
    show: false,
    header: 'Who do I contact for IT issues?',
    data: orderHelp16,
    category: category[3],
  },
  {
    id: 17,
    show: false,
    header: 'Who do I contact if I am having Live Order Issues?',
    data: orderHelp17,
    category: category[3],
  },
  {
    id: 18,
    show: false,
    header: 'Who do I contact for menu support?',
    data: orderHelp18,
    category: category[3],
  },
];
const dataHeader = data.map((item) => item.header.toLowerCase());
var arrOfData = [];
function findData(q) {
  const arrOfData = [];
  for (var i = 0; i < dataHeader.length; i++) {
    if (dataHeader[i].includes(q.toLowerCase())) {
      data[i].show = false;
      arrOfData.push(data[i]);
    }
  }
  return arrOfData;
}
const BorderBelow = () => <div className='border-below-accordion'></div>;

const AccordionView = ({ props }) => {
  const [show, setShow] = useState(props.show);
  return (
    <>
      <div
        className='help-header-text cursor-pointer'
        onClick={() => setShow(!show)}
      >
        <img
          src={show ? arrow_up : arrow_down}
          alt='arUp'
          type='button'
          onClick={() => setShow(!show)}
          className='accordion-view-padding-right-10'
        ></img>
        {props.header}
      </div>
      {show && (
        <div className='help-para-margin'>
          <div dangerouslySetInnerHTML={{ __html: props.data }} />
        </div>
      )}
      <BorderBelow />
    </>
  );
};

const GetHelp = (results) => {
  return (
    results.results?.length > 0 && (
      <>
        {results.results.map((item) => (
          <div className='pos-top' key={item.id}>
            <AccordionView props={item} />
          </div>
        ))}
      </>
    )
  );
};
const getClassNameforid = (id, fresults) => {
  return id === fresults.length - 1
    ? 'search-li-text-no-border'
    : 'search-li-text';
};
class SVTOrderHelpComponent extends React.Component {
  state = {
    query: '',
    results: [],
    showResults: true,
    showScroll: true,
    showCross: false,
    showResultsFound: false,
    showDataOff: category[0],
  };
  componentDidMount() {
    this.props.setCurrentPath(this.props.location.pathname);
  }

  onSearchClick = () => {
    document.getElementById('searchQuery').innerHTML = '';
    this.setState({
      showCross: false,
      showScroll: false,
      showResultsFound: false,
      query: '',
      showDataOff: category[0],
    });
  };

  handleChange = (event) => {
    const querydata = event.target.value;
    this.showScroll === true
      ? this.setState({
          query: querydata,
          results: findData(querydata),
          showResults: false,
        })
      : this.setState({
          query: querydata,
          results: findData(querydata),
          showResults: false,
          showScroll: true,
        });
    this.setState({ showCross: true });
  };
  handleKeyPress = (event) => {
    const keyPressed = event.key;
    if (keyPressed === 'Enter') {
      this.setState({
        showScroll: false,
        showResults: true,
        showResultsFound: true,
        showDataOff: '',
      });
    }
  };

  showThisAccordion = (r) => {
    this.setState({
      results: [r],
      showResults: true,
      showScroll: false,
      query: r.header,
      showResultsFound: true,
    });
  };

  getShowData = () => {
    const fdata = data.filter(
      (item) => item.category === this.state.showDataOff
    );
    if (fdata.length > 0) {
      fdata[0].show = true;
      return fdata;
    } else {
      return [];
    }
  };

  getshowDataOff = (category) => {
    return this.state.showDataOff === category
      ? categoryBtnClassName[0]
      : categoryBtnClassName[1];
  };

  classTextInputBox = () => {
    return this.state.showScroll && this.state.query.length > 0
      ? 'text-center help-input-box-shadow'
      : 'text-center help-input-shadow ';
  };
  classInputGroup = () => {
    return this.state.query.length > 0 &&
      this.state.showScroll &&
      this.state.results.length > 0
      ? 'input-group search-bar-bottom-border input-group-help'
      : 'input-group input-group-help';
  };
  valuefunc = () => {
    return this.state.query ? this.state.query : '';
  };

  FilterDataBasedOnCategory = (category) => {
    const ftrdata = data.filter((item) => item.category === category);
    this.setState({
      results: ftrdata,
      showScroll: false,
      showResultsFound: false,
      showDataOff: category,
    });
  };

  Suggestion = () => {
    const trimmedresults = [];
    let fresults = [];
    if (this.state.results.length > 0) {
      const queryL = this.state.query.length;
      for (var i = 0; i < this.state.results.length; i++) {
        const strt = this.state.results[i].header;
        const strd = this.state.results[i].data;
        const strtS = strt.toLowerCase();
        const idxOfQ = strtS.indexOf(this.state.query.toLowerCase());
        trimmedresults.push({
          id: this.state.results[i].id,
          header: this.state.results[i].header,
          header1: strt.slice(0, idxOfQ),
          header2: strt.slice(idxOfQ, idxOfQ + queryL),
          header3: strt.slice(idxOfQ + queryL, strt.length),
          show: false,
          data: strd,
        });
      }
    }
    if (trimmedresults.length > 4) {
      fresults = trimmedresults.slice(0, 4);
    } else {
      fresults = trimmedresults;
    }
    const options = fresults.map((r, id) => {
      return r.header2 ? (
        <li
          key={r.id}
          className={getClassNameforid(id, fresults)}
          onClick={() => this.showThisAccordion(r)}
        >
          {r.header1}
          <b>{r.header2}</b>
          {r.header3}
        </li>
      ) : (
        <li
          key={r.id}
          className='search-li-text'
          onClick={() => this.showThisAccordion(r)}
        >
          {r.header}
        </li>
      );
    });
    return <ul className='text-start help-list '>{options}</ul>;
  };

  render() {
    return (
      <>
        <SVTHeaderComponent />
        <div className='container-fluid padding-left-right-50'>
          <div className=' help-shadow font-help'>
            <div className=' text-center text-help-section  padding-bottom-30PX'>
              Help Section
            </div>
            <Row>
              <Col md={3} sm={2} lg={3} xl={3}></Col>
              <Col md={6} sm={6} lg={6} xl={6}>
                <div className={this.classTextInputBox()}>
                  <div className={this.classInputGroup()}>
                    <input
                      type='text'
                      autoComplete='off'
                      className='form-control input-help'
                      placeholder='Search help articles'
                      name='searchQuery'
                      id='searchQuery'
                      data-testid='searchQuery'
                      onChange={this.handleChange.bind(this)}
                      onKeyPress={this.handleKeyPress.bind(this)}
                      value={this.valuefunc()}
                    />
                    <a
                      className='d-flex align-self-center help-image-margin-right'
                      type='button'
                      id='button-addon2'
                    >
                      <img
                        src={this.state.showCross ? Xcross : search}
                        alt='cross'
                        onClick={() => this.onSearchClick()}
                      ></img>
                    </a>
                  </div>
                  {this.state.results &&
                    this.state.query &&
                    this.state.showScroll &&
                    this.Suggestion()}
                </div>
              </Col>
            </Row>
            <div className='button-scrolling'>
              <div className='pt-5 pb-3 button-array'>
                <button
                  id='btn-1'
                  className={this.getshowDataOff(category[0])} //"category-buttons"
                  type='button'
                  autoFocus=''
                  onClick={() => this.FilterDataBasedOnCategory(category[0])}
                >
                  Order Support
                </button>
                <button
                  id='btn-2'
                  className={this.getshowDataOff(category[1])} //"category-buttons"
                  type='button'
                  onClick={() => this.FilterDataBasedOnCategory(category[1])}
                >
                  Managing Orders
                </button>
                <button
                  type='button'
                  id='btn-3'
                  className={this.getshowDataOff(category[2])} //"category-buttons"
                  onClick={() => this.FilterDataBasedOnCategory(category[2])}
                >
                  Technical Issues
                </button>
                <button
                  id='btn-4'
                  className={this.getshowDataOff(category[3])} //"category-buttons"
                  type='button'
                  onClick={() => this.FilterDataBasedOnCategory(category[3])}
                >
                  Support Summary
                </button>
              </div>
            </div>
            {(this.state.showDataOff === 'Ticket' && (
              <div className='help-search-result-text pos-div'>
                <ShowTicketpage />
              </div>
            )) ||
              (this.state.query.length > 0 && (
                <>
                  {this.state.results.length > 0 ? (
                    <>
                      {(this.state.showResultsFound && (
                        <>
                          <div className='help-search-result-text pos-div'>
                            Showing search results for "{this.state.query}" ...
                            <GetHelp results={this.state.results} />
                          </div>
                        </>
                      )) || (
                        <div className='help-search-result-text'>
                          <GetHelp
                            results={data.filter(
                              (item) => item.category === this.state.showDataOff
                            )}
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <div className='help-search-result-text pos-div'>
                        No results found, try searching something different.
                        <BorderBelow />
                        <ShowTicketpage from_show={true} />
                        <BorderBelow />
                      </div>
                    </>
                  )}
                </>
              )) || (
                <div className='pos-div'>
                  <GetHelp results={this.getShowData()} />
                </div>
              )}
          </div>
        </div>
      </>
    );
  }
}

function mapState(state) {
  const { orderManagementRelated } = state;
  return { orderManagementRelated };
}
const actionCreators = {
  setCurrentPath: orderManagementActions.setCurrentPath,
};

export class ShowTicketpage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: !this.props.from_show,
      dropDowntext: ticketCategory,
      textAreatext: '',
      showTicketLogged: false,
      showTicketError: false,
      showTicketLogging: false,
    };
  }
  changeDropDownText(s) {
    this.setState({ dropDowntext: s });
  }
  handleChangeText = () => {
    const queryText = document.getElementById(
      'exampleFormControlTextarea11'
    ).value;
    this.setState({ textAreatext: queryText });
  };

  ticketGenerate = () => {
    this.setState({
      showTicketLogging: true,
      textAreatext: '',
      dropDowntext: ticketCategory,
    });
  };

  resetTicketStates = () => {
    this.setState({
      showTicketLogging: false,
      showTicketError: false,
      showTicketLogged: false,
    });
  };
  supportTicketDropDown = () => {
    return (
      this.state.show && (
        <div className='help-para-margin'>
          <div className='help-ticket-help-text'>
            If you are unable to find a solution to your query, you can raise a
            support ticket by filling out the form below.
          </div>
          <div className='help-ticket-category-text'>TICKET CATEGORY</div>

          <Dropdown drop='up' className='help-dropdown'>
            <Dropdown.Toggle
              id='dropdown-basic-help'
              className='success-border-btn px-3 shadow-none help-category-dropdown help-dropdown-menu-text'
            >
              {this.state.dropDowntext}
            </Dropdown.Toggle>
            <Dropdown.Menu
              className='help-dropdown-menu '
              align='start'
              style={{ height: '200px', overflowY: 'scroll' }}
            >
              <Dropdown.Item
                className='order-help-dropdown-menu-text ps-0 pt-2 pd-2'
                disabled={false}
                onClick={() => this.changeDropDownText('Orders not coming')}
              >
                <div className='help-dropdown-header-text'>
                  Orders not coming
                </div>
                <div className='help-dropdown-content-text'>
                  Tablets not recieved any order
                </div>
              </Dropdown.Item>
              <Dropdown.Divider />

              <Dropdown.Item
                className='order-help-dropdown-menu-text ps-0 pt-2 pd-2'
                disabled={false}
                onClick={() =>
                  this.changeDropDownText('Incorrect Information displayed')
                }
              >
                <div className='help-dropdown-header-text'>
                  Incorrect Information displayed
                </div>
                <div className='help-dropdown-content-text'>
                  Order related information is incorrect
                </div>
              </Dropdown.Item>
              <Dropdown.Divider />

              <Dropdown.Item
                className='order-help-dropdown-menu-text ps-0 pt-2 pd-2'
                disabled={false}
                onClick={() => this.changeDropDownText('Order Acceptence')}
              >
                <div className='help-dropdown-header-text'>
                  Order Acceptence
                </div>
                <div className='help-dropdown-content-text'>
                  Problems while accepting orders
                </div>
              </Dropdown.Item>
              <Dropdown.Divider />

              <Dropdown.Item
                className='order-help-dropdown-menu-text ps-0 pt-2 pd-2'
                disabled={false}
                onClick={() => this.changeDropDownText('Driver Updates')}
              >
                <div className='help-dropdown-header-text'>Driver Updates</div>
                <div className='help-dropdown-content-text'>
                  Problems with driver information and updates
                </div>
              </Dropdown.Item>
              <Dropdown.Divider />
            </Dropdown.Menu>
          </Dropdown>

          <div className='help-ticket-description-text'>Description</div>

          <Row>
            <Col md={8}>
              <textarea
                className='help-ticket-textarea'
                id='exampleFormControlTextarea11'
                rows='5'
                placeholder='Enter description'
                value={this.state.textAreatext}
                onChange={() => this.handleChangeText()}
              ></textarea>
            </Col>
          </Row>

          <div className='help-ticket-submit-button'>
            <button
              onClick={() => this.ticketGenerate()}
              disabled={
                this.state.dropDowntext !== ticketCategory &&
                this.state.textAreatext !== ''
                  ? false
                  : true
              }
              className={
                this.state.dropDowntext !== ticketCategory &&
                this.state.textAreatext !== ''
                  ? 'shadow-none help-ticket-submit-button-success'
                  : ' shadow-none help-ticket-submit-button-muted'
              }
            >
              <span className='help-ticket-submit-button-text'>Submit</span>
            </button>
          </div>
        </div>
      )
    );
  };

  render() {
    return (
      <>
        <div
          className='help-header-text cursor-pointer'
          onClick={() => this.setState({ show: !this.state.show })}
        >
          <img
            src={this.state.show ? arrow_up : arrow_down}
            alt='arUp'
            type='button'
            onClick={() => this.setState({ show: false })}
            className='accordion-view-padding-right-10'
          ></img>
          Create a support ticket
        </div>
        {this.supportTicketDropDown()}

        {/* Modal for submitting ticket */}
        {/* Logging Ticket - submitting */}
        {this.state.showTicketLogging && (
          <Modal
            show={this.state.showTicketLogging}
            size='md'
            aria-labelledby='contained-modal-title-vcenter'
            centered
          >
            <Modal.Header style={{ padding: padding[0] }}>
              <Modal.Title id='contained-modal-title-vcenter'>
                <div className='d-flex'>
                  <p className='m-0 px-0 normal-font fw-bold '>
                    Ticket creation
                  </p>
                </div>
              </Modal.Title>
              <img
                className='cursor-pointer'
                src={cross_icon}
                height='21.98px'
                width='21.98px'
                onClick={() =>
                  this.setState({
                    showTicketLogging: false,
                  })
                }
                alt='cross icon'
              />
            </Modal.Header>
            <Modal.Body className='m-0' style={{ padding: padding[1] }}>
              <hr className='m-0 contact-supp-hr-setting-others' />
              <Row className='help-ticket-loading-svg'>
                <img src={loading_svg} alt='loading' className='spin' />
              </Row>
              <Row className='help-ticket-loading-svg-text'>
                <div className='text-center help-ticket-submitting-request'>
                  Submitting request...
                </div>
                <div className='text-center help-ticket-please-wait'>
                  Please wait, while we submit the ticket.
                </div>
              </Row>
            </Modal.Body>
          </Modal>
        )}

        {/* Logging ticket - Error */}
        {this.state.showTicketError && (
          <Modal
            show={this.state.showTicketError}
            size='md'
            aria-labelledby='contained-modal-title-vcenter'
            centered
          >
            <Modal.Header style={{ padding: padding[0] }}>
              <Modal.Title id='contained-modal-title-vcenter'>
                <div className='d-flex'>
                  <p className='m-0 px-0 normal-font fw-bold '>
                    Ticket creation
                  </p>
                </div>
              </Modal.Title>
              <img
                className='cursor-pointer'
                src={cross_icon}
                height='21.98px'
                width='21.98px'
                onClick={() => this.setState({ showTicketError: false })}
                alt='cross icon'
              />
            </Modal.Header>
            <Modal.Body className='m-0' style={{ padding: padding[1] }}>
              <hr className='m-0 contact-supp-hr-setting-others' />
              <Row className='help-ticket-error-svg'>
                <img src={Risk_ticket} alt='error' width='72px' height='64px' />
              </Row>
              <Row className='help-ticket-error-svg-text'>
                <div className='text-center help-ticket-submitting-request'>
                  Unable to create a ticket
                </div>
                <div className='text-start help-ticket-please-wait-error'>
                  Sorry! An error was encountered while creating the ticket.
                  <br />
                  Please retry or try contacting the support team
                </div>
              </Row>
            </Modal.Body>

            <Modal.Footer style={{ padding: '15px 20px 20px 20px' }}>
              <button
                type='button'
                className='help-ticket-close-btn shadow-none help-ticket-btn-close-text'
                onClick={() => this.resetTicketStates()}
              >
                Close
              </button>
              <button
                type='button'
                onClick={() =>
                  this.setState({
                    showTicketError: false,
                    showTicketLogged: true,
                  })
                }
                className='help-ticket-retry-btn shadow-none help-ticket-btn-retry-text'
              >
                Retry
              </button>
            </Modal.Footer>
          </Modal>
        )}

        {/* Logging ticket - success */}
        {this.state.showTicketLogged && (
          <Modal
            show={this.state.showTicketLogged}
            size='md'
            aria-labelledby='contained-modal-title-vcenter'
            centered
          >
            <Modal.Header style={{ padding: padding[0] }}>
              <Modal.Title id='contained-modal-title-vcenter'>
                <div className='d-flex'>
                  <p className='m-0 px-0 normal-font fw-bold '>
                    Ticket creation
                  </p>
                </div>
              </Modal.Title>
              <img
                className='cursor-pointer'
                src={cross_icon}
                height='21.98px'
                width='21.98px'
                onClick={() => this.setState({ showTicketLogged: false })}
                alt='cross icon'
              />
            </Modal.Header>
            <Modal.Body className='m-0' style={{ padding: padding[1] }}>
              <hr className='m-0 contact-supp-hr-setting-others' />
              <Row className='help-ticket-logged-svg'>
                <img
                  src={ticket_logged}
                  alt='logged'
                  width='72px'
                  height='64px'
                />
              </Row>
              <Row className='help-ticket-error-svg-text'>
                <div className='text-center help-ticket-submitting-request'>
                  Ticket successfully created
                </div>
                <div className='text-start help-ticket-please-wait-error'>
                  <span className='help-ticket-your-reference'>
                    Your reference number is
                  </span>
                  <br />
                  <span className='help-ticket-ref-number'>
                    67TYGFBNMJKIO87
                  </span>
                </div>

                <div className='help-ticket-ack-text'>
                  An acknowledgement mail has been sent to ****abc@bp.com.
                </div>
              </Row>
            </Modal.Body>

            <Modal.Footer style={{ padding: '15px 20px 20px 20px' }}>
              <button
                type='button'
                className='help-ticket-retry-btn shadow-none help-ticket-btn-retry-text'
                onClick={() => this.resetTicketStates()}
              >
                Close
              </button>
            </Modal.Footer>
          </Modal>
        )}
      </>
    );
  }
}

export default compose(
  withRouter,
  connect(mapState, actionCreators)
)(SVTOrderHelpComponent);
