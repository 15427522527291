function OrderCategoryBarcode(order) {
  let content = '';

  const category = order?.catetory;
  const plu = order?.plu;
  const pluPrefix = plu?.slice(0, 3);
  const pluCode = plu?.split('prd')[1];
  const sku = order?.sku;
  const barcode = order?.barcodes;

  if (category !== '' && category !== undefined) {
    content += category;
  }

  if (pluPrefix === 'prd' && pluCode !== '' && pluCode !== undefined) {
    content += pluCode;
  }

  if (sku !== '' && sku !== undefined) {
    content += sku;
  }
  if (barcode !== '' && barcode !== 'None' && barcode !== undefined) {
    if (content !== '') {
      content += ' / ';
    }
    content += barcode;
  }

  return content;
}

export default OrderCategoryBarcode;
