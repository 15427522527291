import React from 'react';
import ubereats_receipt from '../../images/uberEatsStore.png';
import menulog_receipt from '../../images/logos/deliveroo/deliveroo_receipt.svg';

class SVTComponentToPrint extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      totalItemsCost: 0,
      showPrice: false,
    };
  }

  componentDidMount() {}
  formatAMPM = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const amOrPm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    return `${hours}:${minutes} ${amOrPm}`;
  };
  convertTZ = (date, tzString) => {
    const currentTime = new Date(
      (typeof date === 'string' ? new Date(date) : date).toLocaleString(
        'en-US',
        { timeZone: tzString }
      )
    );
    return new Date(currentTime.getTime() + 64000);
  };

  getAceeptedAt() {
    let accTime;
    if (!this.props.details?.cancelled_at) {
      accTime = this.props.details?.accepted_at;
    } else {
      accTime = this.props.details?.cancelled_at;
    }
    const dAccTime = accTime.split('T')[1];
    const tAccTime = dAccTime.split(':');
    let outputMsg = '';
    let amPm = '';
    let hourTime = '';
    if (Number(tAccTime[0]) > 12) {
      amPm = ' PM';
      hourTime = String(Number(tAccTime[0]) - 12);
    } else {
      amPm = ' AM';
      hourTime = tAccTime[0];
    }
    outputMsg += hourTime;
    outputMsg += ':';
    outputMsg += tAccTime[1];
    outputMsg += amPm;
    return outputMsg;
  }

  getPartnerSuppoortNumber() {
    let pSNumber =
      this.props?.partner && this.props?.partner[0]?.partner_support_phone;
    if (pSNumber) {
      if (pSNumber.startsWith('+')) {
        pSNumber = pSNumber.slice(1);
      }
      return `${pSNumber.slice(0, 3)} ${pSNumber.slice(3, 6)} ${pSNumber.slice(
        6
      )}`;
    } else {
      return 'N/A';
    }
  }

  getFloatFunction(itm) {
    if (itm?.price) {
      return itm?.price / 100;
    } else {
      return (
        parseFloat((itm?.unit_price / 100).toFixed(2)) *
        (itm?.quantity ? itm?.quantity : 1)
      );
    }
  }

  getFloatFunction2(item) {
    if (item?.unit_price) {
      return item?.unit_price / 100;
    } else {
      return (
        parseFloat((item?.price / item?.quantity / 100).toFixed(2)) *
        (item?.quantity ? item?.quantity : 1)
      );
    }
  }

  render() {
    let totalCost = 0;
    return (
      <div className='receipt_style print-receipt-font'>
        <div className='text-center'>
          {this.props.details?.partner_name === 'Uber Eats' ? (
            <img src={ubereats_receipt} alt='UberEats Receipt' />
          ) : (
            <img src={menulog_receipt} alt='Deliveroo Receipt' />
          )}
        </div>
        <div className='extra_small_text receipt_address_style'>
          {this.props.storeDetails?.store_address}
        </div>
        <div className='gray_solid_border_top pt-2 text-center extra_large_text'>
          {this.props?.details?.cancelled_at ? 'Cancelled' : 'Delivery'}
        </div>
        <div className='mt-3'>
          <div className='extra_small_text'>Order Number</div>
          <div className='extra_large_text'>
            {this.props.details?.partner_id?.toUpperCase()}
          </div>
        </div>
        <div className='my-2'>
          <div className='extra_small_text'>Order for:</div>
          <div className='extra_large_text'>
            {this.props.details?.customer_name}
          </div>
        </div>
        <div className='gray_solid_border_top padding-top-bottom-print-receipt'>
          <div className='extra_small_text'>Special Instructions</div>
          <div className='mt-1 small_text'>
            {this.props.details?.delivery_instructions}
          </div>
        </div>
        <div className='gray_solid_border_top padding-top-bottom-print-receipt'>
          {this.props.details?.order_items?.map((item, index) => {
            totalCost += parseFloat(this.getFloatFunction2(item));
            return (
              <div className='mt-1' key={index}>
                <ProductItem item={item} showPrice={this.state.showPrice} />
                {item?.children?.length > 0 &&
                  item?.children?.map((itm, idx) => {
                    totalCost += parseFloat(this.getFloatFunction(itm));
                    return (
                      <div key={idx}>
                        <ProductModifierItem
                          key={idx}
                          parentItem={item}
                          itm={itm}
                          partnerName={this.props.details?.partner_name}
                          showPrice={this.state.showPrice}
                        />
                        {itm?.children &&
                          itm.children.map((subModifier, indxSubModifier) => {
                            return (
                              <ProductModifierItem
                                key={indxSubModifier}
                                parentItem={itm}
                                itm={subModifier}
                                partnerName={this.props.details?.partner_name}
                                showPrice={this.state.showPrice}
                              />
                            );
                          })}
                      </div>
                    );
                  })}

                {item?.special_instructions && (
                  <div className='row print-notes-text spacing-bottom-print'>
                    <div className='note-print-margin col col-1  ps-1 pe-0'>
                      Note:
                    </div>
                    <div className='col col-10 px-0 mx-0 print-text-special-instruction'>
                      {item?.special_instructions}
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
        {this.state.showPrice && (
          <div className='my-2 padding-top-bottom-print-receipt gray_dashed_border_top'>
            <div className='d-flex small_text'>
              <div className='print-component-total text-end'>Total</div>
              <div className='ms-auto ps-2'>£{totalCost.toFixed(2)}</div>
            </div>
          </div>
        )}
        <div className='pt-2 padding-top-bottom-print-receipt gray_solid_border_top'>
          <div className='extra_small_text'>
            {this.props.details?.partner_name === 'Uber Eats' ? (
              <span>Uber Eats</span>
            ) : (
              <span>Deliveroo</span>
            )}{' '}
            Support Number:
          </div>
          <div className='extra_large_text'>
            {this.getPartnerSuppoortNumber()}
          </div>
          <div className='mt-2 extra_small_text'>
            <div className='d-flex'>
              <div>Order placed at:</div>
              <div className='ms-auto '>{this.props.details?.time}</div>
            </div>
            <div className='d-flex'>
              <div>
                {this.props.details?.cancelled_at
                  ? 'Order cancelled at:'
                  : 'Order accepted at:'}
              </div>
              {this.props.details?.cancelled_at ? (
                <div className='ms-auto ps-2'>
                  {this.formatAMPM(new Date(this.props.details?.cancelled_at))}
                </div>
              ) : (
                <>
                  {this.props.details?.accepted_at && (
                    <div className='ms-auto ps-2'>
                      {this.formatAMPM(
                        new Date(this.props.details?.accepted_at)
                      )}
                    </div>
                  )}
                  {this.props.details?.accepted_at === undefined && (
                    <div className='ms-auto ps-2'>
                      {this.formatAMPM(new Date())}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className='padding-top-bottom-print-receipt gray_solid_border_top extra_small_text text-center'>
          Thank you for ordering from us!
        </div>
      </div>
    );
  }
}

export default SVTComponentToPrint;
const getUnitPriceFunction = (itm) => {
  return itm?.unit_price
    ? (itm?.unit_price / 100).toFixed(2)
    : (itm?.price / itm?.quantity / 100).toFixed(2);
};
export function ProductItem(props) {
  const { item, showPrice } = props;
  return (
    <div
      className={'d-flex w-100 extra_small_text spacing-bottom-print'}
      key={item?.partner_id}
      data-test='productItemMainComponent'
    >
      <div
        className='font_bold fixed-width-count extra_small_text'
        style={{ textAlign: 'end' }}
      >
        x{item?.quantity ? item?.quantity : 1}
      </div>
      <div
        className={
          showPrice ? 'fixed-width-text  ' : 'fixed-width-text-no-price  '
        }
      >
        {item?.name}
      </div>
      {showPrice && (
        <div className='fixed-width'>£{getUnitPriceFunction(item)}</div>
      )}
    </div>
  );
}

export function ProductModifierItem(props) {
  const { itm, showPrice, partnerName } = props;
  return (
    <>
      <div
        className='d-flex w-100 extra_small_text spacing-bottom-print'
        key={itm?.plu}
        data-test='productModifierItemMainComponent'
      >
        <div className=' '></div>
        <div className='font_bold fixed-width-modifier-count extra_small_text'>
          x{itm?.quantity ? itm?.quantity : 1}
        </div>
        <div
          className={
            showPrice
              ? 'fixed-width-modifier-text  '
              : 'fixed-width--modifier-text-no-price  '
          }
        >
          <div>
            {partnerName === 'Deliveroo' ? itm?.name : itm?.description}
          </div>{' '}
        </div>
        {showPrice && (
          <div className='fixed-width '>£{getUnitPriceFunction(itm)}</div>
        )}
      </div>
    </>
  );
}
