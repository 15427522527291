import React from 'react';
import SVTMenuMarkedUnavailable from './SVTMenuMarkedUnavailable';
import SVTMenuAllItemsAvailable from './SVTMenuAllItemsAvailable';
import SVTAlphabetList from './SVTAlphabetList';
import SVTProductItems from './SVTProductItems';
import { sort } from 'fast-sort';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import SVTModifiersItems from './SVTModifiersItems';

export const filterWithChar = (data = [], char = '') => {
  return data.filter((data) => filterWithCharHelper(char, data.alter_name));
};
export const filterWithCharHelper = (char, productName = '') => {
  productName = productName.toUpperCase();
  return productName.startsWith(char);
};
export const allChar = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

const onComponentMount = (props) => {
  const {
    setEnabledLetters,
    currentActiveTab,
    allProductsList,
    allModifiersList,
  } = props;
  setEnabledLetters([]);
  var tmpList = [];
  let currentProductList = [];
  switch (currentActiveTab) {
    case 'single-tabletall-products':
      currentProductList = allProductsList;
      break;
    case 'single-tabletall-modifiers':
      currentProductList = allModifiersList;
      break;
    default:
      break;
  }
  allChar.forEach((char) => {
    const filteredProductList = filterWithChar(currentProductList, char);
    if (filteredProductList.length > 0) {
      tmpList.push(char);
    }
  });
  setEnabledLetters(tmpList);
};

function SVTProductItemsWrapper(props) {
  const {
    currentActiveTab,
    scrollTopPosition,
    allProductsList,
    allUnavailableProductsList,
    allModifiersList,
  } = props;
  const [enabledLetters, setEnabledLetters] = React.useState([]);
  const [activeLetter, setActiveLetter] = React.useState('');

  /**
   * @description Creates a list of all characters which have products starting with them
   */
  React.useEffect(() => {
    onComponentMount({
      setEnabledLetters,
      currentActiveTab,
      allProductsList,
      allModifiersList,
    });
  }, [currentActiveTab, allProductsList, allModifiersList]);

  const topRef = React.createRef();
  const alphaRefs = enabledLetters.reduce((acc, value) => {
    acc[value] = React.createRef();
    return acc;
  }, {});

  /**
   * @description Changes the active letter on alphabet list based on scroll position and current display
   */
  React.useEffect(() => {
    const finalPosition =
      topRef.current?.offsetTop + topRef.current?.clientHeight;
    var tmpChar = '';
    enabledLetters.map((char) => {
      if (finalPosition >= alphaRefs[char].current?.offsetTop) {
        tmpChar = char;
      }
    });
    setActiveLetter(tmpChar);
  }, [scrollTopPosition, enabledLetters]);

  /**
   * @description scrolls the window to show products starting from the character selected
   * @param {string} char
   */
  const handleAlphabetClick = (char) => {
    alphaRefs[char].current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  if (currentActiveTab === 'marked-as-unavailable') {
    if (allUnavailableProductsList?.length === 0) {
      return <SVTMenuAllItemsAvailable />;
    }
    return <SVTMenuMarkedUnavailable />;
  }
  return (
    <div
      className='product-list-container'
      data-test='SVTProductItemsWrapperMainContainer'
    >
      <section>
        <div ref={topRef} className='fix-block-for-sticky'></div>
        {currentActiveTab === 'single-tabletall-products' &&
          allProductsList &&
          allChar.map((char) => {
            const currentProductList = sort(
              filterWithChar(allProductsList, char)
            ).asc((product) => product.alter_name);
            if (currentProductList.length === 0) {
              return null;
            }
            return (
              <div key={char} ref={alphaRefs[char]}>
                <div className='ml-20 fw-bold letter-spacing-O50 dark-shade-gray sticky-header mb-20'>
                  <div className='sticky-header-right-rectangle'></div>
                  <div className='sticky-header-text'>{char}</div>
                </div>
                <SVTProductItems
                  currentProductList={currentProductList}
                  currentActiveTab={currentActiveTab}
                />
              </div>
            );
          })}
        {currentActiveTab === 'single-tabletall-modifiers' &&
          allModifiersList &&
          allChar.map((char) => {
            const currentProductList = sort(
              filterWithChar(allModifiersList, char)
            ).asc((product) => product.alter_name);
            if (currentProductList.length === 0) {
              return null;
            }
            return (
              <div key={char} ref={alphaRefs[char]}>
                <div className='ml-20 fw-bold letter-spacing-O50 dark-shade-gray sticky-header mb-20'>
                  <div className='sticky-header-right-rectangle'></div>
                  <div className='sticky-header-text'>{char}</div>
                </div>
                <SVTModifiersItems currentModifiersList={currentProductList} />
              </div>
            );
          })}
      </section>
      <section>
        <SVTAlphabetList
          activeLetter={activeLetter}
          enabledLetters={enabledLetters}
          handleAlphabetClick={handleAlphabetClick}
        />
      </section>
    </div>
  );
}
export function mapState(state) {
  const { menuManagementRelated } = state;
  const { allProductsList, allUnavailableProductsList, allModifiersList } =
    menuManagementRelated;
  return {
    allProductsList,
    allUnavailableProductsList,
    allModifiersList,
  };
}
const actionCreators = {};

export default compose(
  withRouter,
  connect(mapState, actionCreators)
)(SVTProductItemsWrapper);
