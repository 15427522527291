import React, { Component } from 'react';
import uberEats from '../../images/uberEats.png';
import menuLog from '../../images/logos/deliveroo/deliveroo.png';
import search from '../../images/search.png';
import Xcross from '../../images/cross_mark.svg';
import cross_mark from '../../images/chip_cross_mark.svg';
import SVTOrderHistorySummaryComponent from './SVTOrderHistorySummaryComponent';
import SVTOrderHistoryFilterPopup from './SVTOrderHistoryFilterPopup';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { orderManagementActions } from '../../action/SVTOrderManagementAction';
import { ListGroup, Button } from 'react-bootstrap';
import { sortingConstants } from '../../constant/sortingConstant';
import SVTHeaderComponent from './SVTHeaderComponent';
import chevronUp from '../../images/up.svg';
import chevronDown from '../../images/down.svg';
import SVTMainComponent from './SVTMainComponent';

class SVTOrderHistoryComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShow: false,
      showCross: false,
      selectedFilter: {},
      historySearchInput: undefined,
      query: '',
      viewport: {
        currentHeight: 0,
        currentWidth: 0,
      },

      ORDER_ID: true,
      PARTNER: true,
      DATE: true,
      ITEMS: true,
      STATUS: true,
    };
  }
  componentDidMount() {
    this.props.setCurrentPath(this.props.location.pathname);
    if (this.props.isFromSaleInjectionTapToView === true) {
      const requiredFilter = [
        'uberEats',
        'deliveroo',
        'lastSevenDays',
        'sale_ingestion',
      ];
      this.props.filterOrderHistory(false, requiredFilter);
    } else {
      this.props.getOrderHistoryList();
      // This is for resetting the filter option while doing browser refresh
      this.setState({
        selectedFilter: {},
        historySearchInput: '',
        showCross: false,
      });
      this.props.filterOrderHistory(true, []);
    }
  }
  componentWillUnmount() {
    this.setState({
      selectedFilter: {},
      historySearchInput: '',
      showCross: false,
    });
    this.props.filterOrderHistory(true, []);
    this.props.setIsFromSaleInjectionTapToView(false);
  }
  filterDataInAscendingOrder = (sortBy) => {
    this.props?.sortOrderHistoryList(
      this.props?.orderHistoryList,
      sortBy,
      sortingConstants.ASCENDING
    );
  };

  filterDataInDescendingOrder = (sortBy) => {
    this.props?.sortOrderHistoryList(
      this.props?.orderHistoryList,
      sortBy,
      sortingConstants.DESCENDING
    );
  };

  setModalShow = (showPopUp) => {
    this.setState({ modalShow: showPopUp });
  };

  formatDateToDateMonth = (newDate) => {
    var months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sept',
      'Oct',
      'Nov',
      'Dec',
    ];
    const dateDate = new Date(newDate);
    const dateVal = dateDate?.getDate().toString().padStart(2, '0');
    const month = months[dateDate.getMonth()];

    return `${dateVal} ${month}`;
  };
  showHistoryDetails = (order) => {
    this.props.storeOrderDetails(order);
    this.props.storeHistoryOrderDetails(order);
    this.props.getPartnersList();
    this.props.history.push('/order-history-details');
  };
  searchForOrder = (event) => {
    const queryId = event.target.value?.toLowerCase();
    this.setState({
      query: queryId,
    });
    if (queryId.length > 2) {
      this.props.searchForOrder(queryId, this.state.selectedFilter);
      this.setState({
        showCross: true,
        historySearchInput: queryId,
      });
    } else if (queryId.length === 0) {
      this.props.searchForOrder(queryId, this.state.selectedFilter);
      this.setState({
        showCross: false,
        historySearchInput: queryId,
      });
    } else {
      this.setState({
        showCross: false,
        historySearchInput: queryId,
      });
    }
  };
  onClosePress = () => {
    this.setState({
      showCross: false,
      historySearchInput: '',
    });
    this.props.searchForOrder('', this.state.selectedFilter);
  };
  checkboxSubmitHandler = (data) => {
    this.setState({
      selectedFilter: data,
      historySearchInput: '',
      showCross: false,
    });
    this.props.filterOrderHistory(false, data);
  };
  dismissFilterBtn = (id) => {
    if (id === 'custom') {
      const updatedTmpFilterList = [];
      for (const element of this.props.orderHistoryFilter) {
        if (!element.custom) {
          updatedTmpFilterList.push(element);
        }
      }
      this.setState({
        selectedFilter: updatedTmpFilterList,
        historySearchInput: '',
        showCross: false,
      });
      this.props.filterOrderHistory(false, updatedTmpFilterList);
    } else {
      let tmpFilterList = this.props.orderHistoryFilter.slice();
      if (tmpFilterList.includes(id)) {
        tmpFilterList = tmpFilterList.filter((item) => item !== id);
      }
      this.setState({
        selectedFilter: tmpFilterList,
        historySearchInput: '',
        showCross: false,
      });
      this.props.filterOrderHistory(false, tmpFilterList);
    }
  };
  areTwoArrayEqual = (arrOne, arrTwo) => {
    return (
      arrOne.length === arrTwo.length &&
      arrOne.every(function (element) {
        return arrTwo.includes(element);
      })
    );
  };
  defaultFilterChecker = () => {
    const defaultSelected = [
      'uberEats',
      'deliveroo',
      'completed',
      'cancelledAggregator',
      'lastSevenDays',
      'sale_ingestion',
    ];
    return this.areTwoArrayEqual(
      defaultSelected,
      this.props?.orderHistoryFilter
    );
  };
  defaultPartnerFilterChecker = () => {
    if (
      this.props?.orderHistoryFilter?.includes('uberEats') &&
      this.props?.orderHistoryFilter?.includes('deliveroo')
    ) {
      return true;
    }
    if (
      !this.props?.orderHistoryFilter?.includes('uberEats') &&
      !this.props?.orderHistoryFilter?.includes('deliveroo')
    ) {
      return true;
    }
    return false;
  };
  defaultStatusFilterChecker = () => {
    if (
      this.props?.orderHistoryFilter?.includes('completed') &&
      this.props?.orderHistoryFilter?.includes('cancelledAggregator') &&
      this.props?.orderHistoryFilter?.includes('sale_ingestion')
    ) {
      return true;
    }
    if (
      !this.props?.orderHistoryFilter?.includes('completed') &&
      !this.props?.orderHistoryFilter?.includes('cancelledAggregator') &&
      !this.props?.orderHistoryFilter?.includes('sale_ingestion')
    ) {
      return true;
    }
    return false;
  };
  isCustom = () => {
    if (this.props.orderHistoryFilter) {
      for (const element of this.props.orderHistoryFilter) {
        if (element['custom']) {
          return [true, element['custom']];
        }
      }
    }
    return [false];
  };
  showTimeline = () => {
    if (this.props.orderHistoryFilter) {
      if (this.props.orderHistoryFilter.includes('today')) {
        return 'Today';
      }
    }
    const tmp = this.isCustom();
    if (tmp[0] === true) {
      const customDate = tmp[1].split(',');
      let firstDate = customDate[0].split('-');
      firstDate = `${firstDate[0]} - ${firstDate[1]}`;
      let secondDate = customDate[1].split('-');
      secondDate = `${secondDate[0]} - ${secondDate[1]}`;
      return `${firstDate} to ${secondDate}`;
    }
    if (
      this.state.historySearchInput !== undefined &&
      this.state.historySearchInput !== ''
    ) {
      return null;
    }
    return 'Last 7 Days';
  };
  getorderHistoryFilterArray = () => {
    let orderHistoryFilterArray = this.props?.orderHistoryFilter || [];
    if (this.defaultFilterChecker()) {
      orderHistoryFilterArray = [];
    } else {
      if (this.defaultPartnerFilterChecker()) {
        orderHistoryFilterArray = orderHistoryFilterArray.filter(
          (item) => item !== 'deliveroo'
        );
        orderHistoryFilterArray = orderHistoryFilterArray.filter(
          (item) => item !== 'uberEats'
        );
      }
      if (this.defaultStatusFilterChecker()) {
        orderHistoryFilterArray = orderHistoryFilterArray.filter(
          (item) => item !== 'completed'
        );
        orderHistoryFilterArray = orderHistoryFilterArray.filter(
          (item) => item !== 'cancelledAggregator'
        );
        orderHistoryFilterArray = orderHistoryFilterArray.filter(
          (item) => item !== 'sale_ingestion'
        );
      }
      if (orderHistoryFilterArray.includes('lastSevenDays')) {
        orderHistoryFilterArray = orderHistoryFilterArray.filter(
          (item) => item !== 'lastSevenDays'
        );
      }
    }
    return orderHistoryFilterArray;
  };
  getfilterList = (orderHistoryFilterArray) => {
    return orderHistoryFilterArray.map((item, index) => {
      if (item.custom) {
        const tmpDate = item.custom.split(',');
        const fromDate = tmpDate[0];
        const toDate = tmpDate[1];
        let firstDate = fromDate.split('-');

        firstDate = `${firstDate[0]} - ${firstDate[1]}`;
        let secondDate = toDate.split('-');
        secondDate = `${secondDate[0]} - ${secondDate[1]}`;
        return (
          <div
            className='pe-3'
            key={item.custom}
            onClick={() => this.dismissFilterBtn('custom')}
            data-test='filterRoundedCustomBtn'
          >
            <button className='round-button' data-test='btn'>
              <span id='but_ride' className='pt-1'>
                {firstDate} {'to'} {secondDate}&nbsp; &nbsp;
              </span>
              <span className='pe-2 mt-1 mb-1'>
                <img src={cross_mark} alt='close'></img>
              </span>
            </button>
          </div>
        );
      }
      return (
        <div className='pe-3' key={item}>
          {item === 'cancelledAggregator' && (
            <button
              className='round-button'
              onClick={() => this.dismissFilterBtn(item)}
              data-test={`filterRoundedBtn${index}`}
            >
              <span id='but_ride' className='pt-1'>
                {'Cancelled'}&nbsp; &nbsp;
              </span>
              <span className='pe-2 mt-1 mb-1'>
                <img src={cross_mark} alt='close'></img>
              </span>
            </button>
          )}
          {item === 'sale_ingestion' && (
            <button
              className='round-button'
              onClick={() => this.dismissFilterBtn(item)}
              data-test={`filterRoundedBtn${index}`}
            >
              <span id='but_ride' className='pt-1'>
                Manual POS entry required
              </span>
              <span className='pe-2 mt-1 mb-1'>
                <img src={cross_mark} alt='close'></img>
              </span>
            </button>
          )}
          {item !== 'cancelledAggregator' && item !== 'sale_ingestion' && (
            <button
              className='round-button'
              onClick={() => this.dismissFilterBtn(item)}
              data-test={`filterRoundedBtn${index}`}
            >
              <span id='but_ride' className='pt-1'>
                {item
                  .split(/(?=[A-Z_])/)
                  .join(' ')
                  .replace('_', '')}
                &nbsp; &nbsp;
              </span>
              <span className='pe-2 mt-1 mb-1'>
                <img src={cross_mark} alt='close'></img>
              </span>
            </button>
          )}
        </div>
      );
    });
  };
  getRenderOrders = () => {
    return this.props.orderHistoryList?.data?.map((order, index) => {
      const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <Button
          href=''
          ref={ref}
          onClick={(e) => {
            e.preventDefault();
            onClick(e);
          }}
        >
          {children}
        </Button>
      ));
      return (
        <ListGroup.Item
          key={index}
          ref={(node) => (this['item-' + index] = node)}
          as={CustomToggle}
          onClick={this.showHistoryDetails.bind(this, order)}
        >
          <div
            className='d-flex mt-1 pt-2 mb-2 pl-2 pb-2 align-items-center text-alignment'
            id={order?.partner_order_id}
          >
            <div className='col-md-2 col-sm-2 col-2 ps-3 underline'>
              <b>#{order.partner_id?.toUpperCase()}</b>
            </div>
            <div className='col-md-2 col-sm-2 col-2 '>
              <img
                className='img-responsive'
                alt=''
                src={order.partner_name === 'Uber Eats' ? uberEats : menuLog}
              />
            </div>
            <div className='col-md-2 col-sm-2 col-2 '>
              <div className='histoy-table-time menu-header'>
                {this.formatDateToDateMonth(order?.created_on)}
              </div>
              <div>{order.time}</div>
            </div>
            <div className='col-md-2 col-sm-2 col-2 ms-1'>
              {order.count?.total_items}
            </div>
            <div className='col-md-2 col-sm-2 col-2 ms-1 order-history-price-col'>
              £{(order.total_price / 100).toFixed(2)}
            </div>
            <div className=' col-md-2 col-sm-2 col-2 ms-1 text-capitalize'>
              {order?.sale_ingestion?.toLowerCase() === 'failed'
                ? 'Manual POS entry required'
                : order.partner_status}
            </div>
          </div>
        </ListGroup.Item>
      );
    });
  };

  onPartnerClick = () => {
    return this.state.PARTNER === true
      ? this.filterDataInAscendingOrder.bind(this)(sortingConstants.PARTNER) ||
          this.setState({
            PARTNER: !this.state.PARTNER,
          })
      : this.filterDataInDescendingOrder.bind(this)(sortingConstants.PARTNER) ||
          this.setState({
            PARTNER: !this.state.PARTNER,
          });
  };

  onDateClick = () => {
    return this.state.DATE === true
      ? this.filterDataInAscendingOrder.bind(this)(sortingConstants.DATE) ||
          this.setState({
            DATE: !this.state.DATE,
          })
      : this.filterDataInDescendingOrder.bind(this)(sortingConstants.DATE) ||
          this.setState({
            DATE: !this.state.DATE,
          });
  };
  onItemsClick = () => {
    return this.state.ITEMS === true
      ? this.filterDataInAscendingOrder.bind(this)(sortingConstants.ITEMS) ||
          this.setState({
            ITEMS: !this.state.ITEMS,
          })
      : this.filterDataInDescendingOrder.bind(this)(sortingConstants.ITEMS) ||
          this.setState({
            ITEMS: !this.state.ITEMS,
          });
  };

  onPriceClick = () => {
    return this.state.PRICE === true
      ? this.filterDataInAscendingOrder.bind(this)(sortingConstants.PRICE) ||
          this.setState({
            PRICE: !this.state.PRICE,
          })
      : this.filterDataInDescendingOrder.bind(this)(sortingConstants.PRICE) ||
          this.setState({
            PRICE: !this.state.PRICE,
          });
  };
  onStatusClick = () => {
    return this.state.STATUS === true
      ? this.filterDataInAscendingOrder.bind(this)(sortingConstants.STATUS) ||
          this.setState({
            STATUS: !this.state.STATUS,
          })
      : this.filterDataInDescendingOrder.bind(this)(sortingConstants.STATUS) ||
          this.setState({
            STATUS: !this.state.STATUS,
          });
  };
  getOrderHistoryListComponent = (renderOrders) => {
    <>
      {this.props.orderHistoryList?.count?.total_orders !== 0 ? (
        <ListGroup variant='flush'>{renderOrders}</ListGroup>
      ) : (
        <div className='mt-5 text-center'>
          <img src={search} alt='close'></img>
          <span className='no-search-results ps-2'>No Results Found</span>
        </div>
      )}
    </>;
  };
  orderHistoryFilterFunction = () => {
    return (
      <div className='d-flex'>
        <div className='history-table-header-Order pt-4 mb-3'>
          <span className='history-table-header'>Order History</span>
          <span className='histoy-table-margin-last7 last-7-days'>
            {this.props.orderHistoryFilter && <> {this.showTimeline()} </>}
          </span>
        </div>
        <div className='d-flex history-btn-alignment'>
          <div
            className={
              this.state.historySearchInput
                ? 'border-highlight input-group'
                : 'search-by-orderID input-group'
            }
          >
            <input
              type='text'
              name='searchID'
              className='form-control history-search '
              autoComplete='false'
              placeholder={'Search by Order Id'}
              aria-label='Search'
              aria-describedby='search-addon'
              value={
                this.state.historySearchInput
                  ? this.state.historySearchInput
                  : ''
              }
              onChange={this.searchForOrder.bind(this)}
            />
            {this.state.showCross ? (
              <a type='button'>
                <img
                  src={Xcross}
                  alt='close'
                  className='search-padding pt-2'
                  onClick={() => this.onClosePress()}
                  data-test='searchCrossIcon'
                ></img>
              </a>
            ) : (
              <a type='button'>
                <img
                  src={search}
                  alt='search'
                  className='search-padding align-items-center pt-2'
                ></img>
              </a>
            )}
          </div>
          <div>
            <Button
              className='filter-button content-display'
              disabled={false}
              onClick={this.setModalShow.bind(this, true)}
            >
              {'Filter'}
            </Button>
          </div>
        </div>
      </div>
    );
  };
  orderHistoryPartnerColumn = () => {
    return (
      <div className='col-md-2 col-sm-2 col-2 d-flex olivegreen-color-text svt-bold-text'>
        <span
          className='cursor-pointer'
          onClick={() => this.onPartnerClick()}
          data-test='partnerSpanOfOrderHistory'
        >
          {'Partner'}
        </span>
        <div className='sorting-button-margin d-grid'>
          <img
            className='img-responsive cursor-pointer '
            alt=''
            src={chevronUp}
            onClick={() =>
              this.filterDataInAscendingOrder.bind(this)(
                sortingConstants.PARTNER
              )
            }
            data-test='partnerUpIconForSortingOfOrderHistory'
          />
          <img
            className='img-responsive cursor-pointer '
            alt=''
            src={chevronDown}
            onClick={() =>
              this.filterDataInDescendingOrder.bind(this)(
                sortingConstants.PARTNER
              )
            }
            data-test='partnerDownIconForSortingOfOrderHistory'
          />
        </div>
      </div>
    );
  };
  orderHistoryItemsColumn = () => {
    return (
      <div className='col-md-2 col-sm-2 col-1 d-flex olivegreen-color-text svt-bold-text'>
        <span
          className='cursor-pointer'
          onClick={() => this.onItemsClick()}
          data-test='itemsSpanOfOrderHistory'
        >
          {'Items'}
        </span>
        <div className='sorting-button-margin d-grid'>
          <img
            className='img-responsive cursor-pointer '
            alt=''
            src={chevronUp}
            onClick={() =>
              this.filterDataInAscendingOrder.bind(this)(sortingConstants.ITEMS)
            }
            data-test='itemsUpIconForSortingOfOrderHistory'
          />
          <img
            className='img-responsive cursor-pointer '
            alt=''
            src={chevronDown}
            onClick={() =>
              this.filterDataInDescendingOrder.bind(this)(
                sortingConstants.ITEMS
              )
            }
            data-test='itemsDownIconForSortingOfOrderHistory'
          />
        </div>
      </div>
    );
  };
  render() {
    const orderHistoryFilterArray = this.getorderHistoryFilterArray();

    const filterList = this.getfilterList(orderHistoryFilterArray);
    const renderOrders = this.getRenderOrders();
    if (this.props?.showErrorPage) {
      return <SVTMainComponent />;
    }
    return (
      <>
        <SVTOrderHistoryFilterPopup
          show={this.state.modalShow}
          onHide={() => this.setModalShow(false)}
          checkboxSubmitHandler={this.checkboxSubmitHandler}
          selectedFilter={this.state.selectedFilter}
          data-test='orderHistoryFilterPopup'
        />
        <SVTHeaderComponent />
        <div
          className='container-fluid parent_block'
          data-test='orderHistoryMainContainer'
        >
          <SVTOrderHistorySummaryComponent
            historyDetails={this.props.orderHistoryList}
            orderHistoryFilter={this.props.orderHistoryFilter}
            shouldShowSummary={this.props.shouldShowSummary}
          />
          <div className='order-history-table col-md-12 col-sm-12 col-12'>
            {this.orderHistoryFilterFunction()}
            {filterList.length !== 0 && (
              <div className='filter-rounded-btn-container ms-4'>
                <div className='d-flex order-history-filter-spacing'>
                  {filterList}
                </div>
              </div>
            )}
            <div className='history-table-content'>
              <div className=' d-flex mt-3 mb-1 pl-2 pb-3 border-bottom align-items-center'>
                <div className='col-md-2 col-sm-2 col-2  d-flex olivegreen-color-text svt-bold-text'>
                  <span className='cursor-pointer ps-3'>{'Order Id'}</span>
                </div>
                {this.orderHistoryPartnerColumn()}
                <div className='col-md-2 col-sm-2 col-2 d-flex olivegreen-color-text svt-bold-text'>
                  <span
                    className='cursor-pointer'
                    onClick={() => this.onDateClick()}
                    data-test='dateSpanOfOrderHistory'
                  >
                    {'Date'}
                  </span>
                  <div className='d-grid sorting-button-margin'>
                    <img
                      className='img-responsive cursor-pointer '
                      alt=''
                      src={chevronUp}
                      onClick={() =>
                        this.filterDataInAscendingOrder.bind(this)(
                          sortingConstants.DATE
                        )
                      }
                      data-test='dateUpIconForSortingOfOrderHistory'
                    />
                    <img
                      className='img-responsive cursor-pointer '
                      alt=''
                      src={chevronDown}
                      onClick={() =>
                        this.filterDataInDescendingOrder.bind(this)(
                          sortingConstants.DATE
                        )
                      }
                      data-test='dateDownIconForSortingOfOrderHistory'
                    />
                  </div>
                </div>
                {this.orderHistoryItemsColumn()}
                <div className='col-md-2 col-sm-2 col-2 d-flex olivegreen-color-text svt-bold-text order-history-price-col'>
                  <span
                    className='cursor-pointer'
                    onClick={() => this.onPriceClick()}
                    data-test='priceSpanOfOrderHistory'
                  >
                    {'Price'}
                  </span>
                  <div className='sorting-button-margin d-grid'>
                    <img
                      className='img-responsive cursor-pointer cursor-pointer '
                      alt=''
                      src={chevronUp}
                      onClick={() =>
                        this.filterDataInAscendingOrder.bind(this)(
                          sortingConstants.PRICE
                        )
                      }
                      data-test='priceUpIconForSortingOfOrderHistory'
                    />
                    <img
                      className='img-responsive cursor-pointer '
                      alt=''
                      src={chevronDown}
                      onClick={() =>
                        this.filterDataInDescendingOrder.bind(this)(
                          sortingConstants.PRICE
                        )
                      }
                      data-test='priceDownIconForSortingOfOrderHistory'
                    />
                  </div>
                </div>
                <div className='col-md-2 col-sm-2 col-2 d-flex olivegreen-color-text svt-bold-text'>
                  <span
                    className='cursor-pointer'
                    onClick={() => this.onStatusClick()}
                    data-test='statusSpanOfOrderHistory'
                  >
                    {'Status'}
                  </span>
                  <div className='sorting-button-margin d-grid'>
                    <img
                      className='img-responsive cursor-pointer '
                      alt=''
                      src={chevronUp}
                      onClick={() =>
                        this.filterDataInAscendingOrder.bind(this)(
                          sortingConstants.STATUS
                        )
                      }
                      data-test='statusUpIconForSortingOfOrderHistory'
                    />
                    <img
                      className='img-responsive cursor-pointer '
                      alt=''
                      src={chevronDown}
                      onClick={() =>
                        this.filterDataInDescendingOrder.bind(this)(
                          sortingConstants.STATUS
                        )
                      }
                      data-test='statusDownIconForSortingOfOrderHistory'
                    />
                  </div>
                </div>
              </div>
              <div
                className={
                  this.props.shouldShowSummary
                    ? 'histoy-table-height'
                    : 'no-summary-height'
                }
              >
                {this.state.query.length !== 0 ? (
                  this.getOrderHistoryListComponent(renderOrders)
                ) : (
                  <ListGroup variant='flush'>{renderOrders}</ListGroup>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapState(state) {
  const { orderManagementRelated, errorPageRelated } = state;
  const {
    orderHistoryList,
    orderHistoryFilter,
    shouldShowSummary,
    isFromSaleInjectionTapToView,
  } = orderManagementRelated;
  const { showErrorPage } = errorPageRelated;
  return {
    orderHistoryList,
    orderHistoryFilter,
    showErrorPage,
    shouldShowSummary,
    isFromSaleInjectionTapToView,
  };
}
const actionCreators = {
  getOrderHistoryList: orderManagementActions.getOrderHistoryList,
  storeHistoryOrderDetails: orderManagementActions.storeHistoryOrderDetails,
  filterOrderHistory: orderManagementActions.filterOrderHistory,
  sortOrderHistoryList: orderManagementActions.sortOrderHistoryList,
  searchForOrder: orderManagementActions.searchForOrder,
  getPartnersList: orderManagementActions.getPartnersList,
  setCurrentPath: orderManagementActions.setCurrentPath,
  storeOrderDetails: orderManagementActions.storeOrderDetails,
  toggleShowOrderSummary: orderManagementActions.toggleShowOrderSummary,
  setIsFromSaleInjectionTapToView:
    orderManagementActions.setIsFromSaleInjectionTapToView,
};

export default compose(
  withRouter,
  connect(mapState, actionCreators)
)(SVTOrderHistoryComponent);
