import { menuManagementService } from '../services/SVTMenuManagementService';
import {
  startLoader,
  stopLoader,
  handleAPIFailure,
  log,
  LogType,
} from '../helper/common';
import { constants } from '../constant/reducerConstant';
export const menuManagementAction = {
  getAllProductsList,
  getCategoriesList,
  getAllProductsAndModifiersListFromSearchQuery,
  updateProductStatus,
  storeSearchQuery,
  saveCurrentActiveCategory,
  setUnavailableItemsCount,
  getTotalUnavailableCount,
  getTotalUnavailableItemList,
  setMenuModifiers,
  setMenuSubModifiers,
  setCurrentActiveProduct,
  updateModifierStatus,
  getRecentTabProductList,
  setModifierProductKey,
  setModifierLevel2ProductKey,
  setOptionName,
  setOPtionLevel2Name,
  clearAllMenuSelections,
  getAllModifiersList,
  setCurrentActiveModifier,
  setProductStatusToNull,
};

function getAllProductsAndModifiersListFromSearchQuery(searchQuery) {
  return (dispatch) => {
    dispatch(startLoader());
    menuManagementService.getAllProductsListFromSearchQuery(searchQuery).then(
      (data) => {
        log('GetAllProductsAndModifiersListFromSearchQuery Success', data, LogType.INFO);
        dispatch(stopLoader());
        dispatch(getAllProductsListFromSearchQuerySuccess(data?.data));
      },
      (error) => {
        log('GetAllProductsAndModifiersListFromSearchQuery Failure', error, LogType.ERROR);
        dispatch(getAllProductsListFromSearchQueryFail());
        dispatch(stopLoader());
        dispatch(handleAPIFailure(error, 'Search Product'));
      }
    );
  };
  function getAllProductsListFromSearchQuerySuccess(data) {
    return { type: constants.SET_ALL_PRODUCTS_LIST_FROM_SEARCH_QUERY, data };
  }
  function getAllProductsListFromSearchQueryFail() {
    return {
      type: constants.SET_ALL_PRODUCTS_LIST_FROM_SEARCH_QUERY,
      data: [],
    };
  }
}
function updateProductStatus(payload) {
  return (dispatch) => {
    dispatch(startLoader());
    menuManagementService.updateProductStatus(payload).then(
      (data) => {
        log('UpdateProductStatus Success', data, LogType.INFO);
        dispatch(stopLoader());
        dispatch(updateProductStatusSuccess(data));
      },
      (error) => {
        log('UpdateProductStatus Failure', error, LogType.ERROR);
        dispatch(updateProductStatusFail());
        dispatch(stopLoader());
        dispatch(handleAPIFailure(error, 'Update Product Status'));
      }
    );
  };
  function updateProductStatusSuccess(data) {
    return { type: constants.UPDATE_PRODUCT_STATUS_SUCCESS, data };
  }
  function updateProductStatusFail() {
    return { type: constants.UPDATE_PRODUCT_STATUS_FAIL };
  }
}
function getCategoriesList() {
  return (dispatch) => {
    dispatch(startLoader());
    menuManagementService.getCategoriesList().then(
      (data) => {
        log('GetCategoriesList Success', data, LogType.INFO);
        dispatch(stopLoader());
        dispatch(getCategoriesListSuccess(data));
      },
      (error) => {
        log('GetCategoriesList Success', error, LogType.ERROR);
        dispatch(getCategoriesListFail());
        dispatch(stopLoader());
        dispatch(handleAPIFailure(error, 'Categories List'));
      }
    );
  };
  function getCategoriesListSuccess(data) {
    return { type: constants.GET_CATEGORIES_LIST_SUCCESS, data };
  }
  function getCategoriesListFail() {
    return { type: constants.GET_CATEGORIES_LIST_FAIL };
  }
}

function storeSearchQuery(data) {
  return { type: constants.STORE_SEARCH_QUERY, data };
}
function saveCurrentActiveCategory(data) {
  return { type: constants.SET_CURRENT_ACTIVE_CATEGORY, data };
}
function setUnavailableItemsCount(data) {
  return { type: constants.SET_UNAVAILABLE_COUNT, data };
}
function getTotalUnavailableCount() {
  return (dispatch) => {
    dispatch(startLoader());
    menuManagementService.getTotalUnavailableCount().then(
      (data) => {
        log('GetTotalUnavailableCount Success', data, LogType.INFO);
        dispatch(stopLoader());
        dispatch(getTotalUnavailableCountSuccess(data));
      },
      (error) => {
        log('GetTotalUnavailableCount Failure', error, LogType.ERROR);
        dispatch(getTotalUnavailableCountFail());
        dispatch(stopLoader());
        dispatch(handleAPIFailure(error, 'Unavailable Count'));
      }
    );
  };
  function getTotalUnavailableCountSuccess(data) {
    return { type: constants.GET_TOTAL_UNAVAILABLE_COUNT_SUCCESS, data };
  }
  function getTotalUnavailableCountFail() {
    return { type: constants.GET_TOTAL_UNAVAILABLE_COUNT_FAIL };
  }
}
function getTotalUnavailableItemList() {
  return (dispatch) => {
    dispatch(startLoader());
    menuManagementService.getTotalUnavailableItemList().then(
      (data) => {
        log('GetTotalUnavailableItemList Success', data, LogType.INFO);
        dispatch(stopLoader());
        dispatch(
          getTotalUnavailableItemListSuccess(
            formatUnavailableProductAndModifier(data)
          )
        );
      },
      (error) => {
        log('GetTotalUnavailableItemList Failure', error, LogType.ERROR);
        dispatch(getTotalUnavailableItemListFail());
        dispatch(handleAPIFailure(error, 'Unavialable Items'));
        dispatch(stopLoader());
      }
    );
  };
  function formatUnavailableProductAndModifier(data) {
    const {
      data: unavailableProductsList = [],
      modifiers_data: unavailableModifiersList = [],
    } = data;
    const allUnavailableProductsAndModifiersList = [...unavailableProductsList];
    unavailableModifiersList.forEach((modifier) => {
      const formattedModifier = formatModifierItem(modifier);
      formattedModifier['isModifier'] = true;
      allUnavailableProductsAndModifiersList.push(formattedModifier);
    });
    return allUnavailableProductsAndModifiersList;
  }
  function getTotalUnavailableItemListSuccess(data) {
    return {
      type: constants.GET_TOTAL_UNAVAILABLE_ITEM_LIST_SUCCESS,
      data,
    };
  }
  function getTotalUnavailableItemListFail() {
    return { type: constants.GET_TOTAL_UNAVAILABLE_ITEM_LIST_FAIL };
  }
}

function setMenuModifiers(data) {
  return { type: constants.SET_MENU_MODIFIERS, data };
}
function setMenuSubModifiers(data) {
  return { type: constants.SET_MENU_SUB_MODIFIERS, data };
}
function setCurrentActiveProduct(payload) {
  return { type: constants.SET_CURRENT_ACTIVE_PRODUCT, payload };
}
function updateModifierStatus(payload) {
  return (dispatch) => {
    dispatch(startLoader());
    menuManagementService.updateModifierStatus(payload).then(
      (data) => {
        log('updateModifierStatus Success', data, LogType.INFO);
        dispatch(stopLoader());
        dispatch(setCurrentActiveModifier(data));
      },
      (error) => {
        log('updateModifierStatus Failure', error, LogType.ERROR);
        dispatch(setCurrentActiveModifier());
        dispatch(stopLoader());
        dispatch(handleAPIFailure(error, 'Update Modifier Status'));
      }
    );
  };
}
function setCurrentActiveModifier(data) {
  const currentActiveModifier = {
    modifierName: '',
    isModifierAvailable: false,
    shouldShow: false,
    numberOfProductsHavingThisModifier: 1,
  };
  if (!data) {
    return {
      type: constants.SET_CURRENT_ACTIVE_MODIFIER,
      data: currentActiveModifier,
    };
  }

  if (data?.status?.toLowerCase() !== 'success') {
    return {
      type: constants.SET_CURRENT_ACTIVE_MODIFIER,
      data: currentActiveModifier,
    };
  }

  const {
    data: { availability = true } = {},
    optionName: modifierName = '',
    product_keys: productKeys = [],
  } = data;
  const numberOfProductsHavingThisModifier = productKeys.length;
  currentActiveModifier.isModifierAvailable = availability;
  currentActiveModifier.modifierName = modifierName;
  currentActiveModifier.shouldShow = true;
  currentActiveModifier.numberOfProductsHavingThisModifier =
    numberOfProductsHavingThisModifier;
  return {
    type: constants.SET_CURRENT_ACTIVE_MODIFIER,
    data: currentActiveModifier,
  };
}
function getRecentTabProductList() {
  return (dispatch) => {
    dispatch(startLoader());
    menuManagementService.getRecentTabProductList().then(
      (data) => {
        log('GetRecentTabProductList Success', data, LogType.INFO);
        dispatch(stopLoader());
        dispatch(getProductListSuccess(data?.data));
      },
      (error) => {
        log('GetRecentTabProductList Failure', error, LogType.ERROR);
        dispatch(getProductListFail());
        dispatch(stopLoader());
        dispatch(handleAPIFailure(error, 'Recently Updated List'));
      }
    );
  };
  function getProductListSuccess(data) {
    return { type: constants.GET_PRODUCT_LIST_SUCCESS, data };
  }
  function getProductListFail() {
    return { type: constants.GET_PRODUCT_LIST_FAIL };
  }
}

function setModifierProductKey(data) {
  return { type: constants.SET_MODIFIER_PRODUCT_KEY, data };
}
function setModifierLevel2ProductKey(data) {
  return { type: constants.SET_MODIFIER_LEVEL2_PRODUCT_KEY, data };
}
function setOptionName(data) {
  return { type: constants.SET_OPTION_NAME, data };
}
function setOPtionLevel2Name(data) {
  return { type: constants.SET_OPTION_LEVEL2_NAME, data };
}

function clearAllMenuSelections() {
  return { type: constants.CLEAR_ALL_MENU_SELECTIONS };
}
function getAllProductsList() {
  return (dispatch) => {
    dispatch(startLoader());
    menuManagementService.getAllProductsList().then(
      (data) => {
        log('GetAllProductsList Success', data, LogType.INFO);
        dispatch(stopLoader());
        dispatch(getProductListSuccess(data?.data));
      },
      (error) => {
        log('GetAllProductsList Failure', error, LogType.ERROR);
        dispatch(getProductListFail());
        dispatch(stopLoader());
        dispatch(handleAPIFailure(error, 'Product List'));
      }
    );
  };
  function getProductListSuccess(data) {
    return { type: constants.SET_ALL_PRODUCTS_LIST, data };
  }
  function getProductListFail() {
    return { type: constants.SET_ALL_PRODUCTS_LIST, data: [] };
  }
}
function getAllModifiersList() {
  return (dispatch) => {
    dispatch(startLoader());
    menuManagementService.getAllModifiersList().then(
      (data) => {
        log('GetAllModifiersList Success', data, LogType.INFO);
        dispatch(stopLoader());
        dispatch(getProductListSuccess(data.data));
      },
      (error) => {
        log('GetAllModifiersList Failure', error, LogType.ERROR);
        dispatch(getProductListFail());
        dispatch(stopLoader());
        dispatch(handleAPIFailure(error, 'All Modifiers List'));
      }
    );
  };
  function getProductListSuccess(data = []) {
    const formattedModifiersList = [];
    data.forEach((modifier) => {
      formattedModifiersList.push(formatModifierItem(modifier));
    });
    return {
      type: constants.SET_ALL_MODIFIERS_LIST,
      data: formattedModifiersList,
    };
  }
  function getProductListFail() {
    return { type: constants.SET_ALL_MODIFIERS_LIST, data: [] };
  }
}
function formatModifierItem(item) {
  const {
    availability = true,
    sku_code = '',
    product_keys = [],
    updated_on: lastUpdated,
  } = item;
  const numberOfProductsHavingThisModifier = product_keys.length;

  const data = {
    availability,
    sku_code,
    numberOfProductsHavingThisModifier,
    lastUpdated,
  };

  data['alter_name'] = item?.option_name?.toLowerCase();

  return data;
}
function setProductStatusToNull() {
  return { type: constants.UPDATE_PRODUCT_STATUS_FAIL };
}
