import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { commonActions } from '../../action/SVTCommonAction';
import { orderManagementActions } from '../../action/SVTOrderManagementAction';
import { menuManagementAction } from '../../action/SVTMenuManagementAction';
import { authActions } from '../../action/SVTAuthActions';
import jwt_decode from 'jwt-decode';
import smile_unhappy from '../../images/smile_unhappy.svg';
import loginErrorPageIcon from '../../images/loginErrorPageIcon.svg';

class SVTExceptionPage extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {}
  componentDidMount() {}
  componentDidUpdate(prevProps) {
    if (this.props?.location !== prevProps.location) {
      this.props.clearErrorPage();
      this.setState = { hasError: false };
    }
  }

  formatDateToDateMonth(newDate) {
    var months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sept',
      'Oct',
      'Nov',
      'Dec',
    ];
    const dateDate = new Date(newDate);
    const dateVal = dateDate?.getDate().toString().padStart(2, '0');
    const month = months[dateDate.getMonth()];
    const timezone = dateDate.toTimeString().split('(')[0].split(' ')[1];
    return `${dateVal} ${month} • ${dateDate.toLocaleTimeString()} (${timezone.substring(
      0,
      6
    )}:${timezone.substring(6)})`;
  }

  async clearSelections() {
    const devicetStatus = await this.props.removeDeviceToken(
      this.props.storeDeviceToken?.device_token
        ? this.props.storeDeviceToken?.device_token
        : this.props.storeDeviceToken
    );
    if (devicetStatus === 'success' || devicetStatus === 'fail') {
      this.props.userLogout(this.props.sessionData.refresh_token);
      sessionStorage.setItem('accessToken', '');
      this.props.clearAllSelections();
      this.props.clearAllMenuSelections();
      this.props.clearAllAuthInfo();
      this.props.history.push('/');
      this.props.saveCancelledOrderNewNotification([]);
      this.props.saveIncomingOrderNewNotification([]);
    }
  }
  render() {
    const date = new Date();
    const username = this.props?.accessToken
      ? jwt_decode(this.props?.accessToken)['cognito:username']
      : 'User';
    if (this.state.hasError || this.props?.showErrorPage) {
      return (
        <div
          data-test='exceptionPageContainer'
          className='container container-body  mt-4 pt-5'
          style={{ height: '410px', textAline: 'left', marginLeft: '10%' }}
        >
          <div className='row'>
            <div className='col col-sm-2 col-md-1'>
              <img
                src={
                  this.props?.errorPageAPIErrorCodeDetails === 403
                    ? loginErrorPageIcon
                    : smile_unhappy
                }
                alt='errorPageIcon'
              />
            </div>
            <div className='col'>
              <div className='row exception-page-header mb-15'>
                {this.props?.errorPageAPIErrorCodeDetails === 403
                  ? 'Login Error'
                  : 'Oops!'}
              </div>
              <div className='row h-100'>
                <div className='col col-12 col-md-12 col-sm-8'>
                  <div className='col-8 col-md-12'>
                    {this.props?.errorPageAPIErrorCodeDetails === 401 ? (
                      <h5>
                        We found, you are logged in from another device or
                        browser. <br />
                        Please login here to continue on this device.
                        <br />
                        This will invalidate session from other devices
                        currently login
                      </h5>
                    ) : (
                      <>
                        <div className='mb-15 heading-h4'>
                          {' '}
                          {username} is currently not registered with Order In
                        </div>
                        <div className='mb-15 error-page-normal-text'>
                          Please try logging in with rosi+ employee login
                        </div>{' '}
                      </>
                    )}
                    <div>
                      <button
                        className='btn success-btn-modal badge-pill mt-3 mb-3'
                        onClick={this.clearSelections.bind(this)}
                      >
                        <span className='btn-white-text'>Go Back</span>
                      </button>
                    </div>
                    {this.props?.errorPageAPIErrorCodeDetails !== 403 && (
                      <div className='d-flex flex-column setting-about-row-gap error-block'>
                        <div className='error-page-color-text'>
                          {'Error Code : '}
                          {this.props?.errorPageAPIErrorCodeDetails}&nbsp;
                          {this.props?.errorPageAPIErrorMessageHeader}
                        </div>
                        <div className='error-page-normal-text'>
                          {this.props?.errorPageAPIErrorMessageDetails} <br />
                          {username}
                          <br />
                          <strong>{this.formatDateToDateMonth(date)}</strong>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return this.props?.children;
    }
  }
}

export function mapState(state) {
  const { orderManagementRelated, authInfo, errorPageRelated } = state;
  const { storeDetails, storeDeviceToken } = orderManagementRelated;
  const {
    showErrorPage,
    errorPageAPIErrorMessageHeader,
    errorPageAPIErrorCodeDetails,
    errorPageAPIErrorMessageDetails,
  } = errorPageRelated;
  const { sessionData, accessToken } = authInfo;
  return {
    showErrorPage,
    errorPageAPIErrorMessageHeader,
    errorPageAPIErrorCodeDetails,
    errorPageAPIErrorMessageDetails,
    storeDetails,
    sessionData,
    storeDeviceToken,
    accessToken,
  };
}

const actionCreators = {
  clearErrorPage: commonActions.clearErrorPage,
  clearAllSelections: orderManagementActions.clearAllSelections,
  clearAllAuthInfo: orderManagementActions.clearAllAuthInfo,
  logoutFromApplication: orderManagementActions.logoutFromApplication,
  refreshAccessToken: authActions.refreshAccessToken,
  removeDeviceToken: orderManagementActions.removeDeviceToken,
  saveIncomingOrderNewNotification:
    orderManagementActions.saveIncomingOrderNewNotification,
  saveCancelledOrderNewNotification:
    orderManagementActions.saveCancelledOrderNewNotification,
  userLogout: authActions.userLogout,
  clearAllMenuSelections: menuManagementAction.clearAllMenuSelections,
};

export default compose(
  withRouter,
  connect(mapState, actionCreators)
)(SVTExceptionPage);
