import { log, LogType } from "../../helper/common";

export const paths = {
  orderList: '/order-list',
};

export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

export const getCancelledItems = () => {
  // ! Commenting this function body because it is doing noting. If it is responsible to do something, please
  // ! justify that before uncommenting the function body code.
  // const btnCheck =
  // 	props?.storePartiallyAcceptedItems?.order_items?.filter(function (e) {
  // 		return e.active === true;
  // 	}).length !== 0;
  // setPartialAcceptanceBtn(btnCheck);
};

export const dismissOrder = async (props, order) => {
  if (order?.partner_name?.toLowerCase() === 'deliveroo') {
    //for muting the sound
    if (props?.incomingOrderNotifications) {
      let incomingOrderNotifications = [...props?.incomingOrderNotifications];
      if (incomingOrderNotifications.length > 0) {
        incomingOrderNotifications = incomingOrderNotifications.filter(
          (notification) => notification.id !== order.id
        );
        props.saveIncomingOrderNewNotification(incomingOrderNotifications);
      }
    }
  }
  const disMissOrders = await props.dismissLiveOrders(order.id);
  if (disMissOrders?.status === 'SUCCESS') {
    props.getOrderReportCount();
    props.getIncomingOrders();
    props.getIncomingOrdersBackup();
  }
};

export const showOrderDetails = async (props, order) => {
  if (order?.partner_name?.toLowerCase() === 'deliveroo') {
    //for muting the sound'
    if (props?.incomingOrderNotifications) {
      let incomingOrderNotifications = [...props?.incomingOrderNotifications];

      if (incomingOrderNotifications.length > 0) {
        incomingOrderNotifications = incomingOrderNotifications.filter(
          (notification) => notification.id !== order.id
        );
        props.saveIncomingOrderNewNotification(incomingOrderNotifications);
      }
    }
  }
  props.storeOrderDetails(order);
  props.setShouldShowEditOrderViewInOrderDetailsPage(false);
  props.history.push('/order-details/Incoming');
  const disMissOrders = await props.dismissLiveOrders(order.id);
  if (disMissOrders?.status === 'SUCCESS') {
    props.getOrderReportCount();
    props.getIncomingOrders();
    props.getIncomingOrdersBackup();
    props.setAutoPrintOption(true);
    props.getIncomingOrdersBackup();
  }
};

export const orderAccepted = async (props, order, setAcceptButtonState) => {
  setAcceptButtonState(true);
  const payload = {
    id: order.id,
    status: 'Accepted',
  };
  if (props?.incomingOrderNotifications) {
    let incomingOrderNotifications = [...props?.incomingOrderNotifications];
    if (incomingOrderNotifications.length > 0) {
      incomingOrderNotifications = incomingOrderNotifications.filter(
        (notification) => notification.id !== order.id
      );
      props.saveIncomingOrderNewNotification(incomingOrderNotifications);
    }
  }
  const headerMsg = `Order # ${order?.partner_id?.toUpperCase()} # has been accepted.`;
  props.storeOrderDetails(order);
  props.setShouldShowEditOrderViewInOrderDetailsPage(false);
  const updateOrder = await props.updateOrderStatus(payload);
  if (updateOrder?.status === 'SUCCESS') {
    props.setAutoPrintOption(true);
    const headerMsgPayload = {
      show: true,
      type: 'Incoming',
      msg: headerMsg,
      orderId: order?.id,
      partnerId: order?.partner_id?.toUpperCase(),
    };
    log(`Order Accepted #${headerMsgPayload.partnerId}`, headerMsgPayload, LogType.INFO)
    props?.setShowTopMsg(headerMsgPayload);
    props.getOrderReportCount();
    props.getIncomingOrders();
    props.getIncomingOrdersBackup();
    props.getOrderList();

    props.history.push(paths.orderList);
  } else if (updateOrder?.status === 'Failed') {
    props.getOrderReportCount();
    props.getIncomingOrders();
    props.getIncomingOrdersBackup();
    props.getOrderList();
    props.history.push(paths.orderList);
  }
};

export const acceptIfPartialAcceptanceAllowed = async (
  props,
  order,
  orderItems
) => {
  const payload = {
    id: order.id,
    status: 'Accepted',
    order_items: orderItems,
  };
  const updatedOrder = await props.updateEditedOrders(payload);
  if (updatedOrder?.status === 'SUCCESS') {
    const headerMsgPayload = {
      show: true,
      type: 'Incoming',
      msg: `Changes made to the order ${order?.partner_id?.toUpperCase()} have been sent to the customer for review.`,
      orderId: order.id,
      partnerId: order?.partner_id?.toUpperCase(),
    };
    const queryString = `id=${order.id}&status=Accepted`;
    const itemDetails = await props.getSingleItemDetails(queryString);
    if (itemDetails) {
      props.setAutoPrintOption(true);
      props.storeOrderDetails(itemDetails.data[0]);
      props.setShouldShowEditOrderViewInOrderDetailsPage(false);
      props.setShowTopMsg(headerMsgPayload);
      props.showEditedOrders(false);
      props.getOrderReportCount();
      props.getIncomingOrders();
      props.getIncomingOrdersBackup();
      props.getOrderList();
      props.history.push(paths.orderList);
    }
  }
};

export const rejectIfPartialAcceptanceNotAllowed = async (props, order) => {
  const payload = {
    id: order.id,
    status: 'Rejected',
    reason: 'OUT_OF_ITEMS',
  };
  const updatedOrder = await props.updateOrderStatus(payload);
  if (updatedOrder?.status === 'SUCCESS') {
    const headerMsgPayload = {
      show: true,
      type: 'Cancelled',
      msg: `Changes made to the order ${order?.partner_id?.toUpperCase()} have been sent to the customer for review.`,
      orderId: order.id,
      partnerId: order?.partner_id?.toUpperCase(),
    };
    const queryString = `id=${order.id}`;
    const itemDetails = await props.getSingleItemDetails(queryString);
    if (itemDetails) {
      props.setAutoPrintOption(false);
      props.storeOrderDetails(itemDetails.data[0]);
      props.setShouldShowEditOrderViewInOrderDetailsPage(false);
      props.setShowTopMsg(headerMsgPayload);
      props.showEditedOrders(false);
      props.getOrderReportCount();
      props.getIncomingOrders();
      props.getIncomingOrdersBackup();
      props.history.push(paths.orderList);
    }
  }
};

export const handlePartiallyOrderAccepted = async (
  props,
  order,
  closePartialAcceptanceView,
  setPAcceptancePopup,
  showPartialAcceptanceBtn
) => {
  props.setAutoPrintOption(false);
  props.trigerEditOrders(false);
  if (props?.incomingOrderNotifications) {
    let incomingOrderNotifications = [...props?.incomingOrderNotifications];
    if (incomingOrderNotifications.length > 0) {
      incomingOrderNotifications = incomingOrderNotifications.filter(
        (notification) => notification.id !== order.id
      );
      props.saveIncomingOrderNewNotification(incomingOrderNotifications);
    }
  }
  closePartialAcceptanceView(props.id);
  props.showEditedOrders(false);
  setPAcceptancePopup(false);
  const orderItems = [];

  const currentOrder = [...props.backupIncomingOrder].find(
    (initOrder) => initOrder['id'] === order.id
  );
  props?.storePartiallyAcceptedItems?.order_items?.forEach((data, index) => {
    if (currentOrder.order_items[index]['quantity'] !== data.quantity) {
      orderItems.push({
        item_id: data.plu,
        quantity: data.quantity,
        reason: data.quantity > 0 ? 'PARTIAL_AVAILABILITY' : 'OUT_OF_ITEM',
      });
    }
  });
  if (showPartialAcceptanceBtn === true) {
    acceptIfPartialAcceptanceAllowed(props, order, orderItems);
  } else if (showPartialAcceptanceBtn === false) {
    rejectIfPartialAcceptanceNotAllowed(props, order);
  }
};

export const handleCurrentFoodItemChange = (props, setCurrentFoodItem) => {
  if (props.id && props.foodQuantity) {
    const tmp = {};
    for (let index = 0; index < props.foodQuantity[props.id]?.length; index++) {
      const key = `editOrderCloseBtn${index}`;
      tmp[key] = props.foodQuantity[props.id][index]['quantity'];
    }

    setCurrentFoodItem(tmp);
  }
};

export const handleCurrentFoodItemModifierChange = (
  props,
  setCurrentFoodItemModifier
) => {
  if (props.id && props.foodQuantity) {
    const tmp = {};
    for (let index = 0; index < props.foodQuantity[props.id]?.length; index++) {
      if (props.foodQuantity[props.id][index]['children'].length > 0) {
        for (
          let j = 0;
          j < props.foodQuantity[props.id][index]['children'].length;
          j++
        ) {
          const key = `editOrderCloseBtn${index}${j}`;
          tmp[key] =
            props.foodQuantity[props.id][index]['children'][j]['quantity'];
        }
      }
    }
    setCurrentFoodItemModifier(tmp);
  }
};

export const getQuantity = (data) => data?.quantity ?? 0;
export const getPrice = (data) =>
  data?.unit_price
    ? data?.unit_price / 100
    : data?.price / data?.quantity / 100;

export const calculateTotalForStateType = {
  decrease: (order1, id, isOutOfStock) => {
    let totalItems = 0;
    let totalCost = 0;

    order1?.order_items?.forEach((data, index) => {
      if (!(id === index && isOutOfStock)) {
        totalItems += Number(getQuantity(data));
        totalCost += parseFloat(getPrice(data)) * getQuantity(data);
        if (index === id) {
          totalItems -= 1;
          totalCost -= parseFloat(getPrice(data));
        }
      }
    });

    return [totalCost, totalItems];
  },
  increase: (order1, id, isZero, isOutOfStock, resetValue) => {
    let totalItems = 0;
    let totalCost = 0;

    order1?.order_items?.forEach((data, index) => {
      if (id === index && isZero) {
        totalItems += 1;
        totalCost += parseFloat(getPrice(data));
      } else if (id === index && isOutOfStock && resetValue !== false) {
        totalItems += resetValue;
        totalCost += parseFloat(getPrice(data)) * resetValue;
      } else {
        totalItems += Number(getQuantity(data));
        totalCost += parseFloat(getPrice(data)) * getQuantity(data);
        if (index === id) {
          totalItems += 1;
          totalCost += parseFloat(getPrice(data));
        }
      }
    });

    return [totalCost, totalItems];
  },
  none: (order1, id, isOutOfStock) => {
    let totalItems = 0;
    let totalCost = 0;

    order1.order_items.forEach((data, index) => {
      if (!(isOutOfStock === true && index === id)) {
        totalItems += Number(getQuantity(data));
        totalCost += parseFloat(getPrice(data)) * getQuantity(data);
      }
    });

    return [totalCost, totalItems];
  },
};

export const calculateTotalForState = (
  order1,
  id,
  type,
  isOutOfStock = false,
  resetValue = false,
  isZero = false
) => {
  if (type === 'decrease') {
    return calculateTotalForStateType.decrease(order1, id, isOutOfStock);
  } else if (type === 'increase') {
    return calculateTotalForStateType.increase(
      order1,
      id,
      isZero,
      isOutOfStock,
      resetValue
    );
  } else {
    return calculateTotalForStateType.none(order1, id, isOutOfStock);
  }
};

export const handleGetCurrentOrder = (incomingOrders, order) => {
  const masterIncomingOrderList = [...incomingOrders];

  for (const incomingOrder of masterIncomingOrderList) {
    if (incomingOrder['id'] === order.id) {
      return incomingOrder;
    }
  }

  return {};
};

export const handleSetCurrentItemQuantityType = {
  decrease: (props, functions, currentFoodItem, id, index) => {
    const {
      setCurrentFoodItemOutOfStock,
      setCurrentFoodItem,
      setTotalPrice,
      setTotalNoOfItems,
      setButtonColor,
      isInInitialState,
    } = functions;

    const tmp = { ...currentFoodItem };
    tmp[id] = tmp[id] <= 1 ? 0 : tmp[id] - 1;
    if (tmp[id] === 0) {
      setCurrentFoodItemOutOfStock(id);
      const tmpStorePartiallyAcceptedItems = {
        ...props.storePartiallyAcceptedItems,
      };
      tmpStorePartiallyAcceptedItems.order_items[index].quantity = 0;
      setCurrentFoodItem(tmp);
      const t1 = calculateTotalForState(
        tmpStorePartiallyAcceptedItems,
        parseInt(id.substring(17)),
        'decrease'
      );
      setTotalPrice(t1[0]);
      setTotalNoOfItems(t1[1]);
      setButtonColor(() => isInInitialState({ ...tmp }));
      return tmp[id];
    } else {
      setCurrentFoodItem(tmp);
      const t1 = calculateTotalForState(
        props.storePartiallyAcceptedItems,
        parseInt(id.substring(17)),
        'decrease'
      );
      setTotalPrice(t1[0]);
      setTotalNoOfItems(t1[1]);
      setButtonColor(() => isInInitialState({ ...tmp }));
      return tmp[id];
    }
  },
  getQuantity: (props, functions, id) => {
    const { getCurrentOrder } = functions;

    id = 'editOrderCloseBtn' + id.substring(17);
    const currentOrder = getCurrentOrder(props.backupIncomingOrder);

    for (let index = 0; index < currentOrder.order_items?.length; index++) {
      const key = `editOrderCloseBtn${index}`;
      if (key === id) {
        return currentOrder.order_items[index]['quantity'];
      }
    }
    return undefined;
  },
  default: (props, functions, id, currentFoodItem) => {
    const {
      setCurrentFoodItem,
      setTotalPrice,
      setTotalNoOfItems,
      setButtonColor,
      isInInitialState,
      getCurrentOrder,
    } = functions;

    id = 'editOrderCloseBtn' + id.substring(17);
    const tmp = { ...currentFoodItem };
    const currentOrder = getCurrentOrder(props.backupIncomingOrder);

    let flag = 0;
    let result;
    let t1 = [0, 0];
    for (let index = 0; index < currentOrder.order_items?.length; index++) {
      const key = `editOrderCloseBtn${index}`;
      if (key === id) {
        if (tmp[id] === currentOrder.order_items[index]['quantity']) {
          result = currentOrder.order_items[index]['quantity'];
          break;
        } else {
          tmp[id] += 1;
          result = tmp[id];
          flag = 1;
          if (tmp[id] === 1) {
            t1 = calculateTotalForState(
              props.storePartiallyAcceptedItems,
              parseInt(id.substring(17)),
              'increase',
              false,
              false,
              true
            );
          } else {
            t1 = calculateTotalForState(
              props.storePartiallyAcceptedItems,
              parseInt(id.substring(17)),
              'increase'
            );
          }

          break;
        }
      }
    }
    switch (flag) {
      case 0:
        setButtonColor(() => isInInitialState({ ...tmp }));
        return result;
      case 1:
        setCurrentFoodItem(tmp);
        setTotalPrice(t1[0]);
        setTotalNoOfItems(t1[1]);
        setButtonColor(() => isInInitialState({ ...tmp }));
        return result;
      default:
        break;
    }
    return undefined;
  },
};

export const handleSetCurrentItemQuantity = (
  props,
  id,
  type,
  index,
  currentFoodItem,
  functions
) => {
  if (type === 'decrease') {
    return handleSetCurrentItemQuantityType.decrease(
      props,
      functions,
      currentFoodItem,
      id,
      index
    );
  } else if (type === 'getQuantity') {
    return handleSetCurrentItemQuantityType.getQuantity(props, functions, id);
  } else {
    return handleSetCurrentItemQuantityType.default(
      props,
      functions,
      id,
      currentFoodItem
    );
  }
};

export const handleSetCurrentFoodItemModifierQuantity = (
  id,
  currentFoodItemModifier,
  setCurrentFoodItemModifier
) => {
  const tmp = { ...currentFoodItemModifier };
  tmp[id] = tmp[id] <= 1 ? 0 : tmp[id] - 1;
  setCurrentFoodItemModifier(tmp);
};

export const handleSetCurrentFoodItemOutOfStock = (
  id,
  props,
  currentFoodItem,
  functions
) => {
  const {
    setCurrentFoodItem,
    isInInitialState,
    setButtonColor,
    setTotalPrice,
    setTotalNoOfItems,
  } = functions;
  if (!isNaN(id.substr(17))) {
    let requiredId = 'editOrderCloseBtn';
    requiredId += id.substr(17);
    const tmp = { ...currentFoodItem };
    tmp[requiredId] = 0;
    setCurrentFoodItem(tmp);
    setButtonColor(() => isInInitialState({ ...tmp }));
    const tmpStorePartiallyAcceptedItems = {
      ...props.storePartiallyAcceptedItems,
    };
    const t1 = calculateTotalForState(
      tmpStorePartiallyAcceptedItems,
      parseInt(id.substring(17)),
      'none',
      true
    );
    setTotalPrice(t1[0]);
    setTotalNoOfItems(t1[1]);
  }
};

export const handleCurrentFoodItemChange2 = (
  currentFoodItem,
  setPartialAcceptanceBtn
) => {
  const keysCount = Object.keys(currentFoodItem).length;
  let count = 0;
  for (const key in currentFoodItem) {
    if (currentFoodItem[key] === 0) {
      count += 1;
    }
  }
  if (count === keysCount) {
    setPartialAcceptanceBtn(false);
  } else {
    setPartialAcceptanceBtn(true);
  }
};

export const handleResetOrderItem = (
  currentOrderList,
  id,
  props,
  functions
) => {
  const {
    getCurrentOrder,
    setCurrentFoodItem,
    isInInitialState,
    setButtonColor,
    setTotalPrice,
    setTotalNoOfItems,
  } = functions;

  const requireId = 'editOrderCloseBtn' + id.substring(17);
  const tmp = getCurrentOrder(props.backupIncomingOrder);

  const tmpList = {};
  for (let index = 0; index < tmp.order_items?.length; index++) {
    const key = `editOrderCloseBtn${index}`;
    tmpList[key] = tmp.order_items[index]['quantity'];
  }
  const updatedList = { ...currentOrderList };
  updatedList[requireId] = tmpList[requireId];
  setCurrentFoodItem(updatedList);
  setButtonColor(() => isInInitialState({ ...updatedList }));
  const tmpStorePartiallyAcceptedItems = {
    ...props.storePartiallyAcceptedItems,
  };
  tmpStorePartiallyAcceptedItems.order_items[parseInt(id.substr(17))][
    'quantity'
  ] = tmpList[requireId];
  const t1 = calculateTotalForState(
    tmpStorePartiallyAcceptedItems,
    parseInt(id.substring(17)),
    'none'
  );
  setTotalPrice(t1[0]);
  setTotalNoOfItems(t1[1]);
  return tmpList[requireId];
};

export const isBothObjIsEqual = (obj1, obj2) => {
  for (const key in obj1) {
    if (key in obj2) {
      if (obj2[key] !== obj1[key]) {
        return false;
      }
    } else {
      return false;
    }
  }
  return true;
};

export const checkIfInitialState = (props, currentList, getCurrentOrder) => {
  const tmp = getCurrentOrder(props.backupIncomingOrder);

  const tmpList = {};
  for (let index = 0; index < tmp.order_items?.length; index++) {
    const key = `editOrderCloseBtn${index}`;
    tmpList[key] = tmp.order_items[index]['quantity'];
  }
  return isBothObjIsEqual(tmpList, { ...currentList });
};

export const formatDateToDateMonth = (newDate) => {
  const dateDate = new Date(newDate);
  const dateVal = dateDate?.getDate().toString().padStart(2, '0');
  const month = months[dateDate.getMonth()];
  return `${dateVal} ${month}`;
};
