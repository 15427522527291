// WARNING! These constants must match the reason enums on the AMP backend!
export const StoreClosureReasonsEnum = Object.freeze({
  ShortageOfStaff: 'shortage_of_staff',
  EmergencyIssue: 'emergency_issue',
  StoreClosingEarly: 'store_closing_early',
  HardwareIssue: 'hardware_issue',
  Other: 'other'
});

export const storeClosureReasons = [
  StoreClosureReasonsEnum.ShortageOfStaff,
  StoreClosureReasonsEnum.EmergencyIssue,
  StoreClosureReasonsEnum.StoreClosingEarly,
  StoreClosureReasonsEnum.HardwareIssue,
  StoreClosureReasonsEnum.Other,
];

export function formatReasonString(str) {
  // Replace all underscores with spaces
  const noUnderscores = str.replace(/_/g, ' ');

  // Capitalize the first letter of each word
  const capitalized = noUnderscores.split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  return capitalized;
}