import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { orderManagementActions } from '../../action/SVTOrderManagementAction';
import circlePlus from '../../images/circleplus.svg';
import circlePlusGrey from '../../images/circlePlusGrey.svg';
import circleMinusGreen from '../../images/circleMinusGreen.svg';
import circleMinus from '../../images/circleMinus.svg';
import CheckBox from '../../images/CheckBoxFaint.svg';
import emptyCheckBox from '../../images/emptyCheckBoxFaint.svg';
import OrderCategoryBarcode from '../../services/OrderCategoryBarcode';

const onComponentMount = (props) => {
  const { orderItems } = props;
  const tmp = {};
  const tmpCheck = {};
  for (let index = 0; index < orderItems?.length; index++) {
    const key = `editOrderCloseBtn${index}`;
    tmp[key] = orderItems[index]['quantity'];

    const checkKey = `editOrderCheckBox${index}`;
    tmpCheck[checkKey] = true;
    if (orderItems[index].children.length > 0) {
      for (
        let dummyIndex = 0;
        dummyIndex < orderItems[index].children.length;
        dummyIndex++
      ) {
        const key = `editOrderCloseBtn${orderItems[index].children[dummyIndex].plu}`;
        tmp[key] = orderItems[index].children[dummyIndex]['quantity'];

        const checkKey = `editOrderCheckBox${orderItems[index].children[dummyIndex].plu}`;
        tmpCheck[checkKey] = true;
      }
    }
  }

  if (props.orderDetails?.order_items) {
    props.orderDetails?.order_items.forEach(function (element) {
      element.active = true;
    });

    props.storePartiallyAcceptedItem(props.orderDetails);
  }
};
function SVTEditIncomingOrder(props) {
  const { currentFoodItem, setCurrentItemQuantity, resetOrderItem } = props;
  const orderItems = props.orderDetails?.order_items;

  const setItemQuantity = (e, index) => {
    const itemCount = setCurrentItemQuantity(e.target.id, 'decrease', index);
    const tmpStorePartiallyAcceptedItems = {
      ...props.storePartiallyAcceptedItems,
    };
    tmpStorePartiallyAcceptedItems.order_items[index].quantity = itemCount;
    props.storePartiallyAcceptedItem({ ...tmpStorePartiallyAcceptedItems });
    props.getCancelledItems();
    if (itemCount === 0) {
      props.setCurrentFoodItemOutOfStock(e.target.id);
    }
  };
  const setItemCheckBox = (e, index) => {
    if (e.target.alt === 'checked') {
      props.setCurrentFoodItemOutOfStock(e.target.id);
      const tmpStorePartiallyAcceptedItems = {
        ...props.storePartiallyAcceptedItems,
      };
      tmpStorePartiallyAcceptedItems.order_items[index].quantity = 0;
      props.storePartiallyAcceptedItem({ ...tmpStorePartiallyAcceptedItems });
    }
    if (e.target.alt === 'unchecked') {
      const tmp = resetOrderItem(currentFoodItem, e.target.id);
      const tmpStorePartiallyAcceptedItems = {
        ...props.storePartiallyAcceptedItems,
      };
      tmpStorePartiallyAcceptedItems.order_items[index].quantity = tmp;
      props.storePartiallyAcceptedItem({ ...tmpStorePartiallyAcceptedItems });
      props.getCancelledItems();
    }
    props.getCancelledItems();
  };

  React.useEffect(() => {
    onComponentMount({
      orderItems,
      orderDetails: props.orderDetails,
      storePartiallyAcceptedItem: props.storePartiallyAcceptedItem,
    });
  }, [orderItems]);
  const increaseItemQuantity = (e, index) => {
    const itemCount = setCurrentItemQuantity(e.target.id, 'increase');
    const tmpStorePartiallyAcceptedItems = {
      ...props.storePartiallyAcceptedItems,
    };
    tmpStorePartiallyAcceptedItems.order_items[index].quantity = itemCount;
    props.storePartiallyAcceptedItem({ ...tmpStorePartiallyAcceptedItems });
    props.getCancelledItems();
  };
  const orderList = orderItems?.map((item, index) => {
    return (
      <div key={'orderlist_incomingorder' + index} className='pb-3'>
        <div className='mt-2'>
          <Row className='mx-0'>
            <Col md={1} className='px-0 edit-order-checkbox-margin'>
              <div className='form-group'>
                {currentFoodItem[`editOrderCloseBtn${index}`] !== 0 ? (
                  <img
                    id={`editOrderCheckBox${index}`}
                    className='edit-order-cursor-pointer'
                    src={CheckBox}
                    alt='checked'
                    onClick={(e) => setItemCheckBox(e, index)}
                  ></img>
                ) : (
                  <img
                    id={`editOrderCheckBox${index}`}
                    className='edit-order-cursor-pointer'
                    src={emptyCheckBox}
                    alt='unchecked'
                    onClick={(e) => setItemCheckBox(e, index)}
                  ></img>
                )}
                <label htmlFor={`editOrderCheckBox${index}`}></label>
              </div>
            </Col>
            <Col
              md={10}
              className={
                currentFoodItem[`editOrderCloseBtn${index}`] === 0
                  ? 'px-0 edit-order-line-through'
                  : 'px-0'
              }
            >
              <div className='small_text gray_text capital_letter '>
                {OrderCategoryBarcode(item)}
              </div>
              <div>
                <Row className='mx-0'>
                  <Col
                    md={7}
                    className='px-0 large_text edit-order-font-bolder'
                  >
                    {item?.name}
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row className='pt-3 mx-0 price-col-for-smaller-screen'>
            <Col
              md={12}
              className='font-size-14 px-0 letter-spacing-O25 d-flex flex-row-reverse align-items-center'
            >
              <span>
                £
                {item.unit_price
                  ? (item.unit_price / 100).toFixed(2)
                  : (item.price / item.quantity / 100).toFixed(2)}
              </span>
            </Col>
          </Row>
          <Row
            className={
              currentFoodItem[`editOrderCloseBtn${index}`] === 0
                ? 'edit-order-line-through pt-3 mx-0'
                : 'pt-3 mx-0'
            }
          >
            <Col md={1}></Col>
            <Col md={10} className='d-flex padding-left-1322'>
              <img
                src={
                  currentFoodItem[`editOrderCloseBtn${index}`] === 0
                    ? circleMinus
                    : circleMinusGreen
                }
                alt='plus_icon'
                id={`editOrderCloseBtn${index}`}
                onClick={(e) => setItemQuantity(e, index)}
                className='pe-3'
              />
              <input
                className='edit-order-input'
                placeholder={
                  'x' +
                  (currentFoodItem
                    ? currentFoodItem[`editOrderCloseBtn${index}`]
                    : '')
                }
                readOnly={true}
              />

              <img
                src={
                  setCurrentItemQuantity(
                    `editOrderCheckBox${index}`,
                    'getQuantity'
                  ) > currentFoodItem[`editOrderCloseBtn${index}`]
                    ? circlePlus
                    : circlePlusGrey
                }
                id={`editOrderCheckBox${index}`}
                alt='plus_icon'
                className='ps-3'
                onClick={(e) => increaseItemQuantity(e, index)}
              />
            </Col>
            <Col
              md={1}
              className='font-size-14 px-0 letter-spacing-O25 d-flex flex-row-reverse align-items-center price-col-for-larger-screen'
            >
              <span>
                £
                {item.unit_price
                  ? (item.unit_price / 100).toFixed(2)
                  : (item.price / item.quantity / 100).toFixed(2)}
              </span>
            </Col>
          </Row>
        </div>
        {item?.children?.length > 0 &&
          item?.children?.map((itm, dummyIndex) => {
            return (
              <>
                <ModifierForEditOrder itm={itm} />
                {itm?.children &&
                  itm?.children.map((modifier) => {
                    return <ModifierForEditOrder itm={modifier} />;
                  })}
              </>
            );
          })}
      </div>
    );
  });

  return <>{orderList}</>;
}

function mapState(state) {
  const { orderManagementRelated } = state;
  const { backupIncomingOrder, storePartiallyAcceptedItems } =
    orderManagementRelated;
  return {
    orderManagementRelated,
    backupIncomingOrder,
    storePartiallyAcceptedItems,
  };
}
const actionCreators = {
  storePartiallyAcceptedItem: orderManagementActions.storePartiallyAcceptedItem,
};

export default compose(
  withRouter,
  connect(mapState, actionCreators)
)(SVTEditIncomingOrder);
function ModifierForEditOrder(props) {
  const { itm } = props;
  return (
    <Row className='mx-0 mb-10 mt-14'>
      <Col md={1}></Col>
      <Col
        md={7}
        className=' d-flex justify-content-start flex-gap-10 padding-left-1322'
      >
        <div className='d-flex flex-column padding-left-40'>
          <div className='large_text large_text'>{itm?.description}</div>
        </div>
      </Col>
      <Col md={3} className='px-0 d-flex flex-column'>
        <div className='w-100'>&nbsp;</div>
      </Col>
    </Row>
  );
}
