import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SVTOrderListComponent from '../aggregators/SVTOrderListComponent';
import SVTIncomingOrderComponent from '../aggregators/SVTIncomingOrderComponent';
import SVTJustCancelledComponent from '../aggregators/SVTJustCancelledComponent';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { orderManagementActions } from '../../action/SVTOrderManagementAction';
import SVTOrderAcceptPopup from './SVTOrderAcceptPopup';
import SVTNoOrderScreen from './SVTNoOrderScreen';
import { menuManagementAction } from '../../action/SVTMenuManagementAction';
import { authActions } from '../../action/SVTAuthActions';

const onComponentInit = (
  isAppInitializeAfterLogin,
  getTotalUnavailableCount,
  setIsAppInitializeAfterLogin
) => {
  if (isAppInitializeAfterLogin) {
    return;
  }
  getTotalUnavailableCount();
  setIsAppInitializeAfterLogin(true);
};

const onJustCancelledOrdersChange = (props) => {
  if (props.justCancelledOrderList) {
    const cancelledOrdersList = props.justCancelledOrderList;
    const currentCancelledNotificationsList = [];
    cancelledOrdersList.forEach((order) => {
      const tmpNotification = {
        id: order.id,
        body: 'This notification  is not come from firebase',
      };
      currentCancelledNotificationsList.push(tmpNotification);
    });
    props.saveCancelledOrderNewNotification(currentCancelledNotificationsList);
  }
};

const onIncomingOrdersChange = (props) => {
  if (props.incomingOrders) {
    const incomingOrdersList = props.incomingOrders;
    const currentIncomingNotificationsList = [];
    if (incomingOrdersList) {
      incomingOrdersList.forEach((order) => {
        const tmpNotification = {
          id: order.id,
          body: 'This notification  is not come from firebase',
        };
        currentIncomingNotificationsList.push(tmpNotification);
      });
    }
    props.saveIncomingOrderNewNotification(currentIncomingNotificationsList);
  }
};

function SVTLiveOrderComponents(props) {
  const {
    completeOrder,
    getOrderList,
    getIncomingOrders,
    cancelOrder,
    getOrderReportCount,
    showTopMsg,
    isAppInitializeAfterLogin,
    setIsAppInitializeAfterLogin,
    getTotalUnavailableCount,
    getJustCancelledOrderList,
  } = props;
  const [orderTab, setOrderTab] = useState('Incoming');

  React.useEffect(() => {
    props.showEditedOrders(false);
    props.setCurrentPath(props.location.pathname);
    props.getJustCancelledOrderList();
  }, []);

  // ! Those api which need to call only once after login can be called here.
  React.useEffect(() => {
    onComponentInit(
      isAppInitializeAfterLogin,
      getTotalUnavailableCount,
      setIsAppInitializeAfterLogin
    );
  }, []);

  React.useEffect(() => {
    onJustCancelledOrdersChange(props);
  }, [props?.justCancelledOrderList]);

  React.useEffect(() => {
    onIncomingOrdersChange(props);
  }, [props?.incomingOrders]);

  React.useEffect(() => {
    getOrderList();
    getIncomingOrders();
    getOrderReportCount();
    getJustCancelledOrderList();
  }, [JSON.stringify(completeOrder), JSON.stringify(cancelOrder)]);

  return (
    <div className=''>
      {[1, 2, 3, 4, 5].map((item, index) => (
        <SVTOrderAcceptPopup type='accepted' orderId={item} key={index} />
      ))}
      <Row>
        <Col
          lg={4}
          md={12}
          sm={12}
          className={
            showTopMsg.show
              ? 'margin-top-live-order-with-bar-notification'
              : 'margin-top-live-order'
          }
        >
          <div className='card_block'>
            <Container fluid className='px-0'>
              <Row className='mx-0'>
                <Col md={6} sm={6} xxl={5} className='px-1 custom-row'>
                  <div
                    className={`d-flex align-items-center pb-2 pt-3 cursor-pointer
                            ${
                              orderTab === 'Incoming'
                                ? 'active_class'
                                : 'non_active_class'
                            }`}
                    onClick={() => {
                      setOrderTab('Incoming');
                    }}
                  >
                    <div
                      className={
                        orderTab === 'Incoming'
                          ? 'active_text'
                          : 'non_active_text'
                      }
                    >
                      Incoming Orders
                    </div>
                    <div className='notification_style green_background-color ms-2'>
                      {props?.orderReportListCount?.incoming_orders_count}
                    </div>
                  </div>
                </Col>
                <Col md={6} sm={6} xxl className='custom-row'>
                  <div
                    className={`d-flex align-items-center pb-2 pt-3 cursor-pointer
                            ${
                              orderTab === 'Cancelled'
                                ? 'active_class'
                                : 'non_active_class'
                            }`}
                    onClick={() => {
                      setOrderTab('Cancelled');
                    }}
                  >
                    <div
                      className={
                        orderTab === 'Cancelled'
                          ? 'active_text'
                          : 'non_active_text'
                      }
                    >
                      Just Cancelled
                    </div>
                    <div
                      className={
                        props?.orderReportListCount
                          ?.just_canceled_orders_count === 0
                          ? 'notification_style ms-2 gray_background_color'
                          : 'notification_style ms-2 org_background_color'
                      }
                    >
                      {props?.orderReportListCount?.just_canceled_orders_count}
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className='mx-0'>
                <Col md={12} sm={12} className='px-0'>
                  {orderTab === 'Incoming'
                    ? (props?.orderReportListCount?.incoming_orders_count >
                        0 && <SVTIncomingOrderComponent />) || (
                        <SVTNoOrderScreen />
                      )
                    : (props?.orderReportListCount?.just_canceled_orders_count >
                        0 && <SVTJustCancelledComponent />) || (
                        <SVTNoOrderScreen />
                      )}
                </Col>
              </Row>
            </Container>
          </div>
        </Col>
        <Col
          lg={8}
          md={12}
          className={
            showTopMsg.show
              ? 'margin-top-live-order-with-bar-notification'
              : 'margin-top-live-order'
          }
        >
          <div className='card_block'>
            <div>
              <SVTOrderListComponent />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}
export function mapState(state) {
  const { orderManagementRelated, authInfo } = state;
  const {
    orderReportListCount,
    justCancelledOrderList,
    completeOrder,
    cancelOrder,
    showTopMsg,
    incomingOrders,
  } = orderManagementRelated;
  const { isAppInitializeAfterLogin } = authInfo;
  return {
    orderReportListCount,
    justCancelledOrderList,
    completeOrder,
    cancelOrder,
    isAppInitializeAfterLogin,
    showTopMsg,
    incomingOrders,
  };
}
const actionCreators = {
  showEditedOrders: orderManagementActions.showEditedOrders,
  setCurrentPath: orderManagementActions.setCurrentPath,
  saveCancelledOrderNewNotification:
    orderManagementActions.saveCancelledOrderNewNotification,
  saveIncomingOrderNewNotification:
    orderManagementActions.saveIncomingOrderNewNotification,
  getJustCancelledOrderList: orderManagementActions.getJustCancelledOrderList,
  getOrderList: orderManagementActions.getOrderList,
  getIncomingOrders: orderManagementActions.getIncomingOrders,
  getOrderReportCount: orderManagementActions.getOrderReportCount,
  setIsAppInitializeAfterLogin: authActions.setIsAppInitializeAfterLogin,
  getTotalUnavailableCount: menuManagementAction.getTotalUnavailableCount,
};

export default compose(
  withRouter,
  connect(mapState, actionCreators)
)(SVTLiveOrderComponents);
