import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { orderManagementActions } from '../../action/SVTOrderManagementAction';
export const formatAMPM = (date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const amOrPm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  hours = hours < 10 ? '0' + hours : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  return `${hours} : ${minutes} ${amOrPm}`;
};
function SVTJustCancelledDetailsComponent(props) {
  const { image, ordersData, setShowTopMsg } = props;

  return (
    <>
      <div
        className='Accordion_Header'
        data-test='justCancelledDetailsContainer'
      >
        <div>
          <img src={image} alt='uber_logo' />
        </div>
        <div className='ms-12'>
          <div className='id_style'>
            #{ordersData?.partner_id?.toUpperCase()}
          </div>
          <div className='small_text gray_text mb-2'>
            <span>
              Cancelled at{' '}
              {formatAMPM(
                new Date(
                  ordersData?.cancelled_at ?? '2022-02-18T00:56:01.446804'
                )
              )}{' '}
              by&nbsp;
              {ordersData?.cancelled_by ? ordersData?.cancelled_by : 'Customer'}
            </span>
          </div>
          <div>
            <div className='items_count_style text-decoration-line-through'>
              {ordersData?.count?.total_items} items
            </div>
          </div>
        </div>
      </div>

      <div
        className={
          'Accordion_Footer justify-content-end dismiss-row-padding-top'
        }
      >
        <button
          type='button'
          className='success-border-btn dismiss-btn-just-cancelled-margin-right'
          onClick={() => dismissOrder(ordersData, props, setShowTopMsg)}
          data-test='dismissOrder'
        >
          Dismiss
        </button>
        <button
          type='button'
          className='success-btn'
          onClick={() => showOrderDetails(ordersData, props, setShowTopMsg)}
          data-test='showOrderDetails'
        >
          View
        </button>
      </div>
    </>
  );
}
const showOrderDetails = async (order, props, setShowTopMsg) => {
  //for muting the sound
  if (props?.cancelledOrderNotifications) {
    const cancelledOrderNotifications = [...props?.cancelledOrderNotifications];
    if (cancelledOrderNotifications.length > 0) {
      cancelledOrderNotifications.pop();
      props.saveCancelledOrderNewNotification(cancelledOrderNotifications);
    }
  }
  const headerMsgPayload = {
    show: false,
    type: '',
    msg: '',
    orderId: '',
    partnerId: '',
  };
  setShowTopMsg(headerMsgPayload);
  props.justCancelledSelectedOrderDetails(order);
  props.storeOrderDetails(order);
  props.setShouldShowEditOrderViewInOrderDetailsPage(false);
  props.history.push('/order-details/Cancelled');
  const dismissCancelledOrder = await props.dismissLiveOrders(order.id);
  if (dismissCancelledOrder?.status === 'SUCCESS') {
    props.getJustCancelledOrderList();
  }
};
const dismissOrder = async (order, props, setShowTopMsg) => {
  //for muting the sound
  if (props?.cancelledOrderNotifications) {
    const cancelledOrderNotifications = [...props?.cancelledOrderNotifications];
    if (cancelledOrderNotifications.length > 0) {
      cancelledOrderNotifications.pop();
      props.saveCancelledOrderNewNotification(cancelledOrderNotifications);
    }
  }
  const headerMsgPayload = {
    show: false,
    type: '',
    msg: '',
    orderId: '',
    partnerId: '',
  };
  setShowTopMsg(headerMsgPayload);
  const disMissOrders = await props.dismissLiveOrders(order.id);
  if (disMissOrders?.status === 'SUCCESS') {
    props.getOrderReportCount();
    props.getJustCancelledOrderList();
  }
};
function mapState(state) {
  const { orderManagementRelated } = state;
  const { cancelledOrderNotifications } = orderManagementRelated;
  return { cancelledOrderNotifications };
}
const actionCreators = {
  justCancelledSelectedOrderDetails:
    orderManagementActions.justCancelledSelectedOrderDetails,
  dismissLiveOrders: orderManagementActions.dismissLiveOrders,
  getJustCancelledOrderList: orderManagementActions.getJustCancelledOrderList,
  saveCancelledOrderNewNotification:
    orderManagementActions.saveCancelledOrderNewNotification,
  getOrderReportCount: orderManagementActions.getOrderReportCount,
  setShowTopMsg: orderManagementActions.setShowTopMsg,
  storeOrderDetails: orderManagementActions.storeOrderDetails,
  setShouldShowEditOrderViewInOrderDetailsPage:
    orderManagementActions.setShouldShowEditOrderViewInOrderDetailsPage,
};

export default compose(
  withRouter,
  connect(mapState, actionCreators)
)(SVTJustCancelledDetailsComponent);
