import React, { useEffect, useState } from 'react';
import { Offcanvas, Row, Col, Container } from 'react-bootstrap';
import info_icon from '../../images/info.svg';
import green_outline_cross_icon from '../../images/bp-icon-crossmark-green.svg';
import close from '../../images/close.svg';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { orderManagementActions } from '../../action/SVTOrderManagementAction';

import order_received from '../../images/order_received.svg';
import order_completed from '../../images/order_completed.svg';
import salesIngestionNotificationIcon from '../../images/bp-icon-finance-asset-review-black.svg';
import bellIcon from '../../images/Notification ALT.svg';

const storeMarked = 'Store Marked';
const updateNotificationStatesOnChange = (
  props,
  setAllNotificationList,
  setRecentNotificationList
) => {
  if (props?.allNotificationsList) {
    setAllNotificationList(props?.allNotificationsList);
  }
  if (props.recentNotificationsList) {
    setRecentNotificationList(props.recentNotificationsList);
  }
};

const updateNotificationCountOnChange = (
  props,
  recentNotificationList,
  allNotificationList
) => {
  const allNotificationCount =
    recentNotificationList.length + allNotificationList.length;
  if (props.setAllNotificationCount) {
    props.setAllNotificationCount(allNotificationCount);
  }
};

const handleClearNotification = async (props, item) => {
  const payload = {
    id: item.id,
    status: 'Deactive',
  };
  const notificationStatus = await props.updateNotificationStatus(payload);
  if (notificationStatus?.status === 'SUCCESS') {
    props.getAllNotificationsList();
  }
};

const handleClearAllNotification = async (
  props,
  activeLink,
  recentNotificationList
) => {
  let payload = {};
  if (activeLink.all) {
    payload = {
      id: '*',
      status: 'Deactive',
    };
  } else if (activeLink.recent) {
    let ids = '';
    for (let index = 0; index < recentNotificationList.length; index++) {
      ids += recentNotificationList[index]['id'] + ', ';
      payload = {
        id: ids,
        status: 'Deactive',
      };
    }
  }
  const notificationStatus = await props.updateNotificationStatus(payload);
  if (notificationStatus?.status === 'SUCCESS') {
    props.getAllNotificationsList();
  }
};

const handleViewItemDetails = async (props, item) => {
  props.onHide();
  handleClearNotification(props, item);
  // Handling the Tap to View for sale injection
  if (item?.message?.includes('Sales Ingestion')) {
    props.history.push('/order-history');
    const headerMsgPayload = {
      show: false,
      type: '',
      msg: '',
      orderId: '',
      partnerId: '',
    };
    props.setShowTopMsg(headerMsgPayload);
    props.setIsFromSaleInjectionTapToView(true);
    return;
  }
  const queryString = `id=${item.order_id}`;
  const itemDetails = await props.getSingleItemDetails(queryString);
  if (itemDetails?.status === 'success') {
    props.getAllNotificationsList();
    props.history.push('/order-details/Incoming');
  }
};

function SVTNotificationComponent(props) {
  const [activeLink, setActiveLink] = React.useState({
    recent: true,
    all: false,
  });
  const [recentNotificationList, setRecentNotificationList] = useState([]);
  const [allNotificationList, setAllNotificationList] = useState([]);

  useEffect(() => {
    updateNotificationStatesOnChange(
      props,
      setAllNotificationList,
      setRecentNotificationList
    );
  }, [props?.allNotificationsList, props.recentNotificationsList]);

  const clearNotification = async (item) => {
    await handleClearNotification(props, item);
  };

  const viewItemDetails = async (item) => {
    await handleViewItemDetails(props, item);
  };

  const clearAllNotification = async () => {
    await handleClearAllNotification(props, activeLink, recentNotificationList);
  };

  React.useEffect(() => {
    updateNotificationCountOnChange(
      props,
      recentNotificationList,
      allNotificationList
    );
  }, [recentNotificationList.length, allNotificationList.length]);

  return (
    <>
      <Offcanvas
        show={props.show}
        onHide={props.onHide}
        placement='end'
        className='notification-box'
        backdrop={true}
        backdropClassName='offcanvas-backdrop'
      >
        <Offcanvas.Header className='gray-bg m-0 p-0 ps-4 pt-4 pe-5 '>
          <Container fluid className='gray-bg m-0 p-0'>
            <Row>
              <Col md={11} xs={10} className='pt-2 notifications-main-font'>
                Notifications
              </Col>
              <Col md={1} xs={2}>
                <img
                  src={close}
                  alt='close_icon'
                  onClick={props.onHide}
                  className='cursor-pointer'
                />
              </Col>
            </Row>
            <Row className='pt-4 pe-3 notification-row-clear-all-spacing'>
              <Col md={3} xs={4} sm={4}>
                <div
                  className={
                    'd-flex pb-3 maxWidthAuto ' +
                    (activeLink.recent ? 'active-main-header-link' : '')
                  }
                >
                  <div
                    className='pt-1 cursor-pointer'
                    onClick={() =>
                      setActiveLink({
                        recent: true,
                        all: false,
                      })
                    }
                  >
                    Recent
                  </div>
                  <div
                    className={
                      recentNotificationList.length
                        ? 'notification_style green_background-color ms-2'
                        : 'notification_style ms-2 pale-grey-bg'
                    }
                  >
                    {recentNotificationList.length}
                  </div>
                </div>
              </Col>
              <Col md={2} xs={4} sm={4}>
                <div
                  className={
                    'd-flex pb-3 maxWidthAuto ' +
                    (activeLink.all ? 'active-main-header-link' : '')
                  }
                >
                  <div
                    className='pt-1 cursor-pointer'
                    onClick={() =>
                      setActiveLink({
                        recent: false,
                        all: true,
                      })
                    }
                  >
                    All
                  </div>
                  <div className='notification_style pale-grey-bg ms-2'>
                    {allNotificationList.length}
                  </div>
                </div>
              </Col>
              <Col md={5} xs={1} sm={1}></Col>
              <Col md={2} sm={3} xs={3} className='pb-2'>
                {activeLink.all && allNotificationList.length > 0 ? (
                  <button
                    className='gray-outline-btn gray-outline-btn-txt'
                    onClick={() => {
                      clearAllNotification();
                    }}
                  >
                    Clear All
                  </button>
                ) : (
                  activeLink.recent &&
                  ((recentNotificationList.length > 0 && (
                    <button
                      className='gray-outline-btn gray-outline-btn-txt'
                      onClick={() => {
                        clearAllNotification();
                      }}
                    >
                      Clear All
                    </button>
                  )) ||
                    '')
                )}
              </Col>
            </Row>
          </Container>
        </Offcanvas.Header>
        <Offcanvas.Body className='m-0 p-0 scroller-notification spacing-scroller'>
          {(activeLink.recent &&
            (recentNotificationList.length !== 0 ? (
              recentNotificationList.map((item, index) => (
                <div
                  key={index}
                  className='pt-3 ps-4 pe-4'
                  id={`${item?.partner_id}_${index}`}
                >
                  <NoticationCard
                    item={item}
                    clearNotification={clearNotification}
                    viewItemDetails={viewItemDetails}
                  />
                </div>
              ))
            ) : (
              <NoNotificationCard />
            ))) ||
            ''}

          {(activeLink.all &&
            (allNotificationList.length !== 0 ? (
              allNotificationList.map((item, index) => (
                <div
                  id={`${item?.partner_id}_${index}`}
                  key={index}
                  className='pt-3 ps-4 pe-4'
                >
                  <NoticationCard
                    item={item}
                    viewItemDetails={viewItemDetails}
                    clearNotification={clearNotification}
                  />
                </div>
              ))
            ) : (
              <NoNotificationCard />
            ))) ||
            ''}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

function mapState(state) {
  const { orderManagementRelated } = state;
  const { recentNotificationsList, allNotificationsList } =
    orderManagementRelated;
  return {
    recentNotificationsList,
    allNotificationsList,
  };
}
const actionCreators = {
  updateNotificationStatus: orderManagementActions.updateNotificationStatus,
  getSingleItemDetails: orderManagementActions.getSingleItemDetails,
  setAllNotificationCount: orderManagementActions.setAllNotificationCount,
  getAllNotificationsList: orderManagementActions.getAllNotificationsList,
  filterOrderHistory: orderManagementActions.filterOrderHistory,
  setShowTopMsg: orderManagementActions.setShowTopMsg,
  setIsFromSaleInjectionTapToView:
    orderManagementActions.setIsFromSaleInjectionTapToView,
};

export default compose(
  withRouter,
  connect(mapState, actionCreators)
)(SVTNotificationComponent);

function extractOrderID(message, orderId) {
  let i = 0;
  const returnArrayData = [];
  const splitData = message.split(' ');
  let mPrior = '';
  let mAfter = '';
  for (; i < splitData.indexOf(orderId); i++) {
    mPrior += splitData[i];
    mPrior += ' ';
  }
  i += 1;
  for (; i < splitData.length; i++) {
    mAfter += splitData[i];
    mAfter += ' ';
  }
  if (mAfter.endsWith(' ')) {
    mAfter = mAfter.trimRight();
  }
  returnArrayData.push(mPrior);
  returnArrayData.push(orderId);
  returnArrayData.push(mAfter);
  return returnArrayData;
}

function formatMessage(message) {
  let orderId = '';
  let messageBody = '';
  const messageArr = message.split(':');
  orderId = messageArr[messageArr.length - 1]?.trim();
  messageBody = messageArr[0];
  if (messageBody.includes('received')) {
    messageBody = (
      <span>
        A new order <strong>#{orderId + ' '}</strong>
        has arrived.
      </span>
    );
  } else if (message.includes('cancelled')) {
    messageBody = (
      <span>
        Order <strong>#{orderId + ' '}</strong>
        has been cancelled.
      </span>
    );
  } else if (message.includes('Sales Ingestion')) {
    messageBody = (
      <span>
        Something went wrong with order <strong>#{orderId + ' '}</strong>
        {', '} please manually enter the details in the POS system.
      </span>
    );
  } else if (message.includes(storeMarked)) {
    messageBody = <span>{message.replace('-', ':')}.</span>;
  } else {
    messageBody = (
      <span>
        {messageArr[0] + ': '} <strong> #{orderId}.</strong>
      </span>
    );
  }
  return messageBody;
}
function NoticationCard(props) {
  const itemVal = props.item;

  const notificationText = formatMessage(itemVal.message);
  return (
    <>
      <Container fluid className='pb-3 border-below ms-2'>
        <Row>
          <Col xs={12} className='notification-card-time px-0'>
            {(itemVal.message.toLowerCase().includes('completed') && (
              <>{itemVal.time} • SILENT NOTIFICATION</>
            )) ||
              (itemVal.message.toLowerCase().includes('received') && (
                <>{itemVal.time} • SILENT NOTIFICATION</>
              )) || <>{itemVal.time}</>}
          </Col>
        </Row>
        <Row className='py-1'>
          <Col md={1} className='pt-2'>
            <RenderAppropriateNotificationIcon message={itemVal.message} />
          </Col>
          <Col md={10}>
            <div
              className={
                itemVal.message.includes('received') ||
                itemVal.message.includes(storeMarked)
                  ? 'pb-15 pt-15'
                  : ''
              }
            >
              {notificationText}
            </div>
            {!(
              itemVal.message.includes('received') ||
              itemVal.message.includes(storeMarked)
            ) && (
              <a
                className='green-text text-decoration-none cursor-pointer'
                onClick={() => props.viewItemDetails(itemVal)}
              >
                Tap to view.
              </a>
            )}
          </Col>
          <Col md={1}>
            <img
              src={green_outline_cross_icon}
              alt='cross'
              onClick={() => props.clearNotification(itemVal)}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}

function NoNotificationCard() {
  return (
    <div className='text-center ps-2' style={{ paddingTop: '80px' }}>
      <span className='pe-2'>
        <img src={bellIcon} alt='bell' />
      </span>
      <span className='no-notification-text'>No notifications</span>
    </div>
  );
}
function RenderAppropriateNotificationIcon({ message }) {
  if (message.toLowerCase().includes('completed')) {
    return <img src={order_completed} alt='cross' width='36px' height='36px' />;
  } else if (message.toLowerCase().includes('received')) {
    return <img src={order_received} alt='cross' width='36px' height='36px' />;
  } else if (message.toLowerCase().includes('sales ingestion ')) {
    return (
      <img
        src={salesIngestionNotificationIcon}
        alt='salesIngestionNotificationIcon'
        width='36px'
        height='36px'
      />
    );
  } else {
    return <img src={info_icon} alt='cross' width='35.82px' height='35.82px' />;
  }
}
