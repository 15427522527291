import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import SVTPrintReceipt from './SVTPrintReceipt';
import upArrowNoteIcon from '../../images/arrow-up-note.svg';
import OrderCategoryBarcode from '../../services/OrderCategoryBarcode';

const orderDetailsFunction = (orderDetails, itm) => {
  if (orderDetails?.partner_name === 'Deliveroo') {
    return itm?.name;
  } else {
    return itm?.description;
  }
};

function SVTHistoryItemList(props) {
  const orderDetails = props.orderDetails;
  const history = useHistory();

  function showOrderHistoryList() {
    history.push('/order-history');
  }

  const orderViewHandler = (item, itm) => {
    return (
      <div key={itm?.barcodes}>
        <Row className='m-0 mt-0 pb-2'>
          <Col sm={1} md={1}></Col>
          <Col md={7} className='m-0  pl-40'>
            <div className='light-grey-txt font-size-12 letter-spacing-15 text-break text-uppercase'>
              {OrderCategoryBarcode(itm)}
            </div>
            <div>{orderDetailsFunction(orderDetails, itm)} </div>
          </Col>
          <Col
            md={2}
            className='order-font-history-item-list align-self-end px-1'
          >
            x{itm?.quantity ? itm?.quantity : 1}
          </Col>
          <Col
            md={2}
            className='order-font-history-item-list align-self-end px-1'
          >
            £
            {(itm?.unit_price
              ? itm?.unit_price / 100
              : itm?.price / itm?.quantity / 100
            ).toFixed(2)}
          </Col>
        </Row>
        {itm?.children?.length > 0 &&
          itm?.children?.map((childItem) => {
            return orderViewHandler(item, childItem);
          })}
      </div>
    );
  };

  const orderItems = orderDetails?.order_items?.map((item, index) => {
    return (
      <div className='m-0 p-0 pt-2' key={index}>
        <Row className='m-0 p-0 pt-2'>
          <Col md={1}>
            <img src={item?.image_url} height='50px' width='50px' alt=''></img>
          </Col>
          <Col sm={7} className='my-2'>
            <div className='light-grey-txt font-size-12 letter-spacing-15 text-break text-uppercase'>
              {OrderCategoryBarcode(item)}
            </div>
            <div className='letter-spacing-O50'>{item?.name}</div>
          </Col>

          <Col md={2} className='order-font-history-item-list px-1'>
            x{item?.quantity ? item?.quantity : 1}
          </Col>
          <Col md={2} className='order-font-history-item-list px-1'>
            £
            {(item?.unit_price
              ? item?.unit_price / 100
              : item?.price / item?.quantity / 100
            ).toFixed(2)}
          </Col>
        </Row>
        {item?.children?.length > 0 &&
          item?.children?.map((itm) => {
            return orderViewHandler(item, itm);
          })}
        {/* If any special_instruction for order-items then it will show */}
        {item?.special_instructions?.length > 0 && (
          <Row className='mx-0 mt-2 pb-3'>
            <Col md={12}>
              <img
                src={upArrowNoteIcon}
                alt='upArrowNoteIcon'
                className='item-specific-instruction-image'
              />
              <span className='ps-1 item-specific-instruction'>
                {item?.special_instructions}
              </span>
            </Col>
          </Row>
        )}
      </div>
    );
  });

  return (
    <>
      <div
        className={
          props.cancelled === 'Cancelled'
            ? 'menu-table-container-cancelled margin-top-menu-table-history'
            : 'menu-table-container-history margin-top-menu-table-history'
        }
        data-test='historyItemListContainer'
      >
        <div
          className={
            props.cancelled === 'Cancelled'
              ? 'menu-table menu-table-order-history-cancelled'
              : 'menu-table menu-table-order-history'
          }
        >
          <Row className='m-0 p-0 pb-2 menu-header border-below'>
            <Col md={1} className='px-0 order-details-item-qty-price'></Col>
            <Col md={7} className='px-0 order-details-item-qty-price'>
              Item Name
            </Col>
            <Col md={2} className='order-details-item-qty-price '>
              {'QTY'}
            </Col>
            <Col md={2} className='order-details-item-qty-price'>
              {'PRICE'}
            </Col>
          </Row>
          <Row>{orderItems}</Row>
          <Row className='m-0 p-0 border-bottom pt-3'> </Row>
          <Row className='mt-2 order-font-history-item-list'>
            <Col
              md={8}
              className='pt-2 pb-2 large_text'
              style={{ paddingLeft: '70px' }}
            >
              Total
            </Col>
            <Col md={2} className='pt-2 pb-2 px-1'>
              x{orderDetails?.count?.total_items}
            </Col>
            <Col md={2} className='px-1 pt-2 pb-2'>
              £{(parseFloat(orderDetails?.total_price) / 100).toFixed(2)}
            </Col>
          </Row>
        </div>
        <div className='order-history-footer d-flex justify-content-between col-md-12 col-sm-12 col-12'>
          {orderDetails?.order_items?.length > 0 ? (
            <div className='printer-icon-order-history content-display'>
              <SVTPrintReceipt details={orderDetails} />
            </div>
          ) : (
            <div className='content-display'></div>
          )}
          <div>
            <div
              className='order-history-view-order-btn-back cursor-pointer'
              onClick={showOrderHistoryList}
              data-test='showOrderHistoryListBtn'
            >
              <span className='order-history-item-list-btn-back-text'>
                {'Back'}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SVTHistoryItemList;
