/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import firebase from '../../services/SVTFirebaseService';
import { getMessaging, onMessage } from 'firebase/messaging';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { orderManagementActions } from '../../action/SVTOrderManagementAction';

import incomingSound from '../../sound/Incoming-order.wav';
import cancelledSound from '../../sound/Cancelled-order.wav';
import { log, LogType } from '../../helper/common';

const incomingTone = new Audio(incomingSound);
const cancelledTone = new Audio(cancelledSound);
incomingTone.loop = true;
cancelledTone.loop = true;
const pauseIncomingTone = () => !incomingTone.paused && incomingTone.pause();
const pauseCancelledTone = () => !cancelledTone.paused && cancelledTone.pause();
const audioPlayErrorMessage =
  'User is not given permission to play audio and because of that an error occurred with message';

const channel = new BroadcastChannel('sw-messages');

const isPresentInIncomingOrderNotificationsList = (
  incomingOrderNotifications,
  id
) => {
  for (const element of incomingOrderNotifications) {
    if (element['id'] === id) {
      return true;
    }
  }
  return false;
};

const isPresentInCancelledOrderNotificationsList = (
  cancelledOrderNotifications,
  id
) => {
  for (const element of cancelledOrderNotifications) {
    if (element.id === id) {
      return true;
    }
  }
  return false;
};

const acknowledgeNotification = (
  payload,
  partnerId,
  type,
  orderId,
  currentPath,
  notificationAcknowledgement
) => {
  const currentNotification = {
    id: partnerId,
    type: type,
    order_id: orderId,
    title: payload.notification.title,
    notification: {
      title: payload.notification.title,
      id: orderId,
    },
  };

  const ackPayload = {
    notification_id: payload.data.notification_id,
    received_time: new Date().toLocaleString(),
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    screenX: window.innerWidth,
    screenY: window.innerHeight,
    hidden: document.hidden,
    currentPage: currentPath,
  };

  notificationAcknowledgement(ackPayload);

  return currentNotification;
};

const assigningDriverNotification = (
  partnerId,
  orderId,
  currentNotification,
  props
) => {
  const {
    setShowTopMsg,
    saveHeaderMsgOrderId,
    getOrderReportCount,
    getAllNotificationsList,
    getIncomingOrders,
    getIncomingOrdersBackup,
    getJustCancelledOrderList,
    getOrderList,
  } = props;

  const headerMsg = `Assigning driver to order #${partnerId}#`;
  const headerMsgPayload = {
    show: true,
    partnerId: partnerId,
    orderId: orderId,
    type: 'assigningdriver',
    msg: headerMsg,
  };
  setShowTopMsg(headerMsgPayload);
  saveHeaderMsgOrderId(currentNotification.id);
  getIncomingOrders();
  getIncomingOrdersBackup();
  getOrderList();
  getAllNotificationsList();
  getJustCancelledOrderList();
  getOrderReportCount();
};

const driverArrivedNotification = (
  partnerId,
  orderId,
  currentNotification,
  props
) => {
  const {
    setShowTopMsg,
    saveHeaderMsgOrderId,
    getIncomingOrders,
    getIncomingOrdersBackup,
    getOrderList,
    getAllNotificationsList,
    getJustCancelledOrderList,
    getOrderReportCount,
  } = props;

  const headerMsg = `A driver has arrived to pick up order #${partnerId}#`;
  const headerMsgPayload = {
    show: true,
    type: 'driverarrived',
    msg: headerMsg,
    orderId: orderId,
    partnerId: partnerId,
  };
  setShowTopMsg(headerMsgPayload);
  saveHeaderMsgOrderId(currentNotification.id);
  getIncomingOrders();
  getIncomingOrdersBackup();
  getOrderList();
  getAllNotificationsList();
  getJustCancelledOrderList();
  getOrderReportCount();
};

const movedToOrderHistoryNotification = (orderId, props) => {
  const {
    removeCancelledNotificationById,
    getIncomingOrders,
    getIncomingOrdersBackup,
    getOrderList,
    getAllNotificationsList,
    getJustCancelledOrderList,
    getOrderReportCount,
  } = props;

  removeCancelledNotificationById(orderId);
  getIncomingOrders();
  getIncomingOrdersBackup();
  getOrderList();
  getAllNotificationsList();
  getJustCancelledOrderList();
  getOrderReportCount();
};

const completedNotification = (
  currentHeaderMsgOrderId,
  currentNotification,
  props
) => {
  const {
    setShowTopMsg,
    getOrderList,
    getAllNotificationsList,
    getJustCancelledOrderList,
    getOrderReportCount,
  } = props;

  if (currentHeaderMsgOrderId === currentNotification.id) {
    const headerMsgPayload = {
      show: false,
      type: 'others',
      msg: '',
      orderId: '',
    };
    setShowTopMsg(headerMsgPayload);
  }

  getOrderList();
  getAllNotificationsList();
  getJustCancelledOrderList();
  getOrderReportCount();
};

const defaultNotification = (props) => {
  const {
    getIncomingOrders,
    getIncomingOrdersBackup,
    getOrderList,
    getAllNotificationsList,
    getJustCancelledOrderList,
    getOrderReportCount,
  } = props;

  getIncomingOrders();
  getIncomingOrdersBackup();
  getOrderList();
  getAllNotificationsList();
  getJustCancelledOrderList();
  getOrderReportCount();
};

const onFirebaseInit = async (props) => {
  const {
    accessToken,
    payload,
    incomingOrderNotifications,
    cancelledOrderNotifications,
    currentHeaderMsgOrderId,
    currentPath,
    showTopMsg,

    setShowTopMsg,
    getIncomingOrders,
    getIncomingOrdersBackup,
    getOrderList,
    getJustCancelledOrderList,
    getAllNotificationsList,
    getOrderReportCount,
    saveIncomingOrderNewNotification,
    removeIncomingNotificationById,
    saveCancelledOrderNewNotification,
    notificationAcknowledgement,
    getStoreDetails,
  } = props;

  if (!accessToken?.length) {
    return;
  }

  const {
    type = '',
    partner_id: partnerId = '',
    order_id: orderId = '',
  } = payload.data;

  const currentNotification = acknowledgeNotification(
    payload,
    partnerId,
    type,
    orderId,
    currentPath,
    notificationAcknowledgement
  );

  if (showTopMsg.type === 'store_unattended') {
    return;
  }

  switch (type) {
    case 'store_unattended': {
      setShowTopMsg({
        show: true,
        type: type,
        msg: 'Your store has been closed to customer due to the device unattended. Please click here to resume.',
        orderId: '',
        partnerId: '',
      });
      getStoreDetails();
      getAllNotificationsList();
      break;
    }
    case 'sale_ingestion': {
      const headerMsg = `Something went wrong with order #${partnerId}, please manually enter the details in the POS system. `;
      const headerMsgPayload = {
        show: true,
        type: 'saleIngestion',
        msg: headerMsg,
        orderId: orderId,
        partnerId: partnerId,
      };
      setShowTopMsg(headerMsgPayload);
      getIncomingOrders();
      getIncomingOrdersBackup();
      getOrderList();
      getJustCancelledOrderList();
      getAllNotificationsList();
      getOrderReportCount();
      break;
    }
    case 'pendingaction': {
      const headerMsg = `Kindly review/accept Order : #${partnerId} in the Incoming Orders tab`;
      const typeName = 'PendingAction';
      const headerMsgPayload = {
        show: true,
        type: typeName,
        msg: headerMsg,
        orderId: orderId,
        partnerId: partnerId,
      };
      setShowTopMsg(headerMsgPayload);
      getIncomingOrders();
      getIncomingOrdersBackup();
      getOrderList();
      getJustCancelledOrderList();
      getAllNotificationsList();
      getOrderReportCount();
      break;
    }
    case 'incoming': {
      if (
        !isPresentInIncomingOrderNotificationsList(
          incomingOrderNotifications,
          orderId
        )
      ) {
        const updatedIncomingNotification = [
          ...incomingOrderNotifications,
          currentNotification,
        ];
        saveIncomingOrderNewNotification(updatedIncomingNotification);
        getIncomingOrders();
        getIncomingOrdersBackup();
        getOrderList();
        getAllNotificationsList();
        getJustCancelledOrderList();
        getOrderReportCount();
        const headerMsg = `Order has been received: #${partnerId}`;
        const typeName = 'Incoming';
        const headerMsgPayload = {
          show: true,
          type: typeName,
          msg: headerMsg,
          orderId: orderId,
          partnerId: partnerId,
        };
        log(`Notification Incoming Order: #${partnerId}`, headerMsgPayload, LogType.INFO);
        setShowTopMsg(headerMsgPayload);
      }
      break;
    }
    case 'cancelled': {
      if (
        isPresentInIncomingOrderNotificationsList(
          incomingOrderNotifications,
          currentNotification.id
        )
      ) {
        removeIncomingNotificationById(currentNotification.id);
      }
      if (
        !isPresentInCancelledOrderNotificationsList(
          cancelledOrderNotifications,
          orderId
        )
      ) {
        const headerMsg = `Order # ${partnerId}# has been cancelled`;
        const headerMsgPayload = {
          show: true,
          type: 'Cancelled',
          msg: headerMsg,
          orderId: orderId,
          partnerId: partnerId,
        };
        log(`Notification Cancelled Order: #${partnerId}`, headerMsgPayload, LogType.INFO);
        setShowTopMsg(headerMsgPayload);
        const updatedCancelledNotification = [
          ...cancelledOrderNotifications,
          currentNotification,
        ];
        saveCancelledOrderNewNotification(updatedCancelledNotification);
        getIncomingOrders();
        getIncomingOrdersBackup();
        getOrderList();
        getJustCancelledOrderList();
        getAllNotificationsList();
        getOrderReportCount();
      }
      break;
    }
    case 'assigningdriver': {
      assigningDriverNotification(
        partnerId,
        orderId,
        currentNotification,
        props
      );
      break;
    }
    case 'driverarrived': {
      driverArrivedNotification(partnerId, orderId, currentNotification, props);
      break;
    }
    case 'movedtoorderhistory': {
      movedToOrderHistoryNotification(orderId, props);
      break;
    }
    case 'completed': {
      completedNotification(
        currentHeaderMsgOrderId,
        currentNotification,
        props
      );
      break;
    }
    default: {
      defaultNotification(props);
      break;
    }
  }
};

const onComponentMount = (props) => {
  const {
    notificationMessage,
    accessToken,
    incomingOrderNotifications,
    cancelledOrderNotifications,
    currentHeaderMsgOrderId,
    currentPath,
    showTopMsg,

    setShowTopMsg,
    getIncomingOrders,
    getIncomingOrdersBackup,
    getOrderList,
    getJustCancelledOrderList,
    getAllNotificationsList,
    getOrderReportCount,
    saveIncomingOrderNewNotification,
    removeIncomingNotificationById,
    saveCancelledOrderNewNotification,
    notificationAcknowledgement,
    getStoreDetails,
  } = props;

  if (!notificationMessage || !accessToken?.length) {
    return;
  }

  const payload = notificationMessage?.msg;

  const type = payload.data.type;
  const partnerId = payload.data.partner_id;
  const orderId = payload.data.order_id;

  const currentBgcNotification = acknowledgeNotification(
    payload,
    partnerId,
    type,
    orderId,
    currentPath,
    notificationAcknowledgement
  );
  currentBgcNotification.notification = payload.notification;

  if (showTopMsg.type === 'store_unattended') {
    return;
  }

  switch (type) {
    case 'store_unattended': {
      setShowTopMsg({
        show: true,
        type: type,
        msg: 'Your store has been closed to customer due to the device unattended. Please click here to resume.',
        orderId: '',
        partnerId: '',
      });
      getStoreDetails();
      getAllNotificationsList();
      break;
    }
    case 'pendingaction': {
      const headerMsg = `Kindly review/accept Order : #${partnerId} in the Incoming Orders tab`;
      const typeName = 'PendingAction';
      const headerMsgPayload = {
        show: true,
        type: typeName,
        msg: headerMsg,
        orderId: orderId,
        partnerId: partnerId,
      };
      setShowTopMsg(headerMsgPayload);
      getIncomingOrders();
      getIncomingOrdersBackup();
      getOrderList();
      getJustCancelledOrderList();
      getAllNotificationsList();
      getOrderReportCount();
      if (!incomingTone.paused) {
        incomingTone.pause();
      }
      break;
    }
    case 'incoming': {
      if (
        !isPresentInIncomingOrderNotificationsList(
          incomingOrderNotifications,
          orderId
        )
      ) {
        const updatedIncomingNotification = [
          ...incomingOrderNotifications,
          currentBgcNotification,
        ];
        saveIncomingOrderNewNotification(updatedIncomingNotification);
        getIncomingOrders();
        getIncomingOrdersBackup();
        getOrderList();
        getAllNotificationsList();
        getOrderReportCount();
        const headerMsg = `Order has been received: #${partnerId}`;
        const typeName = 'Incoming';
        const headerMsgPayload = {
          show: true,
          type: typeName,
          msg: headerMsg,
          orderId: orderId,
          partnerId: partnerId,
        };
        setShowTopMsg(headerMsgPayload);
      }
      break;
    }
    case 'cancelled': {
      if (
        isPresentInIncomingOrderNotificationsList(
          incomingOrderNotifications,
          currentBgcNotification.id
        )
      ) {
        removeIncomingNotificationById(currentBgcNotification.id);
      }
      if (
        !isPresentInCancelledOrderNotificationsList(
          cancelledOrderNotifications,
          orderId
        )
      ) {
        const updatedCancelledNotification = [
          ...cancelledOrderNotifications,
          currentBgcNotification,
        ];
        saveCancelledOrderNewNotification(updatedCancelledNotification);
        getJustCancelledOrderList();
        getIncomingOrders();
        getIncomingOrdersBackup();
        getOrderList();
        getAllNotificationsList();
        getOrderReportCount();
        const headerMsg = `Order # ${partnerId}'#'} has been cancelled`;
        const headerMsgPayload = {
          show: true,
          type: 'Cancelled',
          msg: headerMsg,
          orderId: orderId,
          partnerId: partnerId,
        };
        setShowTopMsg(headerMsgPayload);
      }
      break;
    }
    case 'assigningdriver': {
      assigningDriverNotification(
        partnerId,
        orderId,
        currentBgcNotification,
        props
      );
      break;
    }
    case 'driverarrived': {
      driverArrivedNotification(
        partnerId,
        orderId,
        currentBgcNotification,
        props
      );
      break;
    }
    case 'movedtoorderhistory': {
      movedToOrderHistoryNotification(orderId, props);
      break;
    }
    case 'completed': {
      completedNotification(
        currentHeaderMsgOrderId,
        currentBgcNotification,
        props
      );
      break;
    }
    default: {
      defaultNotification(props);
    }
  }
};

function SVTNotification(props) {
  const {
    saveCancelledOrderNewNotification,
    saveIncomingOrderNewNotification,
    setShowTopMsg,
    getIncomingOrders,
    getJustCancelledOrderList,
    getAllNotificationsList,
    getOrderList,
    getOrderReportCount,
    accessToken,
    saveHeaderMsgOrderId,
    getIncomingOrdersBackup,
    notificationAcknowledgement,
    isOnline,
    getStoreDetails,
  } = props;
  const {
    incomingOrderNotifications,
    cancelledOrderNotifications,
    currentHeaderMsgOrderId,
    currentPath,
    showTopMsg,
  } = props?.orderManagementRelated;

  const messaging = getMessaging(firebase);

  const removeIncomingNotificationById = (id) => {
    let tmp = incomingOrderNotifications.slice();
    tmp = tmp.filter((notification) => notification.id !== id);
    saveIncomingOrderNewNotification(tmp);
  };
  const removeCancelledNotificationById = (id) => {
    let tmp = cancelledOrderNotifications.slice();
    tmp = tmp.filter((notification) => notification.id !== id);
    saveCancelledOrderNewNotification(tmp);
  };

  onMessage(messaging, async (payload) => {
    onFirebaseInit({
      accessToken,
      payload,
      incomingOrderNotifications,
      cancelledOrderNotifications,
      currentHeaderMsgOrderId,
      currentPath,
      showTopMsg,

      setShowTopMsg,
      getIncomingOrders,
      getIncomingOrdersBackup,
      getOrderList,
      getJustCancelledOrderList,
      getAllNotificationsList,
      getOrderReportCount,
      saveIncomingOrderNewNotification,
      removeIncomingNotificationById,
      saveCancelledOrderNewNotification,
      removeCancelledNotificationById,
      saveHeaderMsgOrderId,
      notificationAcknowledgement,
      getStoreDetails,
    });
  });

  if (
    incomingOrderNotifications !== undefined &&
    incomingOrderNotifications?.length !== 0
  ) {
    console.log("multiple incoming order notifications, playing incoming tone");
    pauseCancelledTone();
    incomingTone
      .play()
      .catch((err) => {
        log("Audio playback Error", `${audioPlayErrorMessage} => ${err?.message}`, LogType.ERROR);
        console.log(`${audioPlayErrorMessage} => ${err?.message}`)
      });
  } else {
    pauseIncomingTone();
    if (
      cancelledOrderNotifications !== undefined &&
      cancelledOrderNotifications?.length !== 0
    ) {
      cancelledTone
        .play()
        .catch((err) => {
          log("Audio playback cancelled Error", `${audioPlayErrorMessage} => ${err?.message}`, LogType.ERROR);
          console.log(`${audioPlayErrorMessage} => ${err?.message}`)
        });
    } else {
      pauseCancelledTone();
    }
  }

  const [notificationMessage, setNotificationMessage] = React.useState();

  React.useEffect(() => {
    onComponentMount({
      notificationMessage,
      accessToken,
      incomingOrderNotifications,
      cancelledOrderNotifications,
      currentHeaderMsgOrderId,
      currentPath,
      showTopMsg,

      setShowTopMsg,
      getIncomingOrders,
      getIncomingOrdersBackup,
      getOrderList,
      getJustCancelledOrderList,
      getAllNotificationsList,
      getOrderReportCount,
      saveIncomingOrderNewNotification,
      removeIncomingNotificationById,
      saveCancelledOrderNewNotification,
      saveHeaderMsgOrderId,
      removeCancelledNotificationById,
      notificationAcknowledgement,
      getStoreDetails,
    });
  }, [notificationMessage]);

  React.useEffect(() => {
    if (isOnline) {
      if (!!incomingOrderNotifications?.length) {
        incomingTone.play();
      } else if (!!cancelledOrderNotifications?.length) {
        cancelledTone.play();
      }
    } else {
      pauseIncomingTone();
      pauseCancelledTone();
    }
  }, [isOnline]);

  channel.addEventListener('message', (event) => {
    setNotificationMessage(event.data);
  });

  return <></>;
}

function mapState(state) {
  const { orderManagementRelated, authInfo } = state;
  const { accessToken } = authInfo;
  return {
    orderManagementRelated,
    accessToken,
  };
}

const actionCreators = {
  saveIncomingOrderNewNotification:
    orderManagementActions.saveIncomingOrderNewNotification,
  saveCancelledOrderNewNotification:
    orderManagementActions.saveCancelledOrderNewNotification,
  getJustCancelledOrderList: orderManagementActions.getJustCancelledOrderList,
  getIncomingOrders: orderManagementActions.getIncomingOrders,
  setShowTopMsg: orderManagementActions.setShowTopMsg,
  getAllNotificationsList: orderManagementActions.getAllNotificationsList,
  getOrderList: orderManagementActions.getOrderList,
  getOrderReportCount: orderManagementActions.getOrderReportCount,
  saveHeaderMsgOrderId: orderManagementActions.saveHeaderMsgOrderId,
  getIncomingOrdersBackup: orderManagementActions.getIncomingOrdersBackup,
  notificationAcknowledgement:
    orderManagementActions.notificationAcknowledgement,
  getStoreDetails: orderManagementActions.getStoreDetails,
};

export default compose(connect(mapState, actionCreators))(SVTNotification);
