/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import SVTHeaderComponent from './SVTHeaderComponent';
import SVTMenuHeader from './SVTMenuHeader';
import checklistGray from '../../images/ChecklistGray.svg';
import checklistGreen from '../../images/ChecklistGreen.svg';
import SVTProductItemsWrapper from './SVTProductItemsWrapper';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { menuManagementAction } from '../../action/SVTMenuManagementAction';
import SVTMenuSearchResults from './SVTMenuSearchResults';
import allProductActive from '../../images/all-products-active.svg';
import allProductInactive from '../../images/all-products-inactive.svg';
import SVTMainComponent from './SVTMainComponent';
import { orderManagementActions } from '../../action/SVTOrderManagementAction';
/**
 *
 * @returns {Array<object>}
 */

const markedAsUnavailable = 'marked-as-unavailable';
const categoryKey = ['single-tabletall-modifiers', 'single-tabletall-products'];
const imageSelection = (tabList, index, image1, image2, className) => {
  return tabList[index]?.active === true ? (
    <img
      src={image1}
      alt='checklistGray'
      width='36px'
      height='36px'
      className={className}
    />
  ) : (
    <img
      src={image2}
      alt='checklistGray'
      width='36px'
      height='36px'
      className={className}
    />
  );
};
const serchQueryCondition = (
  searchQuery,
  tabList,
  productRef,
  scrollEventListener,
  scrollTopPosition
) => {
  return searchQuery?.length > 2 ? (
    <section className='menu-body-right'>
      <SVTMenuSearchResults
        tabList={tabList}
        currentActiveTab={getCurrentActiveTabKey(tabList)}
        searchQuery={searchQuery}
      />
    </section>
  ) : (
    <section
      ref={productRef}
      className='menu-body-right'
      onScroll={scrollEventListener}
      data-test='scrollableProductView'
    >
      <SVTProductItemsWrapper
        scrollTopPosition={scrollTopPosition}
        currentActiveTab={getCurrentActiveTabKey(tabList)}
      />
    </section>
  );
};
export const getInitialStateForTab = () => {
  return [
    {
      id: markedAsUnavailable,
      itemIndex: 0,
      category_key: markedAsUnavailable,
      category_name: 'Marked as unavailable',
      active: true,
      product_names: [],
    },
    {
      id: 'recent',
      itemIndex: 1,
      product_names: [],
      active: false,
      category_key: categoryKey[1],
      category_name: 'All Product',
    },
    {
      itemIndex: 2,
      id: 'recent',
      active: false,
      product_names: [],
      category_key: categoryKey[0],
      category_name: 'All Modifiers',
    },
    {
      itemIndex: 3,
      active: false,
      category_key: 'search',
      category_name: 'search',
      id: 'search',
      product_names: [],
    },
  ];
};
/**
 *
 * @param {Array<object>} tabList
 * @returns {string} currentActiveTab
 */
export const getCurrentActiveTabKey = (tabList = []) => {
  const currentActiveTab = tabList.filter((tab) => tab.active)[0];
  return currentActiveTab.category_key;
};
/**
 *
 * @param {Array<object>} tabList
 * @returns {string} currentActiveTabIndex
 */
export const getCurrentActiveTabIndex = (tabList = []) => {
  return tabList.findIndex((tab) => tab.active);
};

const setActiveTab = (
  index,
  getCurrentActiveTabKey,
  tabList,
  storeSearchQuery,
  getInitialStateForTab,
  productRef,
  setTabList
) => {
  if (getCurrentActiveTabKey(tabList) === 'search') {
    storeSearchQuery('');
  }
  const tempItemList = getInitialStateForTab();
  productRef.current?.scrollTo(0, 0);
  if (index !== 0) {
    tempItemList[0]['active'] = false;
    tempItemList[index]['active'] = true;
  }
  setTabList(tempItemList);
};

const onProductStatusChange = (props) => {
  const {
    productStatus,
    currentActiveModifier,
    tabList,
    getTotalUnavailableItemList,
    getTotalUnavailableCount,
    getAllProductsList,
    getAllModifiersList,
    getAllProductsAndModifiersListFromSearchQuery,
    searchQuery,
    setProductStatusToNull,
    setCurrentActiveModifier,
  } = props;
  if (!productStatus && currentActiveModifier?.modifierName === '') {
    return undefined;
  }
  if (getCurrentActiveTabKey(tabList) === markedAsUnavailable) {
    getTotalUnavailableItemList();
    getTotalUnavailableCount();
  } else if (getCurrentActiveTabKey(tabList) === categoryKey[1]) {
    getAllProductsList();
    getTotalUnavailableCount();
  } else if (getCurrentActiveTabKey(tabList) === categoryKey[0]) {
    const { shouldShow = false } = currentActiveModifier;
    // for stopping multiple api call for same toggle action on modifier
    if (shouldShow) {
      getAllModifiersList();
      getTotalUnavailableCount();
    }
  } else {
    if (getCurrentActiveTabKey(tabList) === 'search') {
      getAllProductsAndModifiersListFromSearchQuery(searchQuery);
      getTotalUnavailableCount();
    }
  }
  return () => {
    setProductStatusToNull();
    setCurrentActiveModifier();
  };
};

const onSearchQueryUpdate = (props) => {
  const {
    searchQuery,
    tabList,
    previousActiveTabIndex,
    getTotalUnavailableItemList,
    getAllProductsList,
    getAllModifiersList,
    setTabList,
    productRef,
    storeSearchQuery,
  } = props;
  let currentActiveTabKey;
  if (searchQuery.length === 0) {
    currentActiveTabKey = getCurrentActiveTabKey(tabList);
    if (currentActiveTabKey === 'search') {
      setActiveTab(
        previousActiveTabIndex.current,
        getCurrentActiveTabKey,
        tabList,
        storeSearchQuery,
        getInitialStateForTab,
        productRef,
        setTabList
      );
      currentActiveTabKey =
        tabList[previousActiveTabIndex.current]?.category_key;
    }
    switch (currentActiveTabKey) {
      case markedAsUnavailable:
        getTotalUnavailableItemList();
        break;
      case categoryKey[1]:
        getAllProductsList();
        break;
      case categoryKey[0]:
        getAllModifiersList();
        break;
      default:
        break;
    }
  } else if (searchQuery.length > 2) {
    currentActiveTabKey = getCurrentActiveTabKey(tabList);
    if (currentActiveTabKey !== 'search') {
      const currentActiveTabIndex = getCurrentActiveTabIndex(tabList);
      previousActiveTabIndex.current = currentActiveTabIndex;
      setActiveTab(
        3,
        getCurrentActiveTabKey,
        tabList,
        storeSearchQuery,
        getInitialStateForTab,
        productRef,
        setTabList
      );
    }
  }
};

const currentActiveTabHandler = (props) => {
  const {
    currentActiveProduct,
    setShowTopMsg,
    setCurrentActiveProduct,
    currentActiveModifier,
    setCurrentActiveModifier,
  } = props;
  if (currentActiveProduct?.shouldShow) {
    const headerMsg = `${currentActiveProduct?.productName}`;
    const headerMsgPayload = {
      show: true,
      type: 'menu',
      msg: headerMsg,
      orderId: '',
      partnerId: '',
      isProductAvailable: currentActiveProduct?.isProductAvailable,
    };
    setShowTopMsg(headerMsgPayload);
    const setCurrentActiveProductPayload = {
      productName: '',
      isProductAvailable: false,
      shouldShow: false,
    };
    setCurrentActiveProduct(setCurrentActiveProductPayload);
  }
  if (currentActiveModifier?.shouldShow) {
    const {
      modifierName,
      numberOfProductsHavingThisModifier,
      isModifierAvailable,
    } = currentActiveModifier;
    const headerMsg = String(modifierName);
    const headerMsgPayload = {
      show: true,
      type: 'menu',
      msg: headerMsg,
      isProductAvailable: isModifierAvailable,
      isModifier: true,
      numberOfProductsHavingThisModifier,
    };
    setShowTopMsg(headerMsgPayload);
    setCurrentActiveModifier();
  }
};

const getLeftCardClass = (tabList, index) => {
  if (index === 0) {
    if (tabList[index]?.active === true) {
      return 'left-card-item left-card-item-active mark-as-unavailable-tab cursor-pointer';
    } else {
      return 'left-card-item mark-as-unavailable-tab cursor-pointer left-card-bottom-border';
    }
  } else {
    if (tabList[index]?.active === true) {
      return 'left-card-item left-card-items-body left-card-item-active cursor-pointer';
    } else {
      return 'left-card-item left-card-items-body cursor-pointer left-card-bottom-border';
    }
  }
};

function SVTMenuPage(props) {
  const {
    getTotalUnavailableCount,
    getTotalUnavailableItemList,
    productStatus,
    totalUnavailableCount,
    searchQuery,
    totalProductsCount,
    getAllProductsAndModifiersListFromSearchQuery,
    storeSearchQuery,
    setShowTopMsg,
    currentActiveProduct,
    setCurrentActiveProduct,
    getAllModifiersList,
    getAllProductsList,
    allModifiersList,
    allUnavailableProductsList,
    allProductsList,
    currentActiveModifier,
    setCurrentActiveModifier,
    setProductStatusToNull,
    allProductsListFromSearchQuery,
    allModifiersListFromSearchQuery,
  } = props;
  const [tabList, setTabList] = React.useState(() => getInitialStateForTab());
  const [scrollTopPosition, setScrollPosition] = React.useState(0);
  const productRef = React.useRef();
  React.useEffect(() => {
    props.setCurrentPath(props.location.pathname);
    storeSearchQuery('');
  }, []);
  /**
   * This effect will run when any left tab is getting changed
   */
  React.useEffect(() => {
    const currentActiveTabKey = getCurrentActiveTabKey(tabList);
    getTotalUnavailableCount();
    switch (currentActiveTabKey) {
      case markedAsUnavailable:
        getTotalUnavailableItemList();
        break;
      case categoryKey[1]:
        getAllProductsList();
        break;
      case categoryKey[0]:
        getAllModifiersList();
        break;
      default:
        break;
    }
  }, [tabList]);
  /**
   * This effect will update the product list when any user try to make it available or unavailable
   */
  React.useEffect(() => {
    onProductStatusChange({
      productStatus,
      currentActiveModifier,
      tabList,
      getTotalUnavailableItemList,
      getTotalUnavailableCount,
      getAllProductsList,
      getAllModifiersList,
      getAllProductsAndModifiersListFromSearchQuery,
      searchQuery,
      setProductStatusToNull,
      setCurrentActiveModifier,
    });
  }, [productStatus, currentActiveModifier]);

  const scrollEventListener = (e) => {
    setScrollPosition(e.target.scrollTop);
  };
  const previousActiveTabIndex = React.useRef(0);
  React.useEffect(() => {
    onSearchQueryUpdate({
      searchQuery,
      tabList,
      previousActiveTabIndex,
      getTotalUnavailableItemList,
      getAllProductsList,
      getAllModifiersList,
      setTabList,
      productRef,
      storeSearchQuery,
    });
  }, [searchQuery]);

  React.useEffect(() => {
    currentActiveTabHandler({
      currentActiveProduct,
      setShowTopMsg,
      setCurrentActiveProduct,
      currentActiveModifier,
      setCurrentActiveModifier,
    });
  }, [
    allProductsList,
    allUnavailableProductsList,
    allModifiersList,
    allProductsListFromSearchQuery,
    allModifiersListFromSearchQuery,
  ]);
  if (props?.showErrorPage) {
    return <SVTMainComponent />;
  }
  return (
    <>
      <SVTHeaderComponent />
      <div className='menu-main-container' data-test='menuPageContainer'>
        <SVTMenuHeader currentActiveTab={getCurrentActiveTabKey(tabList)} />
        <main className='menu-body-container'>
          <section className='menu-body-left'>
            <div
              className={getLeftCardClass(tabList, 0)}
              onClick={() =>
                setActiveTab(
                  0,
                  getCurrentActiveTabKey,
                  tabList,
                  storeSearchQuery,
                  getInitialStateForTab,
                  productRef,
                  setTabList
                )
              }
              data-test='markAsUnavailableTab'
            >
              <div
                className={
                  tabList[0]?.active === true ? 'tab-custom-border' : ''
                }
              ></div>
              <div>
                <div className='w-100 d-flex flex-row-reverse menu-count-height'>
                  {totalUnavailableCount > 0 && (
                    <div
                      className={
                        tabList[0]?.active === true
                          ? 'mark-as-unavailable-tab-count ms-2 unavailable-count-active'
                          : 'mark-as-unavailable-tab-count ms-2 unavailable-count-inactive '
                      }
                    >
                      {totalUnavailableCount}
                    </div>
                  )}
                </div>
                <div className='d-flex flex-column justify-content-center align-items-center menu-left-tab-small '>
                  <div>
                    {imageSelection(
                      tabList,
                      0,
                      checklistGreen,
                      checklistGray,
                      'mark-as-unavailable-logo'
                    )}
                  </div>
                  <div className='left-card-text d-flex flex-row make-flex-col-on-overflow-for-mark-as-unavailable-tab'>
                    <div className='word-break'>Marked as&nbsp;</div>
                    <div className='word-break'>Unavailable</div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={getLeftCardClass(tabList, 1)}
              onClick={() =>
                setActiveTab(
                  1,
                  getCurrentActiveTabKey,
                  tabList,
                  storeSearchQuery,
                  getInitialStateForTab,
                  productRef,
                  setTabList
                )
              }
              data-test='allProductTab'
            >
              <div
                className={
                  tabList[1]?.active === true ? 'tab-custom-border' : ''
                }
              ></div>
              <div className='d-flex flex-column justify-content-center align-items-center  flex-gap-15 menu-left-tab-small'>
                {imageSelection(
                  tabList,
                  1,
                  allProductActive,
                  allProductInactive,
                  'all-product-logo'
                )}
                <div className='left-card-text d-flex flex-row justify-content-center align-items-center make-flex-col-on-overflow-for-all-product-tab'>
                  <div>All&nbsp;</div>
                  <div>Products&nbsp;</div>
                  <div>({totalProductsCount})</div>
                </div>
              </div>
            </div>
            {/* ! Please do not remove this section. It is All Modifier */}
            {/* <div
							className={getLeftCardClass(tabList, 2)}
							onClick={() => setActiveTab(2)}
							data-test='allModifiersTab'
						>
							<div
								className={
									tabList[2]?.active === true
										? 'tab-custom-border'
										: ''
								}
							></div>
							<div className='d-flex flex-column justify-content-center align-items-center  flex-gap-15 menu-left-tab-small'>
								{tabList[2]?.active === true ? (
									<img
										src={allModifiersActive}
										alt='checklistGray'
										width='36px'
										height='36px'
										className='all-product-logo'
									/>
								) : (
									<img
										src={allModifiersInactive}
										alt='checklistGray'
										width='36px'
										height='36px'
										className='all-product-logo'
									/>
								)}
								<div className='left-card-text d-flex flex-row justify-content-center align-items-center make-flex-col-on-overflow-for-all-product-tab'>
									<div>All&nbsp;</div>
									<div>Modifiers&nbsp;</div>
									<div>({totalModifiersCount})</div>
								</div>
							</div>
						</div> */}
          </section>
          {serchQueryCondition(
            searchQuery,
            tabList,
            productRef,
            scrollEventListener,
            scrollTopPosition
          )}
        </main>
      </div>
    </>
  );
}

export function mapState(state) {
  const { menuManagementRelated, errorPageRelated } = state;
  const { showErrorPage } = errorPageRelated;
  const {
    searchQuery,
    totalUnavailableCount,
    productList,
    productStatus,
    totalProductsCount,
    currentActiveProduct,
    allModifiersList,
    allUnavailableProductsList,
    allProductsList,
    currentActiveModifier,
    totalModifiersCount,
    allProductsListFromSearchQuery,
    allModifiersListFromSearchQuery,
  } = menuManagementRelated;
  return {
    searchQuery,
    totalUnavailableCount,
    productList,
    productStatus,
    totalProductsCount,
    showErrorPage,
    currentActiveProduct,
    allModifiersList,
    allUnavailableProductsList,
    allProductsList,
    currentActiveModifier,
    totalModifiersCount,
    allProductsListFromSearchQuery,
    allModifiersListFromSearchQuery,
  };
}
const actionCreators = {
  getTotalUnavailableItemList: menuManagementAction.getTotalUnavailableItemList,
  getTotalUnavailableCount: menuManagementAction.getTotalUnavailableCount,
  getAllProductsAndModifiersListFromSearchQuery:
    menuManagementAction.getAllProductsAndModifiersListFromSearchQuery,
  storeSearchQuery: menuManagementAction.storeSearchQuery,
  setShowTopMsg: orderManagementActions.setShowTopMsg,
  setCurrentActiveProduct: menuManagementAction.setCurrentActiveProduct,
  getAllModifiersList: menuManagementAction.getAllModifiersList,
  getAllProductsList: menuManagementAction.getAllProductsList,
  setCurrentActiveModifier: menuManagementAction.setCurrentActiveModifier,
  setProductStatusToNull: menuManagementAction.setProductStatusToNull,
  setCurrentPath: orderManagementActions.setCurrentPath,
};

export default compose(
  withRouter,
  connect(mapState, actionCreators)
)(SVTMenuPage);
