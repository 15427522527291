/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Row, Col, Modal } from 'react-bootstrap';
import SVTIncomingOrderItemsComponent from '../aggregators/SVTIncomingOrderItemsComponent';
import STVOrderHelpDropdown from './SVTOrderHelpDropdown';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { orderManagementActions } from '../../action/SVTOrderManagementAction';
import SVTPrintReceipt from './SVTPrintReceipt';
import SVTEditIncomingOrder from './SVTEditIncomingOrder';
import closeButtonGreen from '../../images/closeButtonGreen.svg';
import cross_icon from '../../images/cross_icon.svg';
import OrderCountDown from '../../services/OrderCountDown';

import {
  getCancelledItems,
  dismissOrder,
  showOrderDetails,
  orderAccepted,
  handlePartiallyOrderAccepted,
  handleCurrentFoodItemChange,
  handleCurrentFoodItemModifierChange,
  calculateTotalForState,
  handleGetCurrentOrder,
  handleSetCurrentItemQuantity,
  handleSetCurrentFoodItemModifierQuantity,
  handleSetCurrentFoodItemOutOfStock,
  handleCurrentFoodItemChange2,
  handleResetOrderItem,
  checkIfInitialState,
  formatDateToDateMonth,
} from './SVTIncomingOrderDetailsComponentHelpers';

const CustomerDetails = (props) => {
  const { order, phone } = props;

  return (
    <Row className='mx-0 cust_info align-items-center'>
      <Col lg={12} className='cust_info-for-larger-screen pt-14 pb-12'>
        <Row className='align-items-center'>
          <Col md={4} className='medium_text word-break'>
            {order.customer_name}
          </Col>
          <Col md={8}>
            <div className='d-flex'>
              <img src={phone} alt='phone_icon' />
              <div className='phone_text_style'>{order.customer_phone}</div>
            </div>
          </Col>
        </Row>
      </Col>
      {order.customer_phone_code && (
        <Col
          lg={12}
          className='code-info-for-larger-screen code-info-padding-for-smaller-screen p-0'
        >
          <div className='mx-0 d-flex justify-content-center code-info-flex-dir-for-larger-screen '>
            <div className='d-flex'>
              <div className='customer-phone-code-text'>Code: &nbsp;</div>
              <div className='customer-phone-code-number word-break'>
                {order.customer_phone_code.replaceAll(' ', '')}
              </div>
            </div>
          </div>
        </Col>
      )}
    </Row>
  );
};

const OrderDetailsBody = (componentProps) => {
  const {
    props,
    order,
    isUbereats,
    totalNoOfItems,
    currentFoodItem,
    setCurrentItemQuantity,
    setCurrentFoodItemModifierQuantity,
    currentFoodItemModifier,
    setCurrentFoodItemOutOfStock,
    resetById,
    resetOrderItem,
    totalPrice,
  } = componentProps;

  return (
    <div className='Accordion_Body'>
      <div>
        {props.shouldShowEditOrder &&
        props?.shouldShowEditOrder[props.id] !== true ? (
          <>
            <Row className='list_header mx-0'>
              <Col md={8} className='item-qty-price-text px-0'>
                ITEM NAME
              </Col>
              <Col md={3} className='item-qty-price-text px-0'>
                QTY
              </Col>
              <Col
                md={1}
                className='item-qty-price-text px-0 d-flex flex-row-reverse'
              >
                <span>PRICE</span>
              </Col>
            </Row>
            {order.order_items?.map((orderItem, index) => {
              return (
                <SVTIncomingOrderItemsComponent
                  key={
                    (isUbereats && `incomingorderitemscomponent_${index}'`) ||
                    order.partner_order_id
                  }
                  order={orderItem}
                  partnerName={order.partner_name}
                  uniqueKey={
                    (!isUbereats && order.partner_order_id) || undefined
                  }
                />
              );
            })}
            <Row className='list_footer mx-0'>
              <Col
                md={8}
                className='live-order-menu-item-footer-text px-0 text-center'
              >
                Total
              </Col>
              <Col md={3} className='live-order-menu-item-footer-text px-0'>
                x{(isUbereats && totalNoOfItems) || order?.count?.total_items}
              </Col>
              <Col
                md={1}
                className='px-0 live-order-menu-item-footer-text d-flex flex-row-reverse'
                style={{ paddingRight: '3px' }}
              >
                <span>£{(order?.total_price / 100).toFixed(2)}</span>
              </Col>
            </Row>
          </>
        ) : (
          <>
            {/* Body for edited orders */}
            <Row className='list_header mx-0'>
              <Col
                md={1}
                className='item-qty-price-text px-0 price-col-for-larger-screen'
              >
                AVL
              </Col>
              <Col md={7} className='item-qty-price-text px-0'>
                ITEM NAME
              </Col>
              <Col md={3} className='item-qty-price-text px-0'>
                QTY
              </Col>
              <Col
                md={1}
                className='item-qty-price-text px-0 d-flex flex-row-reverse price-col-for-larger-screen'
              >
                <span>PRICE</span>
              </Col>
              <Col
                md={2}
                className='item-qty-price-text px-0 d-flex flex-row-reverse price-col-for-smaller-screen'
              >
                <span>PRICE</span>
              </Col>
            </Row>
            <SVTEditIncomingOrder
              orderDetails={order}
              getCancelledItems={getCancelledItems}
              foodQuantity={props.foodQuantity}
              currentFoodItem={currentFoodItem}
              setCurrentItemQuantity={setCurrentItemQuantity}
              setCurrentFoodItemModifierQuantity={
                setCurrentFoodItemModifierQuantity
              }
              currentFoodItemModifier={currentFoodItemModifier}
              setCurrentFoodItemOutOfStock={setCurrentFoodItemOutOfStock}
              resetById={resetById}
              resetOrderItem={resetOrderItem}
            />
            <Row className='list_footer mx-0'>
              <Col
                md={8}
                className='live-order-menu-item-footer-text px-0 text-center'
              >
                Total
              </Col>
              <Col md={3} className='live-order-menu-item-footer-text px-0'>
                x{totalNoOfItems}
              </Col>
              <Col
                md={1}
                className='px-0 live-order-menu-item-footer-text d-flex flex-row-reverse'
                style={{ paddingRight: '0px' }}
              >
                <span>£{totalPrice.toFixed(2)}</span>
              </Col>
            </Row>
          </>
        )}
      </div>
    </div>
  );
};

const OrderDetails = (componentProps) => {
  const {
    props,
    order,
    phone,
    isUbereats,
    totalNoOfItems,
    currentFoodItem,
    setCurrentItemQuantity,
    setCurrentFoodItemModifierQuantity,
    currentFoodItemModifier,
    setCurrentFoodItemOutOfStock,
    resetById,
    resetOrderItem,
    totalPrice,
    closePartialAcceptanceView,
    showButtonColor,
    resetFullList,
    showPartialAcceptanceBtn,
    setPAcceptancePopup,
    disableAcceptBtn,
    setAcceptButtonState,
  } = componentProps;

  return (
    <>
      <CustomerDetails order={order} phone={phone} />

      <OrderDetailsBody
        props={props}
        order={order}
        isUbereats={isUbereats}
        totalNoOfItems={totalNoOfItems}
        currentFoodItem={currentFoodItem}
        setCurrentItemQuantity={setCurrentItemQuantity}
        setCurrentFoodItemModifierQuantity={setCurrentFoodItemModifierQuantity}
        currentFoodItemModifier={currentFoodItemModifier}
        setCurrentFoodItemOutOfStock={setCurrentFoodItemOutOfStock}
        resetById={resetById}
        resetOrderItem={resetOrderItem}
        totalPrice={totalPrice}
      />

      <div className='Accordion_Footer Accordion_Footer_Lower_Screen'>
        {props.shouldShowEditOrder &&
        props?.shouldShowEditOrder[props.id] === true ? (
          <>
            {/* Footer fo edited orders */}
            <img
              src={closeButtonGreen}
              alt='close_button'
              type='button'
              className='header-svg-border-radius button-live-order-height'
              onClick={() => closePartialAcceptanceView(props.id)}
            ></img>
            <div className='edit-order-reset-button-alignment'>
              {showButtonColor === false && (
                <button
                  type='button'
                  className='success-border-btn reset-button-spacing button-live-order-height'
                  onClick={() => resetFullList()}
                >
                  Reset
                </button>
              )}
              {(showPartialAcceptanceBtn && (
                <button
                  type='button'
                  className={
                    (showButtonColor && 'partially-accept-btn ms-auto') ||
                    'partially-accept-btn ms-auto darkgreen-background-color'
                  }
                  onClick={() => setPAcceptancePopup(true)}
                  disabled={showButtonColor}
                >
                  P. Accept
                </button>
              )) || (
                <button
                  type='button'
                  className='partially-accept-btn ms-auto darkgreen-background-color'
                  onClick={() => setPAcceptancePopup(true)}
                >
                  Cancel Order
                </button>
              )}
            </div>
          </>
        ) : (
          <>
            <STVOrderHelpDropdown
              btnClass={'success-border-btn me-2'}
              order={order}
              type={'IncomingOrders'}
              setShouldShowEditOrder={props.setShouldShowEditOrder}
              id={props.id}
              partnerName={order.partner_name}
              orderType={'Incoming'}
              shouldShowCancelBtn={true}
              shouldShowEditBtn={true}
            />
            <button
              type='button'
              className={
                (disableAcceptBtn && 'partially-accept-btn') || 'success-btn'
              }
              onClick={() => orderAccepted(props, order, setAcceptButtonState)}
              disabled={disableAcceptBtn}
            >
              Accept
            </button>
          </>
        )}
      </div>
    </>
  );
};

const IncomingOrderDetails = (componentProps) => {
  const {
    props,
    rotation,
    rotateArrow,
    show,
    image,
    order,
    status,
    up_arrow,
    phone,
    totalNoOfItems,
    currentFoodItem,
    setCurrentItemQuantity,
    setCurrentFoodItemModifierQuantity,
    currentFoodItemModifier,
    setCurrentFoodItemOutOfStock,
    resetById,
    resetOrderItem,
    totalPrice,
    closePartialAcceptanceView,
    resetFullList,
    showButtonColor,
    showPartialAcceptanceBtn,
    setPAcceptancePopup,
    disableAcceptBtn,
    setAcceptButtonState,
  } = componentProps;

  const isUbereats = order.partner_name === 'Uber Eats';

  return (
    <>
      <div className='Accordion_Header cursor-pointer' onClick={rotateArrow}>
        <div>
          <img src={image} alt={`${isUbereats ? 'uber' : 'deliveroo'}_logo`} />
        </div>
        <div className='ms-12'>
          <div className='id_style'>#{order.partner_id?.toUpperCase()}</div>
          <div className='small_text gray_text mb-2'>
            {status === 'Partially Accepted' && (
              <span>Partially-Accepted at </span>
            )}
            <span>
              {formatDateToDateMonth(order?.created_on)}&nbsp;{order.time}
              &nbsp;
              <OrderCountDown
                date={order?.created_on}
                acceptanceTime={order?.acceptance_time}
                partnerName={order?.partner_name}
              />
            </span>
          </div>
          <div>
            <div className='items_count_style'>
              {order.count?.total_items} items
            </div>
          </div>
        </div>
        <div className='ms-auto'>
          <img
            src={up_arrow}
            style={{ transform: `rotate(${rotation}deg)` }}
            alt='up_arrow'
            className='cursor-pointer'
            onClick={rotateArrow}
          />
        </div>
      </div>
      {show && (
        <OrderDetails
          props={props}
          order={order}
          phone={phone}
          isUbereats={isUbereats}
          totalNoOfItems={totalNoOfItems}
          currentFoodItem={currentFoodItem}
          setCurrentItemQuantity={setCurrentItemQuantity}
          setCurrentFoodItemModifierQuantity={
            setCurrentFoodItemModifierQuantity
          }
          currentFoodItemModifier={currentFoodItemModifier}
          setCurrentFoodItemOutOfStock={setCurrentFoodItemOutOfStock}
          resetById={resetById}
          resetOrderItem={resetOrderItem}
          totalPrice={totalPrice}
          closePartialAcceptanceView={closePartialAcceptanceView}
          showButtonColor={showButtonColor}
          resetFullList={resetFullList}
          showPartialAcceptanceBtn={showPartialAcceptanceBtn}
          setPAcceptancePopup={setPAcceptancePopup}
          disableAcceptBtn={disableAcceptBtn}
          setAcceptButtonState={setAcceptButtonState}
        />
      )}
      {status === 'Partially Accepted' && (
        <div
          className={
            'Accordion_Footer justify-content-end ' + (!show && 'pt-0')
          }
        >
          <button
            type='button'
            className='success-border-btn me-3'
            onClick={() => dismissOrder(props, order)}
          >
            Dismiss
          </button>
          <button
            type='button'
            className='success-btn'
            onClick={() => showOrderDetails(props, order)}
          >
            View
          </button>
        </div>
      )}
    </>
  );
};

function SVTIncomingOrderDetailsComponent(props) {
  const {
    image,
    up_arrow,
    status,
    rotation,
    rotateArrow,
    show,
    phone,
    order,
    resetById,
  } = props;
  let totalCost = 0;
  let totalItems = 0;
  const [showPartialAcceptanceBtn, setPartialAcceptanceBtn] = useState(true);
  const [showButtonColor, setButtonColor] = useState(true);
  const [showPAcceptancePopup, setPAcceptancePopup] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalNoOfItems, setTotalNoOfItems] = useState(0);
  const [disableAcceptBtn, setAcceptButtonState] = useState(false);

  const calculateTotal = () => {
    totalItems = 0;
    totalCost = 0;
    order.order_items.forEach((data) => {
      totalItems += Number(data?.quantity ? data?.quantity : 1);
      totalCost +=
        parseFloat(
          data?.unit_price
            ? data?.unit_price / 100
            : data?.price / data?.quantity / 100
        ) * (data?.quantity ? data?.quantity : 1);
    });
    return [totalCost, totalItems];
  };

  calculateTotal();

  const partiallyOrderAccepted = async (order) => {
    handlePartiallyOrderAccepted(
      props,
      order,
      closePartialAcceptanceView,
      setPAcceptancePopup,
      showPartialAcceptanceBtn
    );
  };

  const closePartialAcceptanceView = (id) => {
    props.setShouldShowEditOrder((prev) => ({ ...prev, [id]: false }));
    setButtonColor((prev) => ({ ...prev, [id]: false }));
    props.reset(id);
    props.getIncomingOrders();
    props.getIncomingOrdersBackup();
  };

  const [currentFoodItem, setCurrentFoodItem] = React.useState({});
  const [currentFoodItemModifier, setCurrentFoodItemModifier] =
    React.useState();

  React.useEffect(() => {
    handleCurrentFoodItemChange(props, setCurrentFoodItem);
    handleCurrentFoodItemModifierChange(props, setCurrentFoodItemModifier);
  }, [props.foodQuantity]);

  const getCurrentOrder = (incomingOrders) => {
    return handleGetCurrentOrder(incomingOrders, order);
  };

  const setCurrentItemQuantity = (id, type, index) => {
    return handleSetCurrentItemQuantity(
      props,
      id,
      type,
      index,
      currentFoodItem,
      {
        setCurrentFoodItemOutOfStock,
        setCurrentFoodItem,
        setTotalPrice,
        setTotalNoOfItems,
        setButtonColor,
        isInInitialState,
        getCurrentOrder,
      }
    );
  };

  const setCurrentFoodItemModifierQuantity = (id) => {
    handleSetCurrentFoodItemModifierQuantity(
      id,
      currentFoodItemModifier,
      setCurrentFoodItemModifier
    );
  };

  const setCurrentFoodItemOutOfStock = (id) => {
    handleSetCurrentFoodItemOutOfStock(id, props, currentFoodItem, {
      setCurrentFoodItem,
      isInInitialState,
      setButtonColor,
      setTotalPrice,
      setTotalNoOfItems,
    });
  };

  React.useEffect(() => {
    handleCurrentFoodItemChange2(currentFoodItem, setPartialAcceptanceBtn);
  }, [currentFoodItem]);

  const resetOrderItem = (currentOrderList, id) => {
    return handleResetOrderItem(currentOrderList, id, props, {
      getCurrentOrder,
      setCurrentFoodItem,
      isInInitialState,
      setButtonColor,
      setTotalPrice,
      setTotalNoOfItems,
    });
  };

  React.useEffect(() => {
    const t1 = calculateTotal();
    setTotalPrice(t1[0]);
    setTotalNoOfItems(t1[1]);
  }, [order]);

  const resetFullList = () => {
    const res = props.reset(props.id);
    props.storePartiallyAcceptedItem({ ...res });
    const t1 = calculateTotalForState(res, '', 'none');
    setTotalPrice(t1[0]);
    setTotalNoOfItems(t1[1]);
    setButtonColor(true);
  };

  const isInInitialState = (currentList) => {
    return checkIfInitialState(props, currentList, getCurrentOrder);
  };

  return (
    <>
      <PartialAcceptancePopup
        show={showPAcceptancePopup}
        onHide={setPAcceptancePopup}
        showPartialAcceptanceBtn={showPartialAcceptanceBtn}
        {...props?.order}
        partiallyOrderAccepted={partiallyOrderAccepted}
      />

      <IncomingOrderDetails
        props={props}
        rotation={rotation}
        rotateArrow={rotateArrow}
        show={show}
        image={image}
        order={order}
        status={status}
        up_arrow={up_arrow}
        phone={phone}
        totalNoOfItems={totalNoOfItems}
        currentFoodItem={currentFoodItem}
        setCurrentItemQuantity={setCurrentItemQuantity}
        setCurrentFoodItemModifierQuantity={setCurrentFoodItemModifierQuantity}
        currentFoodItemModifier={currentFoodItemModifier}
        setCurrentFoodItemOutOfStock={setCurrentFoodItemOutOfStock}
        resetById={resetById}
        resetOrderItem={resetOrderItem}
        totalPrice={totalPrice}
        closePartialAcceptanceView={closePartialAcceptanceView}
        resetFullList={resetFullList}
        showButtonColor={showButtonColor}
        showPartialAcceptanceBtn={showPartialAcceptanceBtn}
        setPAcceptancePopup={setPAcceptancePopup}
        disableAcceptBtn={disableAcceptBtn}
        setAcceptButtonState={setAcceptButtonState}
      />

      <SVTPrintReceipt details={order} show={false} />
    </>
  );
}

function PartialAcceptancePopup(props) {
  const { show, onHide } = props;
  return (
    <Modal
      show={show}
      onHide={onHide}
      size='md'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <Modal.Title id='contained-modal-title-vcenter'>
          <div className='d-flex'>
            <p className='m-0 p-0 normal-font fw-bold'>
              {props.showPartialAcceptanceBtn
                ? 'Confirm Changes'
                : 'Cancel Order'}
            </p>
          </div>
        </Modal.Title>
        <img
          src={cross_icon}
          height='21.98px'
          width='21.98px'
          onClick={() => onHide(false)}
          alt='cross icon'
        />
      </Modal.Header>
      <Modal.Body className='p-0 m-0 ps-3 pe-3'>
        <Row>
          <Col sm={12} className='m-0 pb-3'>
            <hr className='m-0 contact-supp-hr' />
          </Col>
          <Col md={12}>
            {!props.showPartialAcceptanceBtn && (
              <span>
                {' '}
                Are you sure you want to cancel order{' '}
                <strong>#{props?.partner_id?.toUpperCase()} </strong>?
              </span>
            )}
            {props.showPartialAcceptanceBtn && (
              <span>
                {' '}
                Are you sure you want to save the changes made to order{' '}
                <strong>#{props?.partner_id?.toUpperCase()} </strong>?
              </span>
            )}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <button
          type='button'
          className='green-outline-btn shadow-none'
          onClick={() => onHide(false)}
        >
          Cancel
        </button>
        <button
          type='button'
          className={' shadow-none ms-3 success-btn-modal'}
          onClick={() => props.partiallyOrderAccepted(props)}
        >
          {props.showPartialAcceptanceBtn ? 'P. Accept' : 'Cancel Order'}
        </button>
      </Modal.Footer>
    </Modal>
  );
}

function mapState(state) {
  const { orderManagementRelated } = state;
  const {
    incomingOrderNotifications,
    storePartiallyAcceptedItems,
    incomingOrders,
    backupIncomingOrder,
  } = orderManagementRelated;
  return {
    incomingOrderNotifications,
    storePartiallyAcceptedItems,
    incomingOrders,
    backupIncomingOrder,
  };
}
const actionCreators = {
  dismissLiveOrders: orderManagementActions.dismissLiveOrders,
  getIncomingOrders: orderManagementActions.getIncomingOrders,
  storeOrderDetails: orderManagementActions.storeOrderDetails,
  updateOrderStatus: orderManagementActions.updateOrderStatus,
  saveIncomingOrderNewNotification:
    orderManagementActions.saveIncomingOrderNewNotification,
  setAutoPrintOption: orderManagementActions.setAutoPrintOption,
  getOrderReportCount: orderManagementActions.getOrderReportCount,
  showEditedOrders: orderManagementActions.showEditedOrders,
  updateEditedOrders: orderManagementActions.updateEditedOrders,
  setShowTopMsg: orderManagementActions.setShowTopMsg,
  getSingleItemDetails: orderManagementActions.getSingleItemDetails,
  storePartiallyAcceptedItem: orderManagementActions.storePartiallyAcceptedItem,
  getIncomingOrdersBackup: orderManagementActions.getIncomingOrdersBackup,
  getOrderList: orderManagementActions.getOrderList,
  trigerEditOrders: orderManagementActions.trigerEditOrders,
  setShouldShowEditOrderViewInOrderDetailsPage:
    orderManagementActions.setShouldShowEditOrderViewInOrderDetailsPage,
};

export default compose(
  withRouter,
  connect(mapState, actionCreators)
)(SVTIncomingOrderDetailsComponent);
