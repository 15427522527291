import React from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import phone_icon from '../../images/phone_icon.svg';
import cross_icon from '../../images/cross_icon.svg';

function SVTCustomerSupportPopup(props) {
  const { show, onHide, venderName, supportNumber } = props;
  const modalProps = { show, onHide };

  return (
    <Modal
      {...modalProps}
      size='md'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header style={{ padding: '20px 20px 0px' }}>
        <Modal.Title id='contained-modal-title-vcenter'>
          <p className='m-0 p-0 normal-font fw-bold'>Contact Support</p>
        </Modal.Title>
        <img
          src={cross_icon}
          height='21.98px'
          width='21.98px'
          onClick={props.onHide}
          alt='cross icon'
          className='cursor-pointer'
        />
      </Modal.Header>
      <Modal.Body style={{ padding: '0px 20px 0px 20px' }}>
        <Row>
          <Col sm={12} className='m-0'>
            <hr
              className='m-0 contact-supp-hr-help'
              style={{ marginTop: '15px', marginBottom: '15px' }}
            />
          </Col>
          <Col md={12}> Contact {venderName} Support</Col>
        </Row>
        <Row className='pt-2'>
          <Col md={1}>
            <img src={phone_icon} className='pt-2' alt='Phone_icon' />
          </Col>
          <Col md={11} style={{ paddingLeft: '10px' }}>
            <span className='text-success order-support-number'>
              {supportNumber}
            </span>
          </Col>
        </Row>
        {/* It is only for Deliveroo */}
        {venderName === 'Deliveroo' ? (
          <Row>
            <Col md={12} className='pt-2'>
              <span className='gray_text'>Refer Deliveroo tablet for code</span>
            </Col>
          </Row>
        ) : null}
      </Modal.Body>
      <Modal.Footer style={{ padding: '20px 20px 20px 20px' }}>
        <button
          type='button'
          className='success-btn-modal ms-auto'
          onClick={props.onHide}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}
export default SVTCustomerSupportPopup;
