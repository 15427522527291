
let storedFlags = null;

export function initializeFlags(flags) {
  storedFlags = flags;
}

export function getFlags() {
  return storedFlags;
}
