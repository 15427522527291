import React, { Component } from 'react';
import { Row, Col, Modal } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import menuLog from '../../images/logos/deliveroo/deliveroo.png';
import hotDrinks from '../../images/hotDrinks.svg';
import uberEats from '../../images/UberEats.svg';
import Clock from '../../images/Clock.png';
import emptyClock from '../../images/EmptyClock.png';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { orderManagementActions } from '../../action/SVTOrderManagementAction';
import chevronUp from '../../images/up.svg';
import chevronDown from '../../images/down.svg';
import { sortingConstants } from '../../constant/sortingConstant';
import SVTNoOrderScreen from './SVTNoOrderScreen';
import greenOutlineCloseBtn from '../../images/green-close-btn.svg';
import CheckBox from '../../images/bp-icon-tick-box-black-green-outline.svg';
import emptyCheckBox from '../../images/bp-icon-tick-box-black-green-outline-bg-white.svg';
import cross_icon from '../../images/cross_icon.svg';
import Crossmark_circle from '../../images/Crossmark_circle.svg';

const listOfVendorForExpliciteOrderCompletion = new Set(['']);

const OrderTableHeader = (componentProps) => {
  const {
    props,
    orderListData,
    handleTimeSorting,
    filterDataInAscendingOrder,
    filterDataInDescendingOrder,
    handleItemsSorting,
    handleStatusSorting,
    handleETASorting,
  } = componentProps;

  return (
    <div className='order_table_container'>
      <div className='border-bottom svt-bold-text'>
        <div
          className='row m-auto active-order-table-for-smaller-screen'
          style={{ padding: '20px 0px' }}
        >
          <div className='col-md-3 col-sm-2 col-2 col d-flex width-100-active-order-table ps-5'>
            <span className='cursor-pointer olivegreen-color-text'>
              {'Order Id'}
            </span>
          </div>
          <div className='col-md-2 col-sm-2 col-2 col d-flex width-100-active-order-table'>
            <span
              className='cursor-pointer olivegreen-color-text'
              onClick={() => handleTimeSorting()}
              data-test='orderTimeSpan'
            >
              {'Time'}
            </span>

            <div className='sorting-button-margin d-grid'>
              <img
                className='img-responsive cursor-pointer '
                alt=''
                src={chevronUp}
                onClick={() =>
                  filterDataInAscendingOrder(sortingConstants.ORDER_TIME)
                }
                data-test='orderTimeChevronUp'
              />
              <img
                className='img-responsive cursor-pointer '
                alt=''
                src={chevronDown}
                onClick={() =>
                  filterDataInDescendingOrder(sortingConstants.ORDER_TIME)
                }
                data-test='orderTimeChevronDown'
              />
            </div>
          </div>
          <div className='col-md-1 col-sm-1 col-1 col order-list-item-margin d-flex ps-0 width-100-active-order-table'>
            <span
              className='cursor-pointer olivegreen-color-text'
              data-test='orderItemsSpan'
              onClick={() => handleItemsSorting()}
            >
              {'Items'}
            </span>
            <div className='sorting-button-margin d-grid'>
              <img
                src={chevronUp}
                alt=''
                className='img-responsive cursor-pointer '
                data-test='orderItemsChevronUp'
                onClick={() =>
                  filterDataInAscendingOrder(sortingConstants.ITEMS)
                }
              />
              <img
                src={chevronDown}
                alt=''
                className='img-responsive cursor-pointer '
                data-test='orderItemsChevronDown'
                onClick={() =>
                  filterDataInDescendingOrder(sortingConstants.ITEMS)
                }
              />
            </div>
          </div>
          <div className='col-md-2 col-sm-3 col-3 col hot-drink-padding d-flex width-100-active-order-table'>
            <span
              className='cursor-pointer olivegreen-color-text'
              data-test='orderStatusSpan'
              onClick={() => handleStatusSorting()}
            >
              {'Status'}
            </span>
            <div className='sorting-button-margin d-grid'>
              <img
                alt=''
                src={chevronUp}
                className='img-responsive cursor-pointer '
                data-test='orderStatusChevronUp'
                onClick={() =>
                  filterDataInAscendingOrder(sortingConstants.STATUS)
                }
              />
              <img
                alt=''
                src={chevronDown}
                className='img-responsive cursor-pointer '
                data-test='orderStatusChevronDown'
                onClick={() =>
                  filterDataInDescendingOrder(sortingConstants.STATUS)
                }
              />
            </div>
          </div>
          <div className='col-md-2 col-sm-2 col-2 d-flex width-100-active-order-table'>
            <span
              className='cursor-pointer olivegreen-color-text'
              onClick={() => handleETASorting()}
              data-test='orderETASpan'
            >
              {'ETA'}
            </span>
            <div className='sorting-button-margin d-grid'>
              <img
                className='img-responsive cursor-pointer '
                alt=''
                src={chevronUp}
                onClick={() => filterDataInAscendingOrder(sortingConstants.ETA)}
                data-test='orderETAChevronUp'
              />
              <img
                className='img-responsive cursor-pointer '
                alt=''
                src={chevronDown}
                onClick={() =>
                  filterDataInDescendingOrder(sortingConstants.ETA)
                }
                data-test='orderETAChevronDown'
              />
            </div>
          </div>
        </div>
      </div>

      {props.orderReportListCount?.active_orders_count >= 0 ? (
        <div className='order_list_data'>{orderListData}</div>
      ) : (
        <SVTNoOrderScreen path={'order-list'} />
      )}
    </div>
  );
};

export class SVTOrderListComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ORDER_ID: true,
      ETA: true,
      ORDER_TIME: true,
      ITEMS: true,
      STATUS: true,
      selectedOrderList: [],
      showCompleteOrderPopUp: false,
    };
  }

  componentDidMount() {
    this.props.getOrderList();
    this.props.getPartnersList();
  }
  showOrderDetails(order) {
    this.props.storeOrderDetails(order);
    this.props.setShouldShowEditOrderViewInOrderDetailsPage(false);
    const pathVal = 'Incoming';
    this.props.history.push(`/order-details/${pathVal}`);
  }

  filterDataInAscendingOrder = (sortBy) => {
    this.props?.sortOrderItemList(
      this.props.orderList,
      sortBy,
      sortingConstants.ASCENDING
    );
  };

  filterDataInDescendingOrder = (sortBy) => {
    this.props?.sortOrderItemList(
      this.props.orderList,
      sortBy,
      sortingConstants.DESCENDING
    );
  };
  formatDateToDateMonth = (newDate) => {
    var months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sept',
      'Oct',
      'Nov',
      'Dec',
    ];
    const dateDate = new Date(newDate);
    const dateVal = dateDate?.getDate().toString().padStart(2, '0');
    const month = months[dateDate.getMonth()];
    return `${dateVal} ${month}`;
  };
  selectedOrderListHandler = (isSelected, currentOrder) => {
    if (isSelected === 'selected') {
      this.setState((prevState) => ({
        ...prevState,
        selectedOrderList: this.state.selectedOrderList.filter(
          (order) => order.id !== currentOrder.id
        ),
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        selectedOrderList: [...this.state.selectedOrderList, currentOrder],
      }));
    }
  };
  isPresentInSelectedOrderList = (currentOrder) => {
    const currentSelectedOrder = this.state.selectedOrderList;
    return currentSelectedOrder.find((order) => order.id === currentOrder.id);
  };
  resetAllSelectedOrderList = () => {
    this.setState((prevState) => ({
      ...prevState,
      selectedOrderList: [],
    }));
  };
  markAsCompletedBtnHandler = (action) => {
    this.setState((prevState) => ({
      ...prevState,
      showCompleteOrderPopUp: action,
    }));
  };

  getCursorPointer = (order) => {
    if (this.isPresentInSelectedOrderList(order)) {
      return 'align-items-center cursor-pointer  active-order-bottom-border sea-mist-bg';
    } else {
      return 'align-items-center cursor-pointer  border-bottom';
    }
  };

  getImage = (order) => {
    if (this.isPresentInSelectedOrderList(order)) {
      return (
        <img
          src={CheckBox}
          alt='CheckBox'
          className='mt-4'
          width='17px'
          height='17.1px'
          id='selected'
          onClick={(e) => this.selectedOrderListHandler(e.target.id, order)}
        />
      );
    } else {
      return (
        <img
          src={emptyCheckBox}
          alt='CheckBox'
          className='mt-4 ms-3'
          width='17px'
          height='17.1px'
          id='notSelected'
          onClick={(e) => this.selectedOrderListHandler(e.target.id, order)}
        />
      );
    }
  };

  getETAInHours = (mins) => {
    if (parseInt(mins) < 60) {
      return `${mins} mins`;
    } else {
      const hours = Math.floor(mins / 60);
      mins = mins % 60;

      return `${hours} hr ${mins} min`;
    }
  };

  getEtaImage = (order) => {
    if (
      order?.partner_status === 'Driver Arrived' ||
      order?.partner_status === 'Driver on the way'
    ) {
      return (
        <div className='d-flex align-items-center'>
          <span>
            <img
              className='img-responsive'
              alt=''
              src={
                order?.partner_status !== 'Driver Arrived' ? Clock : emptyClock
              }
              style={{ marginRight: '5px' }}
            />
          </span>
          {!order?.eta_pickup ? '-' : this.getETAInHours(order?.eta_pickup)}
        </div>
      );
    } else {
      return (
        <div className='d-flex align-items-center'>
          <span className='text-center'>&nbsp;&nbsp;-</span>
        </div>
      );
    }
  };

  handleStatusSorting = () => {
    this.state.STATUS === true
      ? this.filterDataInAscendingOrder.bind(this)(sortingConstants.STATUS) ||
        this.setState({
          STATUS: !this.state.STATUS,
        })
      : this.filterDataInDescendingOrder.bind(this)(sortingConstants.STATUS) ||
        this.setState({
          STATUS: !this.state.STATUS,
        });
  };

  handleItemsSorting = () => {
    this.state.ITEMS === true
      ? this.filterDataInAscendingOrder.bind(this)(sortingConstants.ITEMS) ||
        this.setState({
          ITEMS: !this.state.ITEMS,
        })
      : this.filterDataInDescendingOrder.bind(this)(sortingConstants.ITEMS) ||
        this.setState({
          ITEMS: !this.state.ITEMS,
        });
  };

  handleTimeSorting = () => {
    this.state.ORDER_TIME === true
      ? this.filterDataInAscendingOrder.bind(this)(
          sortingConstants.ORDER_TIME
        ) ||
        this.setState({
          ORDER_TIME: !this.state.ORDER_TIME,
        })
      : this.filterDataInDescendingOrder.bind(this)(
          sortingConstants.ORDER_TIME
        ) ||
        this.setState({
          ORDER_TIME: !this.state.ORDER_TIME,
        });
  };

  handleETASorting = () => {
    this.state.ETA === true
      ? this.filterDataInAscendingOrder.bind(this)(sortingConstants.ETA) ||
        this.setState({
          ETA: !this.state.ETA,
        })
      : this.filterDataInDescendingOrder.bind(this)(sortingConstants.ETA) ||
        this.setState({
          ETA: !this.state.ETA,
        });
  };

  getCoffeeIcon = (order) => {
    if (order?.coffee === 'true') {
      return (
        <span>
          <img className='img-responsive' alt='' src={hotDrinks} />
        </span>
      );
    } else {
      return null;
    }
  };

  render() {
    const orderList = this.props.orderList;
    const orderListData =
      orderList?.length > 0 &&
      orderList.map((order, index) => {
        return (
          <div
            className={this.getCursorPointer(order)}
            key={index}
            data-test={`orderListDiv${index}`}
            id={order.partner_order_id}
          >
            <div
              className='row m-auto order-id active-order-table-for-smaller-screen m-0 p-0'
              style={{ padding: '20px 0px' }}
            >
              <div className='col-md-3 col-sm-2 col-2 col svt-bold-text cursor-pointer align-self-center width-100-active-order-table p-0 m-0'>
                <div className='d-flex'>
                  {this.isPresentInSelectedOrderList(order) && (
                    <div className='active-order-left-bar me-3'></div>
                  )}

                  {listOfVendorForExpliciteOrderCompletion.has(
                    order.partner_name.trim('').toLowerCase()
                  ) && this.getImage(order)}

                  <span
                    className={
                      listOfVendorForExpliciteOrderCompletion.has(
                        order.partner_name.trim('').toLowerCase()
                      )
                        ? 'img-orderId-space order-list-row-padding ms-2'
                        : 'img-orderId-space order-list-row-padding ml-45'
                    }
                  >
                    <img
                      className='img-responsive'
                      alt=''
                      src={
                        order.partner_name.trim('').toLowerCase() ===
                        'deliveroo'
                          ? menuLog
                          : uberEats
                      }
                      onClick={this.showOrderDetails.bind(this, order)}
                    />
                  </span>
                  <u
                    className='order-list-row-padding'
                    onClick={this.showOrderDetails.bind(this, order)}
                  >
                    #{order.partner_id?.toUpperCase()}
                  </u>
                </div>
              </div>
              <div
                className='col-md-2 col-sm-2 col-2 col width-100-active-order-table order-list-row-padding'
                onClick={this.showOrderDetails.bind(this, order)}
              >
                <div className='histoy-table-time menu-header'>
                  {this.formatDateToDateMonth(order?.created_on)}
                </div>
                <div className='d-flex align-items-center word-break'>
                  {order.time}
                </div>
              </div>
              <div
                className='d-flex col-md-1 col-sm-1 col-1 col order-list-item-margin ps-0 width-100-active-order-table order-list-row-padding'
                onClick={this.showOrderDetails.bind(this, order)}
              >
                <div className='d-flex align-items-center'>
                  {order.count?.total_items}
                </div>
              </div>
              <div
                className='d-flex col-md-2 col-sm-3 col-3 col hot-drink-padding status-letter-spacing width-100-active-order-table order-list-row-padding'
                onClick={this.showOrderDetails.bind(this, order)}
              >
                <div className='d-flex align-items-center text-capitalize'>
                  {order?.partner_status}
                </div>
              </div>
              <div
                className='col-md-2 col-sm-2 col-2 col d-flex order-id px-4 min-letter-spacing width-100-active-order-table order-list-row-padding'
                onClick={this.showOrderDetails.bind(this, order)}
              >
                {this.getEtaImage(order)}
              </div>
              <div
                className='col-md-1 col-sm-1 col-2 col text-center mt-1 hot-drink-padding width-100-active-order-table hide-eta-icon-smaller-screen order-list-row-padding'
                onClick={this.showOrderDetails.bind(this, order)}
              >
                {this.getCoffeeIcon(order)}
              </div>
            </div>
          </div>
        );
      });
    return (
      <>
        <CompleteOrderPopUp
          show={this.state.showCompleteOrderPopUp}
          onHide={this.markAsCompletedBtnHandler}
          selectedOrderList={this.state.selectedOrderList}
          resetList={this.resetAllSelectedOrderList}
          completeOrder={this.props.completeOrder}
          history={this.props.history}
        />
        <div className='live-order-order-list-container'>
          <div className='d-flex justify-content-between'>
            <section className='d-flex justify-content-center align-content-center mt-20'>
              <div className='order_header svt-bold-text text-center'>
                {'Orders'}
              </div>
              {this.props.orderReportListCount?.active_orders_count === 0 ? (
                <div
                  className='notification_style ms-2'
                  style={{ backgroundColor: '#ebebeb' }}
                >
                  {this.props.orderReportListCount?.active_orders_count}
                </div>
              ) : (
                <div className='notification_style ms-2 green_background-color'>
                  {this.props.orderReportListCount?.active_orders_count}
                </div>
              )}
            </section>
            {this.state.selectedOrderList.length > 0 && (
              <section className='d-flex mt-10 mr-20 flex-gap-20'>
                <img
                  src={greenOutlineCloseBtn}
                  alt='close icon'
                  className='cursor-pointer'
                  onClick={this.resetAllSelectedOrderList}
                />
                <div className='mark-as-completed-btn'>
                  <div
                    className='text-white letter-spacing-125 font-size-18'
                    onClick={() => this.markAsCompletedBtnHandler(true)}
                  >
                    Mark As Completed
                  </div>
                  <div className='notification_style bg-white font-size-12 olivegreen-color-text'>
                    {this.state.selectedOrderList.length}
                  </div>
                </div>
              </section>
            )}
          </div>

          {OrderTableHeader({
            props: this.props,
            orderListData: orderListData,
            handleTimeSorting: this.handleTimeSorting,
            filterDataInAscendingOrder:
              this.filterDataInAscendingOrder.bind(this),
            filterDataInDescendingOrder:
              this.filterDataInDescendingOrder.bind(this),
            handleItemsSorting: this.handleItemsSorting,
            handleStatusSorting: this.handleStatusSorting,
            handleETASorting: this.handleETASorting,
          })}
        </div>
      </>
    );
  }
}

function CompleteOrderPopUp(props) {
  const { show, onHide, selectedOrderList, resetList, completeOrder } = props;
  const [orderList, setOrderList] = React.useState([]);

  React.useEffect(() => {
    setOrderList(selectedOrderList);
  }, [selectedOrderList, show]);

  const completeOrderHandler = () => {
    const completeOrdersList = orderList.map((order) => order.id);
    completeOrder(completeOrdersList);
    resetList();
    onHide(false);
    props.history.push('/');
  };

  const removeOrder = (id) => {
    const tmpList = orderList.filter((order) => order.partner_id !== id);
    setOrderList(tmpList);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size='md'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <Modal.Title id='contained-modal-title-vcenter'>
          <div className='d-flex'>
            <p className='m-0 p-0 normal-font fw-bold ps-2'>
              Order picked up by driver?
            </p>
          </div>
        </Modal.Title>
        <img
          src={cross_icon}
          height='21.98px'
          width='21.98px'
          onClick={() => onHide(false)}
          alt='cross icon'
          className='cursor-pointer'
        />
      </Modal.Header>
      <Modal.Body className='p-0 m-0 ps-3 pe-3'>
        <Row>
          <Col sm={12} className='m-0 pb-3 pl-20'>
            <hr className='m-0 contact-supp-hr' />
          </Col>
          <Col md={12} className='pl-20 complete-order-body-text'>
            Do you want to mark the following order(s) as completed?
          </Col>
          {orderList?.map((order) => {
            return (
              <Col md={12} className='complete-order-container'>
                <img
                  className='complete-order-menulog-img'
                  src={menuLog}
                  alt='Deliveroo'
                />
                <div className='complete-order-body-text fw-bold'>
                  {' '}
                  #{order.partner_id}{' '}
                </div>
                {orderList.length > 1 && (
                  <img
                    src={Crossmark_circle}
                    alt='remove order'
                    className='complete-order-close-img'
                    onClick={() => {
                      removeOrder(order.partner_id);
                    }}
                  />
                )}
              </Col>
            );
          })}
        </Row>
      </Modal.Body>
      <Modal.Footer className='complete-order-footer'>
        <div className='complete-order-footer-text'>
          If confirmed, you can view the order(s) from the order history page.
        </div>
        <button
          type='button'
          className='green-outline-btn shadow-none letter-spacing-125'
          onClick={() => onHide(false)}
        >
          Cancel
        </button>
        <button
          type='button'
          className={' shadow-none ms-3 success-btn-modal letter-spacing-125'}
          onClick={() => completeOrderHandler()}
        >
          Confirm
        </button>
      </Modal.Footer>
    </Modal>
  );
}

function mapState(state) {
  const { orderManagementRelated } = state;
  const { orderList, orderReportListCount } = orderManagementRelated;
  return { orderList, orderReportListCount };
}
const actionCreators = {
  getOrderList: orderManagementActions.getOrderList,
  storeOrderDetails: orderManagementActions.storeOrderDetails,
  sortOrderItemList: orderManagementActions.sortOrderItemList,
  getPartnersList: orderManagementActions.getPartnersList,
  completeOrder: orderManagementActions.completeOrder,
  setShouldShowEditOrderViewInOrderDetailsPage:
    orderManagementActions.setShouldShowEditOrderViewInOrderDetailsPage,
};

export default compose(
  withRouter,
  connect(mapState, actionCreators)
)(SVTOrderListComponent);
