import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';

const calculateDuration = (date) =>
  moment.duration(
    Math.max(Math.floor((date - new Date().getTime()) / 1000), 0),
    'seconds'
  );

function OrderCountDown({ date, acceptanceTime, partnerName }) {
  const currentDate = new Date(date).getTime();
  const acceptTime = new Date(acceptanceTime).getTime();
  const defaultDuration =
    currentDate + (partnerName === 'Deliveroo' ? 900000 : 600000);
  const interval = 1000;
  const eventTime = acceptanceTime ? acceptTime : defaultDuration;
  const [duration, setDuration] = useState(calculateDuration(eventTime));
  const timerRef = useRef(0);
  const timerCallback = useCallback(() => {
    setDuration(calculateDuration(eventTime));
  }, [eventTime]);

  useEffect(() => {
    timerRef.current = setInterval(timerCallback, interval);

    return () => {
      clearInterval(timerRef.current);
    };
  }, [eventTime, interval, timerCallback]);

  return (
    <>
      {duration.minutes() === 0 && duration.seconds() === 0 ? (
        <>
          <span className='order-count-down'>
            Order will be cancelled shortly
          </span>
        </>
      ) : (
        <>
          <span className='order-count-down'>
            {duration.minutes()} Minutes {duration.seconds()} Sec
          </span>
        </>
      )}
    </>
  );
}

export default OrderCountDown;
