import React from 'react';
import { Row, Col } from 'react-bootstrap';
import upArrowNoteIcon from '../../images/arrow-up-note.svg';
import OrderCategoryBarcode from '../../services/OrderCategoryBarcode';
function SVTIncomingOrderItemsComponent({ order, partnerName }) {
  let totalQuantity = 0;
  totalQuantity += Number(order?.quantity ? order?.quantity : 1);
  const itemName = order.name;

  return (
    <div
      className='mt-2'
      data-test='incomingDetailsContainer'
      key={'incomingorderitems_' + order?.plu}
    >
      <Row className='mx-0'>
        <Col md={2} className='px-0'>
          <img src={order?.image_url} height='50px' width='50px' alt=''></img>
        </Col>
        <Col md={10} className='px-0'>
          <div>
            <Row className='mx-0'>
              <div className='menu-category menu-header px-0'>
                {OrderCategoryBarcode(order)}
              </div>
              <Col md={8} className='px-0 live-order-menu-item-text'>
                {order?.name}
              </Col>
              <Col md={3} className='px-0  item-qty-price-number'>
                x{totalQuantity}
              </Col>
              <Col
                md={1}
                className='px-0  item-qty-price-number d-flex flex-row-reverse'
              >
                <span>
                  £
                  {parseFloat(
                    order?.unit_price
                      ? order?.unit_price / 100
                      : order?.price / order?.quantity / 100
                  ).toFixed(2)}
                </span>
              </Col>
            </Row>
            {order?.children?.length > 0 &&
              order?.children?.map((itm, index) => {
                return (
                  <div key={'orderchildrenparent_' + index}>
                    <ModifierItemView
                      key={index}
                      itm={itm}
                      partnerName={partnerName}
                      itemName={itemName}
                    />
                    {itm?.children &&
                      itm.children.map((subModifier, indxSubModifier) => {
                        return (
                          <ModifierItemView
                            key={indxSubModifier}
                            itm={subModifier}
                            partnerName={partnerName}
                            itemName={itemName}
                          />
                        );
                      })}
                  </div>
                );
              })}

            {order?.special_instructions?.length > 0 && (
              <Row className='mx-0 mt-2'>
                <Col md={12} className='px-0'>
                  <img
                    src={upArrowNoteIcon}
                    alt='upArrowNoteIcon'
                    className='item-specific-instruction-image'
                  />
                  <span className='ps-1 item-specific-instruction'>
                    {order?.special_instructions}
                  </span>
                </Col>
              </Row>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default SVTIncomingOrderItemsComponent;

function ModifierItemView(props) {
  const { itm, partnerName } = props;
  return (
    <Row className='mx-0 mb-10 mt-10'>
      <Col
        md={8}
        className=' d-flex justify-content-start flex-gap-10 padding-left-20'
      >
        <div className='d-flex flex-column'>
          <div className='light-grey-txt font-size-12 letter-spacing-15 text-break text-uppercase'>
            {OrderCategoryBarcode(itm)}
          </div>
          <div className='large_text letter-spacing-O50'>
            {' '}
            {partnerName === 'Deliveroo' ? itm?.name : itm?.description}
          </div>
        </div>
      </Col>
      <Col md={3} className='px-0 d-flex flex-column'>
        <div className='font-size-14 letter-spacing-O25'>x{itm?.quantity}</div>
      </Col>
      <Col md={1} className='px-0 d-flex flex-column'>
        <div className='w-100 font-size-14 letter-spacing-O25 d-flex flex-row-reverse'>
          <span>
            £
            {(itm?.unit_price
              ? itm?.unit_price / 100
              : itm?.price / itm?.quantity / 100
            ).toFixed(2)}
          </span>
        </div>
      </Col>
    </Row>
  );
}
