import React, { Component } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import SVTExceptionPage from '../common/SVTExceptionPage';
import SVTAuthCheck from '../common/SVTAuthCheck';

// See https://github.com/derrickpelletier/react-loading-overlay/pull/57
LoadingOverlay.propTypes = null;

class SVTMainComponent extends Component {
  componentDidMount() {}

  render() {
    return (
      <LoadingOverlay
        active={this.props.showLoader}
        className='loaderSpinner'
        spinner
        styles={{
          wrapper: {
            width: '100%',
            height: '100%',
          },
        }}
      >
        <SVTAuthCheck>
          <SVTExceptionPage>{this.props.children}</SVTExceptionPage>
        </SVTAuthCheck>
      </LoadingOverlay>
    );
  }
}

export function mapState(state) {
  const { loader } = state;
  const { showLoader } = loader;
  return { showLoader };
}

const actionCreators = {};

export default compose(
  withRouter,
  connect(mapState, actionCreators)
)(SVTMainComponent);
