import React, { useState, useCallback, useContext } from 'react';
import ReactDOM from 'react-dom';
import { Provider, useDispatch } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { history } from './helper/history';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import SVTLandingPage from './components/SVTLandingPage';
import { store, persistor } from './helper/store';
import { PersistGate } from 'redux-persist/integration/react';
import SVTOrderHistoryComponent from './components/aggregators/SVTOrderHistoryComponent';
import SVTOrderHelpComponent from './components/aggregators/SVTOrderHelpComponent';
import SVTOrderViewComponent from './components/aggregators/SVTOrderViewComponent';
import SVTOrderSettingPage from './components/aggregators/SVTOrderSettingPage';
import SVTOrderHistoryDetails from './components/aggregators/SVTOrderHistoryDetails';
import SVTNotification from './components/aggregators/SVTNotification';
import SVTOrderListScheduler from './components/aggregators/SVTOrderListScheduler';
import SVTMenuPage from './components/aggregators/SVTMenuPage';
import OfflinePopup from './components/aggregators/SVTOfflinePopup';
import { LogType, delayForGivenTime, handleResponse, log } from './helper/common';
import { withLDProvider, useFlags } from 'launchdarkly-react-client-sdk';
import { initializeFlags } from './helper/launchDarkly';
import { apiUrl } from './url/apiUrl';
import { constants } from './constant/reducerConstant';
import Modal from './components/common/Modal';
import ModalContext from './contexts/ModalContext';
import { StoreClosureContext } from './contexts/StoreClosureContext';
import { refreshToken } from './helper/common';



const firebaseConfig = new URLSearchParams({
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
}).toString();

const LaunchDarklyConfig = {
  clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID,
};

const SVTAppWithLaunchDarkly = withLDProvider(LaunchDarklyConfig)(SVTApp);

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register(`../firebase-messaging-sw.js?${firebaseConfig}`)
    .then(function (registration) {
      log('FCM Service Worker Registration successful, scope is:', registration.scope, LogType.INFO);
      console.log('Registration successful, scope is:', registration.scope);
    })
    .catch(function (err) {
      log('FCM Service worker registration failed, error:', err, LogType.ERROR);
      console.log('Service worker registration failed, error:', err);
    });
}

function SVTApp(props) {

  const {
    storeDetails: { store_status: storeStatus = 'Online' } = {},
  } = props;

  const [showModal, setShowModal] = useState(false);

  const toggleModal = useCallback(() => {
    setShowModal(prevShowModal => !prevShowModal);
  }, []);

  const [isOnline, setIsOnline] = React.useState(navigator.onLine);
  const [showOfflinePopup, setShowOfflinePopup] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const [storeClosureReason, setStoreClosureReason] = useState('');

  const checkInternetConnection = async () => {
    setIsLoading(true);
    if (navigator.onLine) {
      setIsOnline(true);
      setShowOfflinePopup(false);
    }
    await delayForGivenTime(300);
    setIsLoading(false);
  };

  const dispatch = useDispatch();

  const toggleStoreStatus = (storeStatus, storeClosureReason, props) => {
    if (storeIdRef.current === null) {
      getStoreDetails().then((details) => {
        storeIdRef.current = details.store_data[0].store_id;
        if (storeStatus.toLowerCase() === 'online') {
          props.updateStoreStatus('Offline', storeClosureReason, storeIdRef.current)
        } else if (storeStatus.toLowerCase() === 'offline') {
          props.updateStoreStatus('Online', '', storeIdRef.current)
        }
      });
    } else {
      if (storeStatus.toLowerCase() === 'online') {
        props.updateStoreStatus('Offline', storeClosureReason, storeIdRef.current)
      } else if (storeStatus.toLowerCase() === 'offline') {
        props.updateStoreStatus('Online', '', storeIdRef.current)
      }
    }
  }

  React.useEffect(() => {
    const handleOnline = () => {
      log('App is online', null, LogType.INFO);
      setIsOnline(true);
      setShowOfflinePopup(false);
    };

    const handleOffline = () => {
      log('App is offline', null, LogType.INFO);
      setIsOnline(false);
      setShowOfflinePopup(true);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      log('Removing online and offline event listeners', null, LogType.INFO);
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  const flags = useFlags();
  //save the flags in a launchDarkly helper component for use in other component / functions
  initializeFlags(flags);
  const enableHeartbeatCheck = flags['enableHeartbeatCheck'];
  const fiveSecHeartbeatCheck = flags['5SecHeartbeatCheck'];

  const siteIdRef = React.useRef(null);
  const storeIdRef = React.useRef(null);

  React.useEffect(() => {
    getStoreDetails().then((storeDetails) => {
      siteIdRef.current = storeDetails.store_data[0].site_id;
      storeIdRef.current = storeDetails.store_data[0].store_id;
    });
  });

  React.useEffect(() => {
    // Event listener for the custom event
    document.addEventListener('pollServer', handlePolling);

    // Define a custom event to handle the polling
    const pollEvent = new CustomEvent('pollServer', {
      detail: {
        enableHeartbeatCheck: enableHeartbeatCheck,
        fiveSecHeartbeatCheck: fiveSecHeartbeatCheck,
        siteIdRef: siteIdRef,
        dispatch: dispatch,
      },
    });
    // Start the polling by dispatching the event
    document.dispatchEvent(pollEvent);
  }, [dispatch, enableHeartbeatCheck, fiveSecHeartbeatCheck]);

  return (
    <BrowserRouter history={history}>
      <StoreClosureContext.Provider value={{storeClosureReason, setStoreClosureReason}}>
        <ModalContext.Provider value={{ showModal, toggleModal  }}>
          <SVTOrderListScheduler isOnline={isOnline} />
          <SVTNotification isOnline={isOnline} />
          <OfflinePopup
            show={showOfflinePopup}
            retryHandler={checkInternetConnection}
            isLoading={isLoading}
          />
          <Switch>
            <Route exact path='/' component={SVTLandingPage} />
            <Route
              exact
              path='/order-details/:type'
              component={SVTOrderViewComponent}
            />
            <Route exact path='/order-list' component={SVTLandingPage} />
            <Route
              exact
              path='/order-history'
              component={SVTOrderHistoryComponent}
            />
            <Route exact path='/order-help' component={SVTOrderHelpComponent} />
            <Route exact path='/order-setting' component={SVTOrderSettingPage} />
            <Route
              exact
              path='/order-history-details'
              component={SVTOrderHistoryDetails}
            />
            <Route exact path='/menu' component={SVTMenuPage} />
          </Switch>
          {showModal && <Modal toggleModal={toggleModal} toggleStoreStatus={toggleStoreStatus}></Modal>}
        </ModalContext.Provider>
      </StoreClosureContext.Provider>
    </BrowserRouter>
  );
}

// Function to handle polling
function handlePolling(event) {
  const { enableHeartbeatCheck, fiveSecHeartbeatCheck, siteIdRef, dispatch } =
    event.detail;
  if (enableHeartbeatCheck === undefined || !enableHeartbeatCheck) {
    return;
  }

  const pingInterval = fiveSecHeartbeatCheck ? 5000 : 60000;

  try {
    if (siteIdRef.current === null) {
      getStoreDetails().then((storeDetails) => {
        siteIdRef.current = storeDetails.store_data[0].site_id;
      });
    }
    pingAmpServer(siteIdRef.current, dispatch);
    pingOrderInServer();
  } catch (error) {
    console.error('An error occurred:', error);
  }

  setTimeout(() => {
    const pollEvent = new CustomEvent('pollServer', {
      detail: {
        enableHeartbeatCheck: enableHeartbeatCheck,
        fiveSecHeartbeatCheck: fiveSecHeartbeatCheck,
        siteIdRef: siteIdRef,
        dispatch: dispatch,
      },
    });
    document.dispatchEvent(pollEvent);
  }, pingInterval);
}

// Function to execute the graphql mutation for the site ping status
function pingAmpServer(siteId, dispatch) {
  refreshToken(dispatch);

  const endpoint = process.env.REACT_APP_AMP_API_URL;
  const token = store.getState().authInfo?.accessToken;
  const input = {
    orderInLastPingTime: new Date().toISOString(),
  };

  const mutation = `
            mutation UpdatePingStatus($siteId: String!) {
              updatePingStatus(internalSiteID: $siteId){
                internalSiteID
                id
                orderInLastPingTime
              }
            }
        `;

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  try {
    fetch(endpoint, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        query: mutation,
        variables: { siteId, input },
      }),
    });
  } catch (error) {
    log('Ping test to Amp server Error:', error, LogType.ERROR);
    console.error('There was a problem with the fetch operation:', error);
  }
}

// Function to execute the ping test
function pingOrderInServer() {
  const requestOptions = {
    method: 'GET',
    headers: {
      code: store.getState().authInfo?.accessToken,
      token:
        store.getState().orderManagementRelated?.storeDeviceToken?.device_token,
    },
  };
  const url = `${process.env.REACT_APP_URL}/ping-test`;

  try {
    fetch(url, requestOptions);
  } catch (error) {
    log('Ping test response to OrderIn server Error:', error, LogType.ERROR);
    console.error('An error occurred:', error);
  }
}

function getStoreDetails() {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      code: store.getState().authInfo?.accessToken,
      token:
        store.getState().orderManagementRelated?.storeDeviceToken?.device_token,
    },
  };
  const url = `${apiUrl.url}/my_store_details`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

ReactDOM.render(
  <React.Fragment>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <SVTAppWithLaunchDarkly />
      </PersistGate>
    </Provider>
  </React.Fragment>,
  document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
