import { compose } from 'redux';
import { connect } from 'react-redux';
import { menuManagementAction } from '../../action/SVTMenuManagementAction';
function SVTModifiersItems(props) {
  const { currentModifiersList, updateModifierStatus } = props;
  const modifierAvailabilityHandler = (skuCode, availabilityFlag) => {
    const payload = {
      sku_code: skuCode,
      status: String(availabilityFlag),
    };
    updateModifierStatus(payload);
  };
  return (
    <>
      {currentModifiersList &&
        currentModifiersList.map((modifier) => {
          const {
            alter_name: modifierName = '',
            numberOfProductsHavingThisModifier = 1,
            availability = true,
            sku_code: skuCode = '',
          } = modifier;
          return (
            <main
              className='modifier-main-container'
              data-test='modifierMainContainer'
              key={skuCode}
            >
              <section className='modifier-title d-flex align-items-center flex-gap-10'>
                <div className='letter-spacing-O50 text-capitalize text-break text-center'>
                  {modifierName}
                </div>
                <div className='font-size-14 gray_text letter-spacing-O25 text-break text-center'>
                  {`in ${numberOfProductsHavingThisModifier} products`}
                </div>
              </section>
              <section className='w-100 modifier-availability'>
                <section className='d-flex flex-gap-20 w-100'>
                  <div className='d-flex flex-column float-end'>
                    <div className='small_text gray_text text-uppercase letter-spacing-15 text-end font-size-12 pt-1'>
                      {availability ? 'Available' : 'Unavailable'}
                    </div>
                  </div>
                  <label className='switch'>
                    <input
                      type='checkbox'
                      id='selectID'
                      checked={availability}
                      onChange={(e) =>
                        modifierAvailabilityHandler(skuCode, e.target.checked)
                      }
                      data-test='availabilityHandlerForModifiers'
                    />
                    <span className='slider round'></span>
                  </label>
                </section>
              </section>
            </main>
          );
        })}
    </>
  );
}
const actionCreators = {
  getTotalUnavailableCount: menuManagementAction.getTotalUnavailableCount,
  updateModifierStatus: menuManagementAction.updateModifierStatus,
};

export default compose(connect(undefined, actionCreators))(SVTModifiersItems);
