import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import printerIcon from '../../images/bp-icon-printer.svg';
import SVTComponentToPrint from './SVTComponentToPrint';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { orderManagementActions } from '../../action/SVTOrderManagementAction';

function SVTPrintReceipt(props) {
  const partner = props.partnerList?.partners?.filter((partener) => {
    return partener.partner_name === props.details?.partner_name;
  });
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const getPrintBtn = (props) => {
    return props.show !== false ? (
      <img
        className='img-responsive print-button-border'
        alt=''
        src={printerIcon}
        type='button'
        onClick={handlePrint}
      />
    ) : null;
  };
  if (props.showPrintOption) {
    handlePrint();
    props.setAutoPrintOption(false);
  }
  return (
    <>
      {props?.selectedOrderDetails?.order_items?.length > 0 ? (
        <>
          <div className='component-to-print'>
            <SVTComponentToPrint
              ref={componentRef}
              details={props.selectedOrderDetails}
              partner={partner}
              storeDetails={props?.storeDetails}
            />
          </div>
          {getPrintBtn(props)}
        </>
      ) : null}
    </>
  );
}

export function mapState(state) {
  const { orderManagementRelated } = state;
  const { partnerList, storeDetails, showPrintOption, selectedOrderDetails } =
    orderManagementRelated;
  return { partnerList, storeDetails, showPrintOption, selectedOrderDetails };
}
const actionCreators = {
  setAutoPrintOption: orderManagementActions.setAutoPrintOption,
};

export default compose(
  withRouter,
  connect(mapState, actionCreators)
)(SVTPrintReceipt);
