import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { authActions } from '../../action/SVTAuthActions';
import { apiUrl } from '../../url/apiUrl';
import firebase from '../../services/SVTFirebaseService';
import { getMessaging, getToken } from 'firebase/messaging';
import { browserName, osName, deviceType } from 'react-device-detect';
import { orderManagementActions } from '../../action/SVTOrderManagementAction';
import { log, LogType } from '../../helper/common';

const waitFor = async (callback) => Promise.resolve(callback());

class SVTAuthCheck extends Component {
  constructor(props) {
    super(props);
  }

  logout = async () => {
    const devicetSatus = await this.props.removeDeviceToken(
      this.props.storeDeviceToken?.device_token
        ? this.props.storeDeviceToken?.device_token
        : this.props.storeDeviceToken
    );
    if (devicetSatus === 'success' || devicetSatus === 'fail') {
      this.props.userLogout(this.props.sessionData.refresh_token);
      sessionStorage.setItem('accessToken', '');
      this.props.clearAllAuthInfo();
    }
  };

  async componentDidMount() {
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get('code');
    const retry = queryParams.get('retry');
    if (code) {
      const authRes = retry? await this.props.callAuth(code, true): await this.props.callAuth(code, false);
      if (authRes.success) {
        localStorage.userLoggedIn = true;
        await waitFor(() => {
          this.props.storeAccessToken(this.props.sessionData.id_token);
          sessionStorage.setItem(
            'accessToken',
            this.props.sessionData.id_token
          );
          sessionStorage.setItem(
            'refreshToken',
            this.props.sessionData.refresh_token
          );
        });
        const messaging = getMessaging(firebase);
        getToken(messaging)
          .then(async (data) => {
            log('Firebase Initiation Success', data, LogType.INFO);
            if (!!data) {
              console.log('Token is', data);
              await waitFor(() => this.props.storeDeviceToken(data));
              const payload = {
                device_os: osName,
                device_type: deviceType,
                device_name: browserName,
                device_token: data,
              };

              this.props.addDeviceToken(payload);
              this.props.history.push('/');
              log('Login flow Success', data, LogType.INFO);
            } else {
              log('Firebase Initiation Failed', data, LogType.ERROR);
              this.logout();
            }
          })
          .catch((err) => {
            log('Firebase Initiation Failed', err, LogType.ERROR);
            console.log('Error is :--', err);
            this.logout();
          });
      }
    } else if (this.isEmptyObj(this.props.userData)) {
      if (this.props?.showErrorPage) {
        if (this.props?.showErrorPage === false) {
          this.props.savePath(this.props.location.pathname);
          window.location.href = apiUrl.loginUrl;
        }
      } else {
        this.props.savePath(this.props.location.pathname);
        window.location.href = apiUrl.loginUrl;
      }
    }
  }
  isEmptyObj(value) {
    return (
      this.props.userData &&
      Object.keys(this.props.userData).length === 0 &&
      this.props.userData.constructor === Object
    );
  }
  render() {
    if (this.props.showErrorPage) {
      return this.props.children;
    } else {
      return <></>;
    }
  }
}

function mapState(state) {
  const { authInfo, orderManagementRelated, errorPageRelated } = state;
  const { showErrorPage } = errorPageRelated;
  const { sessionData, redirectUrl, userData } = authInfo;
  const { storeDeviceToken } = orderManagementRelated;
  return {
    showErrorPage,
    sessionData,
    redirectUrl,
    userData,
    storeDeviceToken,
  };
}

const actionCreators = {
  callAuth: authActions.callAuth,
  savePath: authActions.savePath,
  userLogout: authActions.userLogout,
  storeAccessToken: orderManagementActions.storeAccessToken,
  storeDeviceToken: orderManagementActions.storeDeviceToken,
  addDeviceToken: orderManagementActions.addDeviceToken,
  removeDeviceToken: orderManagementActions.removeDeviceToken,
  clearAllAuthInfo: orderManagementActions.clearAllAuthInfo,
};

export default compose(
  withRouter,
  connect(mapState, actionCreators)
)(SVTAuthCheck);
