import { useState } from 'react';
import info from '../../images/info.svg';
import cross from '../../images/cross_icon.svg';
import { Row, Col, Container } from 'react-bootstrap';

function SVTOrderAcceptPopup(props) {
  const [show, setShow] = useState(false);
  const { type, orderId } = props;
  return (
    <>
      {type === 'accepted' && (
        <Container
          fluid
          className={
            'accept-order-info-popup position-absolute ' +
            (show ? '' : 'd-none')
          }
          style={{ maxWidth: '92%' }}
        >
          <Row>
            <Col md={10}>
              <img src={info} alt='info icon' />
              <span className='ps-2'>
                Order #<strong>{orderId}</strong> has been accepted.
                <a className='order-accept-popup' href='#'>
                  Tab To View
                </a>
              </span>
            </Col>
            <Col md={2}>
              <img
                src={cross}
                alt='cross'
                className='float-end'
                onClick={() => setShow(false)}
              />
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}
export default SVTOrderAcceptPopup;
