import { apiUrl } from '../url/apiUrl';
import { handleResponse } from '../helper/common';
import { store } from '../helper/store';
export const menuManagementService = {
  getAllProductsList,
  getCategoriesList,
  getAllProductsListFromSearchQuery,
  getAllModifiersListFromSearchQuery,
  updateProductStatus,
  updateModifierStatus,
  getTotalUnavailableCount,
  getTotalUnavailableItemList,
  getRecentTabProductList,
  getAllModifiersList,
};
const contentType = 'application/json';
function getAllProductsList() {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': contentType,
      code: store.getState().authInfo?.accessToken,
      token:
        store.getState().orderManagementRelated?.storeDeviceToken?.device_token,
    },
  };
  const url = `${apiUrl.url}/products_list?category_key=single-tabletall-products`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}
function getAllProductsListFromSearchQuery(searchQuery = '') {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': contentType,
      code: store.getState().authInfo?.accessToken,
      token:
        store.getState().orderManagementRelated?.storeDeviceToken?.device_token,
    },
  };
  // Handling "&" edge case for query string
  searchQuery = searchQuery.replace('&', '%26');
  const url = `${apiUrl.url}/search_menu?product_name=${searchQuery}`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}
function getAllModifiersListFromSearchQuery(searchQuery = '') {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': contentType,
      code: store.getState().authInfo?.accessToken,
      token:
        store.getState().orderManagementRelated?.storeDeviceToken?.device_token,
    },
  };
  // Handling "&" edge case for query string
  searchQuery = searchQuery.replace('&', '%26');
  const url = `${apiUrl.url}/store_menu_all_modifiers?search=${searchQuery}`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      console.log('Menu search', data);
      return data;
    });
}

function updateProductStatus(payload) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': contentType,
      code: store.getState().authInfo?.accessToken,
      token:
        store.getState().orderManagementRelated?.storeDeviceToken?.device_token,
    },
    body: JSON.stringify(payload),
  };
  const url = `${apiUrl.url}/update_product_status`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}
function getCategoriesList() {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': contentType,
      code: store.getState().authInfo?.accessToken,
      token:
        store.getState().orderManagementRelated?.storeDeviceToken?.device_token,
    },
  };
  const url = `${apiUrl.url}/categories_list`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}
function updateModifierStatus(payload) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': contentType,
      code: store.getState().authInfo?.accessToken,
      token:
        store.getState().orderManagementRelated?.storeDeviceToken?.device_token,
    },
    body: JSON.stringify(payload),
  };
  const url = `${apiUrl.url}/store_modifier_update`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function getTotalUnavailableCount() {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': contentType,
      code: store.getState().authInfo?.accessToken,
      token:
        store.getState().orderManagementRelated?.storeDeviceToken?.device_token,
    },
  };
  const url = `${apiUrl.url}/unavailable_count`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}
function getTotalUnavailableItemList() {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': contentType,
      code: store.getState().authInfo?.accessToken,
      token:
        store.getState().orderManagementRelated?.storeDeviceToken?.device_token,
    },
  };
  const url = `${apiUrl.url}/unavailable_products`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}
function getRecentTabProductList() {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': contentType,
      code: store.getState().authInfo?.accessToken,
      token:
        store.getState().orderManagementRelated?.storeDeviceToken?.device_token,
    },
  };
  const url = `${apiUrl.url}/recently_updated`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}
function getAllModifiersList() {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': contentType,
      code: store.getState().authInfo?.accessToken,
      token:
        store.getState().orderManagementRelated?.storeDeviceToken?.device_token,
    },
  };
  const url = `${apiUrl.url}/store_menu_all_modifiers`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}
