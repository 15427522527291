import React, { useState } from 'react';
import uber_eats from '../../images/UberEats.svg';
import menulog from '../../images/logos/deliveroo/deliverooSVG.svg';
import up_arrow from '../../images/up_arrow.svg';
import phone from '../../images/phone.svg';

function SVTAccordionTabComponent({
  OrderDetails,
  order,
  index,
  setShouldShowEditOrder,
  shouldShowEditOrder,
  id,
  foodQuantity,
  setFoodQuantity,
  resetCurrentItem,
  reset,
  resetById,
}) {
  const [rotation, setRotation] = useState(0);
  const [show, setShow] = useState(true);

  const getRotation = () => {
    return index === 0 ? rotation : rotation + 180;
  };

  const rotateArrow = () => {
    let newRotation = rotation + 180;
    if (newRotation >= 360) {
      newRotation = newRotation - 360;
    }
    setRotation(newRotation);
    setShow(!show);
  };

  const getShow = () => {
    return index === 0 ? show : !show;
  };

  const getOrderDetails = () => {
    return order.partner_name === 'Deliveroo' ? (
      <div className='Accordion_Style' id={order?.partner_order_id}>
        <OrderDetails
          image={menulog}
          up_arrow={up_arrow}
          rotation={getRotation()}
          rotateArrow={rotateArrow}
          show={getShow()}
          phone={phone}
          order={order}
          setShouldShowEditOrder={setShouldShowEditOrder}
          shouldShowEditOrder={shouldShowEditOrder}
          id={id}
          foodQuantity={foodQuantity}
          setFoodQuantity={setFoodQuantity}
          resetCurrentItem={resetCurrentItem}
          reset={reset}
          resetById={resetById}
        />
      </div>
    ) : (
      <></>
    );
  };

  return (
    <>
      {order.partner_name === 'Uber Eats' ? (
        <div className='Accordion_Style' id={order?.partner_order_id}>
          <OrderDetails
            image={uber_eats}
            up_arrow={up_arrow}
            rotation={getRotation()}
            rotateArrow={rotateArrow}
            show={getShow()}
            phone={phone}
            order={order}
            setShouldShowEditOrder={setShouldShowEditOrder}
            shouldShowEditOrder={shouldShowEditOrder}
            key={id}
            id={id}
            foodQuantity={foodQuantity}
            setFoodQuantity={setFoodQuantity}
            resetCurrentItem={resetCurrentItem}
            reset={reset}
            resetById={resetById}
          />
        </div>
      ) : (
        <>{getOrderDetails()}</>
      )}
    </>
  );
}

export default SVTAccordionTabComponent;
