import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Modal } from 'react-bootstrap';
import online_indicator from '../../images/online_indicator.svg';
import arrow_up from '../../images/arrow_up.svg';
import arrow_down from '../../images/arrow_down.svg';
import store_picture from '../../images/StorePicture.png';
import risk_icon from '../../images/Risk.svg';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { orderManagementActions } from '../../action/SVTOrderManagementAction';
import { menuManagementAction } from '../../action/SVTMenuManagementAction';
import cross_icon from '../../images/cross_icon.svg';
import offline_indicator from '../../images/offline_indicator.svg';
import SVTHeaderComponent from './SVTHeaderComponent';
import { authActions } from '../../action/SVTAuthActions';
import SVTMainComponent from './SVTMainComponent';
import phone from '../../images/phone.svg';
import envelope from '../../images/Envelope.png';
import uberEats from '../../images/UberEats.svg';
import crossVector from '../../images/crossCircle.svg';
import warningIcon from '../../images/warningIcon.svg';
import menuLog from '../../images/logos/deliveroo/deliverooSVG.svg';
import checkmarkCircle from '../../images/checkmarkCircle.svg';
import viewModifierArrow from '../../images/viewModifierArrow.svg';
import viewRefreshIcon from '../../images/refresh.svg';
import aggregator_offline_indicator from '../../images/aggregator_offline_indicator.svg';
import DummyMenuItemImg from '../../images/DummyMenuItemImg.svg';
import { store } from '../../helper/store';
import { refreshToken } from '../../helper/common';
import { StoreClosureReasonsEnum, formatReasonString } from '../../helper/closureReason';

const getImageforPauseOrders = (showPauseOrders) => {
  return showPauseOrders ? arrow_up : arrow_down;
};
const getClassNameforAggregator = (storesHavingInvalidAggregatorStatus) => {
  return storesHavingInvalidAggregatorStatus?.length > 0
    ? 'aggr-problem-block'
    : 'aggr-normal-block';
};
const getOrderStatusAndImage = (item) => {
  return (
    item?.aggregators?.length > 0 &&
    item?.aggregators?.map((itm) => {
      return (
        <>
          <div className='mr-20 d-flex'>
            <div
              className={
                itm?.status !== 'active'
                  ? 'inactiveAgreegatorNames'
                  : 'activeAgreegatorNames'
              }
            >
              <img
                className='partner_logo'
                src={itm?.partner_name === 'Uber Eats' ? uberEats : menuLog}
                alt='Email Img'
              />
              <div className='agreegatoNotAvailable'>
                <img
                  src={itm?.status !== 'active' ? crossVector : checkmarkCircle}
                  alt='Email Img'
                />
              </div>
            </div>
          </div>
        </>
      );
    })
  );
};

const getAggregatorOfflineOnlineStatus = (
  storeStatus,
  storesHavingInvalidAggregatorStatus
) => {
  return (
    (storeStatus === 'Offline' && 'Offline') ||
    (storesHavingInvalidAggregatorStatus?.length > 0 &&
      'Aggregator(s) Offline') ||
    'Online'
  );
};
const getStoreStatusImage = (
  storeStatus,
  storesHavingInvalidAggregatorStatus
) => {
  return (
    (storeStatus === 'Offline' && offline_indicator) ||
    (storesHavingInvalidAggregatorStatus?.length > 0 &&
      aggregator_offline_indicator) ||
    online_indicator
  );
};

const getofflineMessageNotifications = (props) => {
  return props.allRawNotificationsList.filter((notification) =>
    notification['message'].startsWith('Store Marked Offline At')
  );
};

const getPauseOrderClassName = (cancelReason) => {
  return (
    'shadow-none ' +
    (cancelReason === ''
      ? 'success-btn-disable-order-setting'
      : 'success-btn-order-setting')
  );
};

const getShowMoreImg = (showMore) => {
  return showMore ? arrow_up : arrow_down;
};

function AggresDetailsUpdated({ propsPassed }) {
  return (
    <>
      {propsPassed?.map((item, index) => (
        <>
          <div className='d-flex col-md-12 peb-10 svt-bold-text'>
            <div className='col-md-6 d-flex'>
              <div className='brandImg'>
                <img
                  src={DummyMenuItemImg}
                  alt='Email Img'
                  className='border-setting-brand brandImg'
                />
              </div>
              <div className='ml-20 mt-20'>{item?.store_name}</div>
              {item?.aggregators?.length > 0 &&
                !item?.aggregators.every((i) => i.status === 'active') && (
                  <div className='mt-20 ml-5'>
                    {' '}
                    <img src={warningIcon} alt='Email Img' />
                  </div>
                )}
            </div>
            {getOrderStatusAndImage(item)}
          </div>
        </>
      ))}
    </>
  );
}

async function getStoreOpeningHours(siteId, dispatch) {
  refreshToken(dispatch);

  const endpoint = process.env.REACT_APP_AMP_API_URL;
  const token = store.getState().authInfo?.accessToken;

  const query = `
    query GetPhysicalStoreByInternalSiteID($input: PhysicalStoreWhereInput!) {
      physicalStores(where: $input) {
        edges {
          node {
            id
            internalSiteID
            operatingHours {
              id
              startTime
              endTime
              dayOfWeek
              isAllDay
            }
          }
        }
      }
    }
  `

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  const queryInput = {
    input: {
      internalSiteID: siteId
    }
  };

  async function getOperatingHoursResponse() {
    return fetch(endpoint, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        query: query,
        variables: queryInput,
      }),
    }).then(response => response.json());
  }

  try {
    const response = await getOperatingHoursResponse();
    const physicalStoreEdges = response?.data?.physicalStores?.edges;
    if (physicalStoreEdges.length > 0) {
      const operatingHours = physicalStoreEdges[0]?.node?.operatingHours;
      console.log("Operating hours: ", operatingHours);
      return operatingHours
    }
  } catch (error) {
    console.error('There was a problem with the fetch operation:', error)
  }
  return null;
}

const SettingsHeader = (componentProps) => {
  const {
    props,
    getStoreStatusImage,
    getAggregatorOfflineOnlineStatus,
    storesHavingInvalidAggregatorStatus,
  } = componentProps;

  return (
    <Row>
      <Col md={12}>
        <div className='d-flex justify-content-between border-bottom-order-setting'>
          <div className='order-setting-bold-text'>Settings</div>
          <div className='order-setting-bold-text'>
            Store Status:
            <img
              src={getStoreStatusImage(
                props?.storeDetails?.store_status,
                storesHavingInvalidAggregatorStatus
              )}
              alt='online indicator'
              className='ps-3 pe-2'
            />
            <span className='order-setting-normal-text'>
              {getAggregatorOfflineOnlineStatus(
                props?.storeDetails?.store_status,
                storesHavingInvalidAggregatorStatus
              )}
            </span>
          </div>
        </div>
      </Col>
    </Row>
  );
};

const AboutStore = (componentProps) => {
  const {
    props,
    openHelpPage,
    handleRetryConnection,
    storesHavingInvalidAggregatorStatus,
    storesHavingValidAggregatorStatus,
  } = componentProps;

  const dispatch = useDispatch();

  const [openingHours, setOpeningHours] = useState([]);

  const daysOfWeekOrder = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

  useEffect(() => {
    async function fetchOpeningHours() { // Define async function inside useEffect
      const hours = await getStoreOpeningHours(props.siteId, dispatch);
      if (hours) {
        hours.sort((hours1, hours2) => {
          const daysOfWeekOrder = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
          const dayIndex1 = daysOfWeekOrder.indexOf(hours1.dayOfWeek.toLowerCase());
          const dayIndex2 = daysOfWeekOrder.indexOf(hours2.dayOfWeek.toLowerCase());
          if (dayIndex1 === dayIndex2) {
            const [hour1, minute1] = hours1.startTime.split(":").map(Number);
            const [hour2, minute2] = hours2.startTime.split(":").map(Number);
            return (hour1 * 60 + minute1) - (hour2 * 60 + minute2);
          }
          return dayIndex1 - dayIndex2;
        });
        setOpeningHours(hours); // Update the state
      }
    }

    fetchOpeningHours();
  }, []);

  return (
    <div className='order-setting-spacing-top'>
      <div className=''>
        <Row>
          <Col
            md={12}
            className='order-setting-bold-text order-setting-about-spacing'
          >
            About
          </Col>
        </Row>
        <Row>
          <Col md={3} style={{ maxWidth: '250px' }} className='ml-66'>
            <img
              src={store_picture}
              alt='Store Img'
              width='216px'
              height='128px'
            />
            <div className='d-flex flex-column setting-about-row-gap'>
              <div className='order-setting-bold-text'>
                {props?.storeDetails?.store_name}
              </div>
              <div>
                <p className='mb-0 order-setting-store-details-text'>
                  {props?.storeDetails?.store_address}
                </p>
              </div>
              <div className='mb-0 order-setting-store-details-text'>
                <img
                  src={phone}
                  alt='Phone Img'
                  width='19.9px'
                  height='19.9px'
                />
                <span className='help-image-margin-right'>
                  {props?.storeDetails?.store_phone}
                </span>
              </div>
              <div className='mb-0 order-setting-store-details-email-text'>
                <img
                  src={envelope}
                  alt='Email Img'
                  width='19.9px'
                  height='19.9px'
                />
                <span className='help-image-margin-right'>
                  {props?.storeDetails?.store_email}
                </span>
              </div>
            </div>
          </Col>
          {openingHours.length > 0 && (
            <Col>
              <div>
                <div className="order-setting-bold-text">
                  Opening Hours
                </div>
                {Array.isArray(openingHours) && openingHours.map((hour, index) => (
                  <Row key={hour.id}>
                    <Col md={6}>
                      {hour.dayOfWeek.charAt(0).toUpperCase() + hour.dayOfWeek.slice(1)}
                    </Col>
                    {hour.isAllDay ?
                      <Col md={6}>
                        All Day
                      </Col> :
                      <Col md={6}>
                        {hour.startTime} - {hour.endTime}
                      </Col>
                    }
                  </Row>
                ))}
              </div>
            </Col>
          )}
          <Col
            md={9}
            className='order-setting-store-spacing aggregator-list px-0'
          >
            <div className='d-flex col-md-12 border-bottom p-20 svt-bold-text'>
              <div className='col-md-6'>Brands</div>
              <div className='col-md-6'>Partnered Aggregator Connection</div>
            </div>
            <div className='aggr-list-height'>
              <div
                className={getClassNameforAggregator(
                  storesHavingInvalidAggregatorStatus
                )}
              >
                <AggresDetailsUpdated
                  propsPassed={storesHavingValidAggregatorStatus}
                />
                {storesHavingInvalidAggregatorStatus?.length > 0 && (
                  <div className='custom-row-flex'>
                    <AggresDetailsUpdated
                      propsPassed={storesHavingInvalidAggregatorStatus}
                    />
                    <div
                      className='green-txt custom-column-flex cursor-pointer mb-18'
                      onClick={() => openHelpPage()}
                    >
                      <div className='open-help-section-text d-flex align-self-end'>
                        Open Help Section
                        <img
                          height='24px'
                          src={viewModifierArrow}
                          alt='viewModifierArrow'
                          className='cursor-pointer ml-10'
                        />
                      </div>
                    </div>

                    <div
                      className='green-txt custom-column-flex cursor-pointer mb-18'
                      onClick={() => handleRetryConnection()}
                    >
                      <div className='open-retry-section-text d-flex align-self-start'>
                        Retry Connection
                        <img
                          height='24px'
                          src={viewRefreshIcon}
                          alt='viewModifierArrow'
                          className='cursor-pointer ml-10'
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const PauseOrdersSection = (componentProps) => {
  const {
    props,
    showPauseOrders,
    setShowPauseOrders,
    handleCancelReason,
    cancelReason,
    handleCloseStore,
    resumeOrder,
  } = componentProps;

  return (
    <Card className='order-setting-pause-border'>
      <Card.Header
        style={{ margin: '-4px -16px' }}
        onClick={() => setShowPauseOrders(!showPauseOrders)}
        className='cursor-pointer'
      >
        <img
          src={getImageforPauseOrders(showPauseOrders)}
          alt='arrow'
          className='mr-10'
        />
        <span className='order-setting-chevron-header'>
          Temporarily Pause Orders
        </span>
      </Card.Header>
      {showPauseOrders && (
        <Card.Body
          className='order-setting-pause-order-card-body'
          style={{ margin: '0px -16px' }}
        >
          {props?.storeDetails?.store_status === 'Online' && (
            <div className='setting-accordion-card'>
              <Row className='pb-4'>
                <Col sm={1} style={{ width: '24px' }}>
                  <img src={risk_icon} alt='danger-icon' />
                </Col>
                <Col md={8}>
                  <div className='setting-accordion-more-txt'>
                    If orders are paused, the store will no longer receive any
                    new orders until manually resumed.{' '}
                  </div>
                  <div className='setting-accordion-more-txt'>
                    You can still finish already existing orders.{' '}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col
                  md={12}
                  className='ms-1 ps-5 setting-accordion-more-txt text-gray-order-setting'
                >
                  Reason For Pausing:
                </Col>
              </Row>
              <Row className='mt-3'>
                <Col md={3} className='col-width'>
                  <input
                    className='form-check-input shadow-none'
                    type='radio'
                    name='flexRadioDefault'
                    id='shortageOfStaff'
                    value={StoreClosureReasonsEnum.ShortageOfStaff}
                    onChange={handleCancelReason}
                  />
                  <label
                    className='form-check-label ms-3'
                    htmlFor='shortageOfStaff'
                  >
                    {formatReasonString(StoreClosureReasonsEnum.ShortageOfStaff)}
                  </label>
                </Col>
                <Col md={3} className='col-width'>
                  <input
                    className='form-check-input shadow-none'
                    type='radio'
                    name='flexRadioDefault'
                    id='storeClosingEarly'
                    value={StoreClosureReasonsEnum.StoreClosingEarly}
                    onChange={handleCancelReason}
                  />
                  <label
                    className='form-check-label ms-3'
                    htmlFor='storeClosingEarly'
                  >
                    {formatReasonString(StoreClosureReasonsEnum.StoreClosingEarly)}
                  </label>
                </Col>
                <Col md={6} className='col-other-first'>
                  <input
                    className='form-check-input shadow-none'
                    type='radio'
                    name='flexRadioDefault'
                    id='others'
                    value={StoreClosureReasonsEnum.Other}
                    onChange={handleCancelReason}
                  />
                  <label className='form-check-label ms-3' htmlFor='others'>
                  {formatReasonString(StoreClosureReasonsEnum.Other)}
                  </label>
                </Col>
              </Row>
              <Row className='mt-3 remove-margin-top'>
                <Col md={3} className='col-width'>
                  <input
                    className='form-check-input shadow-none'
                    type='radio'
                    name='flexRadioDefault'
                    id='emergencyIssue'
                    value={StoreClosureReasonsEnum.EmergencyIssue}
                    onChange={handleCancelReason}
                  />
                  <label
                    className='form-check-label ms-3'
                    htmlFor='emergencyIssue'
                  >
                    {formatReasonString(StoreClosureReasonsEnum.EmergencyIssue)}
                  </label>
                </Col>
                <Col md={3} className='col-width'>
                  <input
                    className='form-check-input shadow-none'
                    type='radio'
                    name='flexRadioDefault'
                    id='deviceIssue'
                    value={StoreClosureReasonsEnum.HardwareIssue}
                    onChange={handleCancelReason}
                  />
                  <label
                    className='form-check-label ms-3'
                    htmlFor='deviceIssue'
                  >
                    {formatReasonString(StoreClosureReasonsEnum.HardwareIssue)}
                  </label>
                </Col>
                <Col className='col-other-last'>
                  <input
                    className='form-check-input shadow-none'
                    type='radio'
                    name='flexRadioDefault'
                    id='others'
                    value={StoreClosureReasonsEnum.Other}
                    onChange={handleCancelReason}
                  />
                  <label className='form-check-label ms-3' htmlFor='others'>
                    {formatReasonString(StoreClosureReasonsEnum.Other)}
                  </label>
                </Col>
              </Row>
              <Row className='pause-order-margin-top'>
                <Col md={12}>
                  <button
                    className={getPauseOrderClassName(cancelReason)}
                    disabled={cancelReason === ''}
                    onClick={handleCloseStore}
                  >
                    Pause Order
                  </button>
                </Col>
              </Row>
            </div>
          )}
          {props?.storeDetails?.store_status === 'Offline' && (
            <div className='setting-accordion-card'>
              <button
                className='success-btn-modal shadow-none'
                onClick={resumeOrder}
              >
                Resume Orders
              </button>
            </div>
          )}
        </Card.Body>
      )}
    </Card>
  );
};

const MoreSection = (componentProps) => {
  const { showMore, setShowMore, setLogoutPopup } = componentProps;

  return (
    <Card className='order-setting-log-out-card'>
      <Card.Header
        style={{ margin: '-4px -16px' }}
        onClick={() => setShowMore(!showMore)}
        className='cursor-pointer'
      >
        <img src={getShowMoreImg(showMore)} alt='arrow' className='mr-10' />
        <span className='order-setting-chevron-header'>More</span>
      </Card.Header>
      {showMore && (
        <Card.Body
          style={{
            margin: '0px -16px',
            paddingBottom: '4px',
          }}
        >
          <div className='d-flex flex-column setting-accordion-card'>
            <div>
              <img src={risk_icon} alt='danger-icon' />
              <span className='setting-accordion-more-txt'>
                If logged out, you will no longer be able to see any incoming or
                received orders.
              </span>
            </div>
            <div>
              <button
                className='green-outline-btn shadow-none mt-4'
                onClick={() => setLogoutPopup(true)}
              >
                Log Out
              </button>
            </div>
          </div>
        </Card.Body>
      )}
    </Card>
  );
};

function SVTOrderSettingPage(props) {
  const [showPauseIncomingOrdersPopup, setPauseIncomingOrdersPopup] = useState({
    shouldShow: false,
    reason: '',
  });
  const [
    showPauseIncomingOrdersOthersPopup,
    setPauseIncomingOrdersOthersPopup,
  ] = useState(false);
  const [showLogoutPopup, setLogoutPopup] = useState(false);
  const [cancelReason, setCancelReason] = useState('');

  const [showPauseOrders, setShowPauseOrders] = useState(true);
  const [showMore, setShowMore] = useState(false);

  const [
    storesHavingValidAggregatorStatus,
    setStoresHavingValidAggregatorStatus,
  ] = useState([]);
  const [
    storesHavingInvalidAggregatorStatus,
    setStoresHavingInvalidAggregatorStatus,
  ] = useState([]);

  const storeStatus = props?.storeDetails?.store_status?.toLowerCase();

  const isAggregatorStatusValid = (aggregator) => {
    return (
      (aggregator.status === 'active' && storeStatus === 'online') ||
      (['deactive', 'inactive'].includes(aggregator.status) &&
        storeStatus === 'offline')
    );
  };
  const getStoresHavingValidAggregatorStatus = () => {
    return props?.profileDetails?.stores?.filter((store) => {
      return store.aggregators.every(isAggregatorStatusValid);
    });
  };
  const getStoresHavingInvalidAggregatorStatus = () => {
    return props?.profileDetails?.stores?.filter((store) => {
      return !store.aggregators.every(isAggregatorStatusValid);
    });
  };

  const handleRetryConnection = () => {
    if (storeStatus === 'online') {
      resumeOrder();
    } else {
      props?.updateStoreStatus(
        'Offline',
        props?.storeDetails?.store_pause_reason,
        props?.storeDetails?.store_id
      );
      props.getMyProfileDetails();
    }
  };

  const handleCancelReason = (e) => setCancelReason(e.target.value);

  const handleCloseStore = () => {
    cancelReason === 'Other'
      ? setPauseIncomingOrdersOthersPopup(true)
      : setPauseIncomingOrdersPopup({
          shouldShow: true,
          reason: cancelReason,
        });
  };

  const deactiveAllOfflineNotifications = async () => {
    const offlineMessageNotifications = getofflineMessageNotifications(props);

    const requests = offlineMessageNotifications.map((notification) => {
      return props.updateNotificationStatus({
        id: notification.id,
        status: 'Deactive',
      });
    });

    Promise.all(requests).then(() => {
      props.getAllNotificationsList();
    });
  };
  const resumeOrder = async () => {
    await deactiveAllOfflineNotifications();
    props?.updateStoreStatus('Online', '', props?.storeDetails?.store_id);
    const headerMsgPayload = {
      show: false,
      type: '',
      msg: '',
      orderId: '',
    };
    props.getMyProfileDetails();
    props.setShowTopMsg(headerMsgPayload);
  };

  const logoutSuccess = async () => {
    setLogoutPopup(false);
    const devicetSatus = await props.removeDeviceToken(
      props.storeDeviceToken?.device_token
        ? props.storeDeviceToken?.device_token
        : props.storeDeviceToken
    );
    if (devicetSatus === 'success' || devicetSatus === 'fail') {
      props.userLogout(props.sessionData.refresh_token);
      sessionStorage.setItem('accessToken', '');
      props.clearAllAuthInfo();
      props.clearAllSelections();
      props.clearAllMenuSelections();
      props.saveCancelledOrderNewNotification([]);
      props.saveIncomingOrderNewNotification([]);
    }
  };
  useEffect(() => {
    props.setCurrentPath(props.location.pathname);
    props.getMyProfileDetails();
  }, []);

  useEffect(() => {
    setStoresHavingValidAggregatorStatus(
      getStoresHavingValidAggregatorStatus()
    );
    setStoresHavingInvalidAggregatorStatus(
      getStoresHavingInvalidAggregatorStatus()
    );
  }, [props?.profileDetails?.stores]);

  const openHelpPage = () => {
    props.history.push('/order-help');
  };

  if (props?.showErrorPage) {
    return <SVTMainComponent />;
  }

  return (
    <>
      <PauseIncomingOrdersPopup
        show={showPauseIncomingOrdersPopup}
        onHide={setPauseIncomingOrdersPopup}
        setCancelReason={setCancelReason}
        {...props}
      />
      <PauseIncomingOrdersOthersPopup
        show={showPauseIncomingOrdersOthersPopup}
        onHide={setPauseIncomingOrdersOthersPopup}
        setCancelReason={setCancelReason}
        {...props}
      />
      <LogoutPopup
        show={showLogoutPopup}
        onHide={setLogoutPopup}
        {...props}
        logoutSuccess={logoutSuccess}
      />
      <SVTHeaderComponent />
      <div className='container-fluid padding-left-right-order-setting'>
        <div className='order-setting container-order-setting'>
          <SettingsHeader
            props={props}
            storesHavingInvalidAggregatorStatus={
              storesHavingInvalidAggregatorStatus
            }
            getStoreStatusImage={getStoreStatusImage}
            getAggregatorOfflineOnlineStatus={getAggregatorOfflineOnlineStatus}
          />

          <div className='scrollbar-order-setting'>
            <AboutStore
              props={props}
              storesHavingInvalidAggregatorStatus={
                storesHavingInvalidAggregatorStatus
              }
              storesHavingValidAggregatorStatus={
                storesHavingValidAggregatorStatus
              }
              openHelpPage={openHelpPage}
              handleRetryConnection={handleRetryConnection}
            />
            <hr className='order-card-hr' />
            <Row>
              <div className='align-self-start'>
                <div className='setting-accordion'>
                  <PauseOrdersSection
                    props={props}
                    showPauseOrders={showPauseOrders}
                    setShowPauseOrders={setShowPauseOrders}
                    handleCancelReason={handleCancelReason}
                    cancelReason={cancelReason}
                    handleCloseStore={handleCloseStore}
                    resumeOrder={resumeOrder}
                  />
                  <hr className='order-card-hr' />
                  <MoreSection
                    showMore={showMore}
                    setShowMore={setShowMore}
                    setLogoutPopup={setLogoutPopup}
                  />
                  <hr className='mb-0 order-card-hr' />
                </div>
              </div>
            </Row>
          </div>
          <div className='order-setting-footer order-setting-footer-margin'>
            <div className='m-0 p-0'>
              <button
                type='button'
                className='green-outline-btn shadow-none float-end'
                onClick={() => props?.history?.goBack()}
              >
                Go Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function PauseIncomingOrdersPopup(props) {
  const { show, onHide, setCancelReason } = props;
  const pauseOrder = () => {
    props?.updateStoreStatus(
      'Offline',
      show?.reason,
      props?.storeDetails?.store_id
    );
    onHide(false);
    const headerMsgPayload = {
      show: true,
      type: 'store',
      msg: 'All incoming orders to the store are manually paused.',
      orderId: '',
    };
    props.setShowTopMsg(headerMsgPayload);
    setCancelReason('');
  };
  return (
    <Modal
      show={show.shouldShow}
      onHide={() =>
        onHide({
          shouldShow: false,
          reason: '',
        })
      }
      size='md'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header style={{ padding: '20px 20px 0px 20px' }}>
        <Modal.Title id='contained-modal-title-vcenter'>
          <div className='d-flex'>
            <img src={risk_icon} alt='Risk' />
            <p className='m-0 p-0 normal-font fw-bold ps-2'>
              Pause Incoming Orders
            </p>
          </div>
        </Modal.Title>
        <img
          src={cross_icon}
          height='21.98px'
          width='21.98px'
          onClick={() => onHide(false)}
          alt='cross icon'
        />
      </Modal.Header>
      <Modal.Body className='m-0' style={{ padding: '0px 20px 0px 20px' }}>
        <Row>
          <Col sm={12} className='m-0'>
            <hr className='m-0 contact-supp-hr-setting-other' />
          </Col>
          <Col md={12} className='pe-2'>
            The store will no longer receive any new orders until manually
            resumed.
          </Col>
        </Row>
        <Row>
          <Col sm={12} className='pt-4 pb-3'>
            Are you sure you want to pause incoming orders?
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <button
          type='button'
          className='green-outline-btn shadow-none btn-cancel-text-order-setting-success'
          onClick={() => onHide(false)}
        >
          Cancel
        </button>
        <button
          type='button'
          className='success-btn-modal shadow-none ms-3 btn-pause-text-order-setting-success'
          onClick={pauseOrder}
        >
          Pause Orders
        </button>
      </Modal.Footer>
    </Modal>
  );
}

function PauseIncomingOrdersOthersPopup(props) {
  const { show, onHide, setCancelReason } = props;
  const [otherReason, setReason] = useState('');
  const pauseOrder = () => {
    props?.updateStoreStatus('Offline', 'Other', props?.storeDetails?.store_id);
    onHide(false);
    const headerMsgPayload = {
      show: true,
      type: 'store',
      msg: 'All incoming orders to the store are manually paused.',
      orderId: '',
    };
    props.setShowTopMsg(headerMsgPayload);
    setCancelReason('');
  };
  return (
    <Modal
      show={show}
      onHide={onHide}
      size='md'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header style={{ padding: '20px 20px 0px 20px' }}>
        <Modal.Title id='contained-modal-title-vcenter'>
          <div className='d-flex'>
            <p className='m-0 px-0 normal-font fw-bold '>Other</p>
          </div>
        </Modal.Title>
        <img
          src={cross_icon}
          height='21.98px'
          width='21.98px'
          onClick={() => onHide(false)}
          alt='cross icon'
        />
      </Modal.Header>
      <Modal.Body className='m-0' style={{ padding: '0px 20px 0px 20px' }}>
        <Row>
          <Col sm={12} className='m-0'>
            <hr className='m-0 contact-supp-hr-setting-others' />
          </Col>
          <Col md={12}>
            <textarea
              className='pause-incoming-orders-others-popup'
              id='exampleFormControlTextarea1'
              rows='5'
              placeholder='Reason For Pausing'
              value={otherReason}
              onChange={(e) => setReason(e.target.value)}
            ></textarea>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer style={{ padding: '15px 20px 20px 20px' }}>
        <button
          type='button'
          className='green-outline-btn shadow-none btn-cancel-text-order-setting'
          onClick={() => onHide(false)}
        >
          Cancel
        </button>
        <button
          type='button'
          className={
            ' shadow-none ms-3 ' +
            (otherReason === ''
              ? 'success-btn-disable btn-pause-text-order-setting'
              : 'success-btn-modal btn-pause-text-order-setting-success')
          }
          onClick={pauseOrder}
          disabled={otherReason === ''}
        >
          Pause Orders
        </button>
      </Modal.Footer>
    </Modal>
  );
}

function LogoutPopup(props) {
  const { show, onHide } = props;
  return (
    <Modal
      show={show}
      onHide={onHide}
      size='md'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <Modal.Title id='contained-modal-title-vcenter'>
          <div className='d-flex'>
            <img src={risk_icon} alt='Risk' />
            <p className='m-0 p-0 normal-font fw-bold ps-2'>Log Out</p>
          </div>
        </Modal.Title>
        <img
          src={cross_icon}
          height='21.98px'
          width='21.98px'
          onClick={() => onHide(false)}
          alt='cross icon'
        />
      </Modal.Header>
      <Modal.Body className='p-0 m-0 ps-3 pe-3'>
        <Row>
          <Col sm={12} className='m-0 pb-3'>
            <hr className='m-0 contact-supp-hr' />
          </Col>
          <Col md={12}>
            Logging out will hide all incoming orders. All orders received will
            no longer be visible.
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <button
          type='button'
          className='green-outline-btn shadow-none'
          onClick={() => onHide(false)}
        >
          Cancel
        </button>
        <button
          type='button'
          className={' shadow-none ms-3 success-btn-modal'}
          onClick={() => props.logoutSuccess()}
        >
          Log out
        </button>
      </Modal.Footer>
    </Modal>
  );
}

function mapState(state) {
  const { orderManagementRelated, authInfo, errorPageRelated } = state;
  const {
    storeDetails,
    storeDeviceToken,
    profileDetails,
    allRawNotificationsList,
  } = orderManagementRelated;
  const { showErrorPage } = errorPageRelated;
  const { sessionData } = authInfo;
  return {
    storeDetails,
    sessionData,
    storeDeviceToken,
    showErrorPage,
    profileDetails,
    allRawNotificationsList,
  };
}

const actionCreators = {
  updateStoreStatus: orderManagementActions.updateStoreStatus,
  setShowTopMsg: orderManagementActions.setShowTopMsg,
  clearAllAuthInfo: orderManagementActions.clearAllAuthInfo,
  clearAllSelections: orderManagementActions.clearAllSelections,
  setCurrentPath: orderManagementActions.setCurrentPath,
  userLogout: authActions.userLogout,
  saveIncomingOrderNewNotification:
    orderManagementActions.saveIncomingOrderNewNotification,
  saveCancelledOrderNewNotification:
    orderManagementActions.saveCancelledOrderNewNotification,
  removeDeviceToken: orderManagementActions.removeDeviceToken,
  getMyProfileDetails: orderManagementActions.getMyProfileDetails,
  updateNotificationStatus: orderManagementActions.updateNotificationStatus,
  getAllNotificationsList: orderManagementActions.getAllNotificationsList,
  clearAllMenuSelections: menuManagementAction.clearAllMenuSelections,
};

export default compose(
  withRouter,
  connect(mapState, actionCreators)
)(SVTOrderSettingPage);
