import React from 'react';
import DummyMenuItemImg from '../../images/DummyMenuItemImg.svg';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { menuManagementAction } from '../../action/SVTMenuManagementAction';
import collapseArrow from '../../images/collapseArrow.svg';
import expandArrow from '../../images/expandArrow.svg';
import SVTModifiersItems from './SVTModifiersItems';
import OrderCategoryBarcode from '../../services/OrderCategoryBarcode';
/**
 *
 * @returns {string} Availability Text
 * @param availability
 */
export const getAvailabilityText = (availability) => {
  switch (availability) {
    case true:
      return 'AVAILABLE';
    case false:
      return 'UNAVAILABLE';
    default:
      console.warn(
        'An unexpected variable is passed in getAvailabilityText function'
      );
      return '';
  }
};
/**
 *
 * @param {any} value
 * @returns {Boolean} "testing my new env"
 */
export const isFalsyValue = (value) => {
  if (value == null) {
    return true;
  } else if (typeof value === 'string') {
    return value.trim() === '';
  }
  return false;
};
/**
 *
 * @param {object} item
 * @returns {Array<object>} item
 */
export const getModifiers = (item) => {
  if (item.product_type === 'Bundled Product') {
    if (
      item?.product_variants?.attributes?.BundledProductAttributes?.bundles
        ?.length > 0
    ) {
      return item?.product_variants?.attributes?.BundledProductAttributes
        ?.bundles;
    }
  }
  if (
    item?.product_variants?.attributes?.ComplexProductAttributes?.modifiers
      ?.length > 0
  ) {
    return item?.product_variants?.attributes?.ComplexProductAttributes
      ?.modifiers;
  }
  return undefined;
};
function SVTProductItems(props) {
  const {
    updateProductStatus,
    // updateModifierStatus,
    setCurrentActiveProduct,
    currentProductList,
  } = props;
  const expendProductList = React.useRef([]);
  /**
   *
   * @param {string} productKey
   * @param {boolean} availabilityFlag
   * @param {string} productName
   */
  const availabilityHandler = (productKey, availabilityFlag, productName) => {
    const payload = {
      product_key: productKey,
      status: String(availabilityFlag),
    };
    const setCurrentActiveProductPayload = {
      productName,
      isProductAvailable: availabilityFlag,
      shouldShow: true,
    };
    updateProductStatus(payload);
    setCurrentActiveProduct(setCurrentActiveProductPayload);
  };
  const [productItemList, setProductList] = React.useState([]);
  const regularProduct = 'regular product';
  /**
   * setting shouldShowModifiers and index of each product item
   */
  React.useEffect(() => {
    if (currentProductList?.length >= 0) {
      const tmpProductList = [];
      for (let index = 0; index < currentProductList.length; index++) {
        const tmpItem = Object.assign({}, currentProductList[index]);
        tmpItem['itemIndex'] = index;
        if (tmpItem?.product_type !== regularProduct) {
          if (expendProductList.current.includes(index)) {
            tmpItem['shouldShowModifiers'] = true;
          } else {
            tmpItem['shouldShowModifiers'] = false;
          }
        }

        tmpProductList.push(Object.assign({}, tmpItem));
      }
      setProductList([...tmpProductList]);
    }
  }, [currentProductList]);
  /**
   * This function is responsible for setting shouldShowModifiers flag in productItemList state
   * @param {number} itemIndex
   * @param {boolean} value
   */
  const shouldShowModifiersHandler = (itemIndex, value) => {
    const tmpItemList = Object.assign([], productItemList);
    if (
      tmpItemList[itemIndex] &&
      tmpItemList[itemIndex].shouldShowModifiers !== undefined
    ) {
      tmpItemList[itemIndex].shouldShowModifiers = value;
    }
    if (expendProductList.current.includes(itemIndex)) {
      expendProductList.current = expendProductList.current.filter(
        (i) => i !== itemIndex
      );
    }
    if (value) {
      expendProductList.current.push(itemIndex);
    }
    setProductList(tmpItemList);
  };

  // ! Please do not remove this commented code. It is related to modifiers
  // const modifierAvailabilityHandler = (skuCode, availabilityFlag) => {
  //   const payload = {
  //     sku_code: skuCode,
  //     status: String(availabilityFlag),
  //   };
  //   updateModifierStatus(payload);
  // };
  return (
    <>
      {productItemList &&
        productItemList.map((item) => {
          if (item?.isModifier) {
            return <SVTModifiersItems currentModifiersList={[item]} />;
          }
          const modifiers = getModifiers(item);
          return (
            <React.Fragment key={item.id}>
              <div
                className='dairy-container-wrapper'
                data-test='productItemsMainContainer'
              >
                <main className='dairy-container'>
                  <section className='d-flex align-items-center flex-gap-20 ml-20'>
                    {item.product_type?.toLowerCase() !== regularProduct &&
                      (item.shouldShowModifiers ? (
                        <img
                          src={expandArrow}
                          alt='expandArrow'
                          width='23.88px'
                          height='8.67px'
                          className='cursor-pointer'
                          onClick={() =>
                            shouldShowModifiersHandler(item?.itemIndex, false)
                          }
                          data-test={`expend-modifier-arrow ${item.id}`}
                        />
                      ) : (
                        <img
                          src={collapseArrow}
                          alt='collapseArrow'
                          width='23.88px'
                          height='8.67px'
                          onClick={() =>
                            shouldShowModifiersHandler(item?.itemIndex, true)
                          }
                          className='cursor-pointer'
                          data-test={`collapse-modifier-arrow ${item.id}`}
                        />
                      ))}
                    <img
                      src={
                        isFalsyValue(
                          item?.product_variants?.images?.mainImage?.url
                        ) === true
                          ? DummyMenuItemImg
                          : item?.product_variants?.images?.mainImage?.url
                      }
                      alt='DummyMenuItemImg'
                      width='80px'
                      height='80px'
                      className={
                        item.product_type === regularProduct ? 'ml-40' : ''
                      }
                    />
                    <div className=''>
                      <div className='small_text gray_text text-uppercase letter-spacing-15 font-size-12'>
                        {OrderCategoryBarcode(item)}
                      </div>
                      <div className='text-capitalize'>{item.alter_name}</div>
                    </div>
                  </section>
                  <section className='right-part flex-gap-20'>
                    <div className='d-flex flex-column w-100 float-end'>
                      <div className='small_text gray_text text-uppercase letter-spacing-15 text-end font-size-12'>
                        {getAvailabilityText(item.availability)}
                      </div>
                    </div>
                    <label className='switch'>
                      <input
                        type='checkbox'
                        id='selectID'
                        onChange={(e) =>
                          availabilityHandler(
                            item.product_key,
                            e.target.checked,
                            item.alter_name
                          )
                        }
                        checked={item.availability === true}
                        data-test={`${item.id}`}
                      />
                      <span className='slider round'></span>
                    </label>
                  </section>
                </main>
                <main className='mb-20'>
                  {modifiers &&
                    modifiers.length > 0 &&
                    item.shouldShowModifiers &&
                    modifiers.map((modifier) => {
                      return (
                        <React.Fragment key={modifier?.productKey}>
                          {modifier.productOptions?.length > 0 &&
                            modifier.productOptions?.map((modifierItems) => {
                              return (
                                <React.Fragment>
                                  <div className='modifier-container'>
                                    <section className='modifier-name-text text-capitalize'>
                                      {
                                        modifierItems?.optionProduct
                                          ?.productName['en-GB']
                                      }
                                    </section>
                                    {/* ! Please do not remove this commented code. It is related to modifiers */}
                                    {/* <section className='right-part flex-gap-20'>
																				<div className='d-flex flex-column w-100 float-end'>
																					<div className='small_text gray_text text-uppercase letter-spacing-15 text-end font-size-12'>
																						{getAvailabilityText(
																							modifierItems.availability,
																						)}
																					</div>
																				</div>
																				<label className='switch' data-test='modifierAvailabilitySlider'>
																					<input
																						type='checkbox'
																						id='selectID'
																						onChange={(e) =>
																						  modifierAvailabilityHandler(
																							modifierItems?.optionProduct
																							?.productVariants[0].sku,
																						    e.target.checked
																						  )
																						}
																						checked={
																						  modifierItems.availability === true
																						}
																						data-test={`modifierAvailabilitySlider ${modifierItems?.optionProduct?.productVariants[0].sku}`}
																					/>
																					<span className='slider round' data-test='modifierAvailabilitySlider'></span>
																				</label>
																			</section> */}
                                  </div>
                                  <div className='margin-left-184'>
                                    <div className='w-100 modifier-border'></div>
                                  </div>
                                </React.Fragment>
                              );
                            })}
                        </React.Fragment>
                      );
                    })}
                </main>
              </div>
            </React.Fragment>
          );
        })}
    </>
  );
}

const actionCreators = {
  updateProductStatus: menuManagementAction.updateProductStatus,
  // updateModifierStatus: menuManagementAction.updateModifierStatus,
  setCurrentActiveProduct: menuManagementAction.setCurrentActiveProduct,
};

export default compose(connect(undefined, actionCreators))(SVTProductItems);
