import React from 'react';

import { withRouter } from 'react-router';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { orderManagementActions } from '../../action/SVTOrderManagementAction';

import menu_search from '../../images/menu_search.svg';
import SVTProductItems from './SVTProductItems';
import SVTModifiersItems from './SVTModifiersItems';

class SVTMenuSearchResults extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: props.searchQuery,
      searchresults: [],
    };
  }

  render() {
    return this.props?.allProductsListFromSearchQuery?.length > 0 ||
      this.props?.allModifiersListFromSearchQuery?.length > 0 ? (
      <div className='product-list-container'>
        <section>
          <div className='search-header'>
            Search Results for "{this.props.searchQuery}":
          </div>
          {this.props?.allProductsListFromSearchQuery?.length > 0 && (
            <SVTProductItems
              currentProductList={this.props.allProductsListFromSearchQuery}
              currentActiveTab={this.props.currentActiveTab}
            />
          )}
          {this.props?.allModifiersListFromSearchQuery?.length > 0 && (
            <SVTModifiersItems
              currentModifiersList={this.props.allModifiersListFromSearchQuery}
              currentActiveTab={this.props.currentActiveTab}
            />
          )}
        </section>
      </div> // Just pass  productName list as props to default right side view component later
    ) : (
      <div className='d-flex align-items-center justify-content-center menu-all-available-height'>
        <div className='row'>
          <img src={menu_search} alt='menu search' width='auto' height='96px' />
          <br />
          <div className='text-center black-txt-txt font-size-16 menu-all-item-available-text'>
            No results found
          </div>
          <br />
          <div className='text-center light-grey-txt font-size-16 '>
            Please try another search
          </div>
        </div>
      </div>
    );
  }
}
export function mapState(state) {
  const { menuManagementRelated } = state;
  const { allProductsListFromSearchQuery, allModifiersListFromSearchQuery } =
    menuManagementRelated;
  return {
    allProductsListFromSearchQuery,
    allModifiersListFromSearchQuery,
  };
}
const actionCreators = {
  getSearchQuery: orderManagementActions.getSearchQuery,
};

export default compose(
  withRouter,
  connect(mapState, actionCreators)
)(SVTMenuSearchResults);
