export const sortingConstants = {
  ORDER_ID: 'partner_id',
  PARTNER: 'partner_name',
  DATE: 'created_on',
  ITEMS: 'total_items',
  PRICE: 'total_price',
  STATUS: 'partner_status',
  TIME: 'time',
  ASCENDING: 'asc',
  DESCENDING: 'desc',
  ETA: 'eta_pickup',
  ORDER_TIME: 'partner_created_at',
  POS_ENTRY: 'sale_ingestion',
};
