import React, { useState, useEffect } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SVTOrderHistoryFilterDates from './SVTOrderHistoryFilterDates';
import CheckBox from '../../images/CheckBox.svg';
import emptyCheckBox from '../../images/emptyCheckBox.svg';
import filter_cross from '../../images/filter_cross.svg';

const formatDate = (date) => {
  const monthsList = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  return `${date.getDate()}-${monthsList[date.getMonth()]}`;
};

const formatDay = (date) => {
  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  return days[date.getDay()];
};

const getTmpFilterListforcheckboxHandler = (tmpFilterList, id) => {
  if (tmpFilterList.includes(id)) {
    return tmpFilterList.filter((item) => item !== id);
  } else {
    tmpFilterList.push(id);
    return tmpFilterList;
  }
};

const isCustomRadioFilterActive = (filterList) => {
  for (const element of filterList) {
    if (element.custom) {
      return true;
    }
  }
  return false;
};

const radioBtnHandler = (id, filterList, setFilterList, lastSevenDates) => {
  let tmpFilterList = filterList.slice();
  tmpFilterList = tmpFilterList.filter((item) => item !== 'today');
  tmpFilterList = tmpFilterList.filter((item) => item !== 'lastSevenDays');
  tmpFilterList = tmpFilterList.filter((item) => item.custom === undefined);
  if (id === 'custom') {
    tmpFilterList.push({
      custom: `${lastSevenDates.fromDay},${lastSevenDates.toDay}`,
    });
  } else {
    tmpFilterList.push(id);
  }
  setFilterList(tmpFilterList.slice());
};

const submitHandler = (filterList, lastSevenDates, props) => {
  const tmp = filterList.slice();
  if (isCustomRadioFilterActive(filterList)) {
    for (let index = 0; index < filterList.length; index++) {
      if (filterList[index].custom) {
        tmp[index] = {
          custom: `${lastSevenDates.fromDay},${lastSevenDates.toDay}`,
        };
      }
    }
  }
  props.checkboxSubmitHandler(tmp);
  props.onHide();
};

const getIndexOfDateFrom = (indexOfDateFrom, lastSevenDates, date) => {
  let indexOfDateFromvariable = indexOfDateFrom;
  for (let index = 0; index < lastSevenDates.datesList.length; index++) {
    if (lastSevenDates.datesList[index].Date === date) {
      indexOfDateFromvariable = index;
      break;
    }
  }
  return indexOfDateFromvariable;
};
const getEndFrom = (endFrom, lastSevenDates, date) => {
  let endFromVariable = endFrom;
  for (let index = 0; index < lastSevenDates.datesList.length; index++) {
    if (lastSevenDates?.datesList[index]['Date'] === date) {
      endFromVariable = index;
      break;
    }
  }
  return endFromVariable;
};

const Last7Dates = () => {
  var result = [];
  for (var i = 0; i < 7; i++) {
    var d = new Date();
    d.setDate(d.getDate() - i);
    result.push({ Date: formatDate(d), Day: formatDay(d) });
  }
  result[0]['Day'] = 'Today';
  return result;
};

const handleSetLastDates = (setLastSevenDates) => {
  setLastSevenDates((prevState) => ({
    ...prevState,
    datesList: Last7Dates(),
    fromDay: Last7Dates()[0]['Date'],
    toDay: Last7Dates()[0]['Date'],
    startFrom: 0,
  }));
};

const setDefaults = (setFilterList, setLastDates) => {
  const defaultSelected = [
    'uberEats',
    'deliveroo',
    'completed',
    'cancelledAggregator',
    'lastSevenDays',
    'sale_ingestion',
  ];
  setFilterList(defaultSelected);
  setLastDates();
};

const CheckBoxComponent = (componentProps) => {
  const { filterList, checkBoxId, label, checkboxHandler } = componentProps;

  return (
    <div className='form-group'>
      {filterList.includes(checkBoxId) ? (
        <img
          id={checkBoxId}
          src={CheckBox}
          alt='checked'
          onClick={(e) => checkboxHandler(e.target.id)}
        ></img>
      ) : (
        <img
          id={checkBoxId}
          src={emptyCheckBox}
          alt='un-checked'
          onClick={(e) => checkboxHandler(e.target.id)}
        ></img>
      )}
      <label
        htmlFor={checkBoxId}
        className='form-group-spacing-img'
        onClick={(e) => checkboxHandler(checkBoxId)}
      >
        {label}
      </label>
    </div>
  );
};

const PartnerFilter = (componentProps) => {
  const { filterList, checkboxHandler } = componentProps;

  return (
    <div className='filter-grid' id='partner-filter'>
      <div>Partner</div>
      <CheckBoxComponent
        filterList={filterList}
        checkBoxId={'uberEats'}
        label={'Uber Eats'}
        checkboxHandler={checkboxHandler}
      />
      <CheckBoxComponent
        label={'Deliveroo'}
        checkBoxId={'deliveroo'}
        filterList={filterList}
        checkboxHandler={checkboxHandler}
      />
    </div>
  );
};

const OrderStatusFilter = (componentProps) => {
  const { filterList, checkboxHandler } = componentProps;

  return (
    <div className='filter-grid'>
      <div>Order Status</div>
      <div className='row-grid'>
        <CheckBoxComponent
          checkBoxId={'completed'}
          label={'Completed'}
          filterList={filterList}
          checkboxHandler={checkboxHandler}
        />

        <div className='form-group'>
          <Row>
            <Col md={1}>
              {filterList.includes('sale_ingestion') ? (
                <img
                  src={CheckBox}
                  alt='checked'
                  id='sale_ingestion'
                  onClick={(e) => checkboxHandler(e.target.id)}
                ></img>
              ) : (
                <img
                  src={emptyCheckBox}
                  alt='un-checked'
                  id='sale_ingestion'
                  onClick={(e) => checkboxHandler(e.target.id)}
                ></img>
              )}
            </Col>
            <Col>
              <label
                htmlFor='sale_ingestion'
                className='form-group-spacing-img pos-manual-entry-checkbox'
                onClick={(e) => checkboxHandler('sale_ingestion')}
              >
                {' '}
                Manual POS entry required
              </label>
            </Col>
          </Row>
        </div>
      </div>
      <div className='row-grid'>
        <div className='form-group'>
          <Row>
            <Col md={1}>
              {filterList.includes('cancelledAggregator') ? (
                <img
                  src={CheckBox}
                  alt='checked'
                  id='cancelledAggregator'
                  onClick={(e) => checkboxHandler('cancelledAggregator')}
                ></img>
              ) : (
                <img
                  src={emptyCheckBox}
                  alt='un-checked'
                  id='cancelledAggregator'
                  onClick={(e) => checkboxHandler('cancelledAggregator')}
                ></img>
              )}
            </Col>
            <Col>
              <label
                htmlFor='cancelledAggregator'
                className='form-group-spacing-img'
                onClick={(e) => checkboxHandler('cancelledAggregator')}
              >
                <div>Cancelled</div>
              </label>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

const DatesFilter = (componentProps) => {
  const { filterList, setFilterList, lastSevenDates, setDateFrom, setDateTo } =
    componentProps;

  const handleRadioButtonClick = (e) =>
    radioBtnHandler(e.target.id, filterList, setFilterList, lastSevenDates);

  return (
    <div className='filter-grid-dates '>
      <div>Dates</div>
      <div
        className='form-check d-flex flex-column'
        style={{ rowGap: '22px', marginBottom: '25px' }}
      >
        <div>
          <input
            className='form-check-input shadow-none'
            type='radio'
            name='filterData'
            id='today'
            checked={filterList.includes('today')}
            onChange={handleRadioButtonClick}
          />
          <label className='form-check-label ms-2' htmlFor='today'>
            Today
          </label>
        </div>
        <div>
          <input
            className='form-check-input shadow-none'
            type='radio'
            name='filterData'
            id='lastSevenDays'
            checked={filterList.includes('lastSevenDays')}
            onChange={handleRadioButtonClick}
          />
          <label className='form-check-label ms-2' htmlFor='lastSevenDays'>
            Last 7 Days
          </label>
        </div>
        <div className='test-grid'>
          <div>
            <input
              className='form-check-input shadow-none'
              type='radio'
              name='filterData'
              id='custom'
              checked={isCustomRadioFilterActive(filterList)}
              onChange={handleRadioButtonClick}
            />
          </div>

          <div>
            <label className='form-check-label ms-2 me-3' htmlFor='custom'>
              Custom
            </label>
          </div>
          <div>
            <div className='d-flex'>
              <SVTOrderHistoryFilterDates
                lastSevenDates={lastSevenDates.datesList}
                selectDate={lastSevenDates?.fromDay}
                setSelectDate={setDateFrom}
                isCustomRadioFilterActive={isCustomRadioFilterActive(
                  filterList
                )}
                endFrom={lastSevenDates?.endFrom}
              />
              <span className='ps-3 pe-3'>to</span>
              <SVTOrderHistoryFilterDates
                lastSevenDates={lastSevenDates.datesList}
                selectDate={lastSevenDates?.toDay}
                setSelectDate={setDateTo}
                startFrom={lastSevenDates.startFrom}
                isCustomRadioFilterActive={isCustomRadioFilterActive(
                  filterList
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function SVTOrderHistoryFilterPopup(props) {
  const [filterList, setFilterList] = useState(props.orderHistoryFilter);
  const [lastSevenDates, setLastSevenDates] = useState({
    datesList: [],
    fromDay: '',
    toDay: '',
    startFrom: undefined,
    endFrom: undefined,
  });

  const setLastDates = () => handleSetLastDates(setLastSevenDates);
  const defaultSelectedHandler = () => setDefaults(setFilterList, setLastDates);

  useEffect(() => {
    if (props.orderHistoryFilter?.length === 0) {
      defaultSelectedHandler();
    } else {
      setFilterList(props.orderHistoryFilter);
    }
  }, [props.orderHistoryFilter]);
  useEffect(() => {
    setLastDates();
  }, []);

  const checkboxHandler = (id) => {
    let tmpFilterList = filterList.slice();
    tmpFilterList = getTmpFilterListforcheckboxHandler(tmpFilterList, id);

    setFilterList(tmpFilterList.slice());
  };

  const setDateFrom = (date) => {
    let indexOfDateFrom = -1;
    indexOfDateFrom = getIndexOfDateFrom(indexOfDateFrom, lastSevenDates, date);

    setLastSevenDates((prevState) => ({
      ...prevState,
      fromDay: date,
      startFrom: indexOfDateFrom,
    }));
  };
  const setDateTo = (date) => {
    let endFrom = null;
    endFrom = getEndFrom(endFrom, lastSevenDates, date);

    setLastSevenDates((prevState) => ({
      ...prevState,
      toDay: date,
      endFrom: endFrom,
    }));
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      data-test={props['data-test']}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      dialogClassName='modal-custom-width'
    >
      <Modal.Header id='histoy_filter_popup'>
        <Modal.Title id='contained-modal-title-vcenter'>
          <p className='m-0 p-0 normal-font fw-bold'>Filter</p>
        </Modal.Title>
        <img
          src={filter_cross}
          onClick={props.onHide}
          alt='cross icon'
          className='edit-order-cursor-pointer'
        />
      </Modal.Header>
      <Modal.Body id='history-filter-body'>
        <div className='d-flex flex-column' style={{ rowGap: '30px' }}>
          <PartnerFilter
            filterList={filterList}
            checkboxHandler={checkboxHandler}
          />

          <OrderStatusFilter
            filterList={filterList}
            checkboxHandler={checkboxHandler}
          />

          {DatesFilter({
            filterList: filterList,
            setFilterList: setFilterList,
            lastSevenDates: lastSevenDates,
            setDateFrom: setDateFrom,
            setDateTo: setDateTo,
          })}
        </div>

        <div className='filter-modal-footer'>
          <div className='filter-modal-footer-buttons'>
            <div>
              <button className='reset-btn' onClick={defaultSelectedHandler}>
                Reset
              </button>
            </div>
            <div>
              <button className='cancel-btn' onClick={props.onHide}>
                Cancel
              </button>
              <button
                className='apply-btn'
                onClick={() => submitHandler(filterList, lastSevenDates, props)}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function mapState(state) {
  const { orderManagementRelated } = state;
  const { orderHistoryFilter } = orderManagementRelated;
  return { orderHistoryFilter };
}
const actionCreators = {};

export default compose(
  withRouter,
  connect(mapState, actionCreators)
)(SVTOrderHistoryFilterPopup);
