import React, { useContext, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap'
import { connect } from 'react-redux';
import { orderManagementActions } from '../../action/SVTOrderManagementAction';
import { StoreClosureContext } from '../../contexts/StoreClosureContext';
import risk_icon from '../../images/Risk.svg'
import cross_icon from '../../images/cross_icon.svg';
import { formatReasonString, storeClosureReasons } from '../../helper/closureReason';

const Modal = (props) => {

  const {toggleModal, toggleStoreStatus, storeDetails: { store_status: storeStatus = 'Online' } = {},} = props;

  const {storeClosureReason, setStoreClosureReason} = useContext(StoreClosureContext);

  const online = storeStatus.toLowerCase() === 'online';
  const title = online ? "Change store status to 'Closed'" : "Change store status to 'Open'";
  const onConfirm = () => {
    const reason = online ? storeClosureReason : '';
    toggleStoreStatus(storeStatus, reason, props);
    toggleModal()
  }

  const children = online ? (
    <div>
      <Row className='mt-3'>
        <div className='open-close-modal-message-container'>
          <img src={risk_icon} alt='info icon'/>
          <span className='open-close-modal-message'>When store is 'Closed', OrderIn will no longer receive any orders until its status is manually changed to 'Open'.  You can still finish already existing orders.</span>
        </div>
      </Row>
      <Row className='mt-3'>
        <div>
          <p>Reason for changing store status to 'Closed':</p>
            {storeClosureReasons.map(reason => {
              return (
                <Row className='mt-3'>
                  <div >
                    <input
                      id={reason}
                      type="radio"
                      name='flexRadioDefault'
                      value={reason}
                      checked={storeClosureReason === reason}
                      onChange={() => {
                        setStoreClosureReason(reason)
                        console.log("storeClosureReason=%s, reason=%s", storeClosureReason, reason, storeClosureReason === reason);
                      }}
                      className='form-check-input shadow-none'
                    />
                    <label className='form-check-label ms-3' htmlFor={reason}>
                      {formatReasonString(reason)}
                    </label>
                  </div>
                </Row>
              )
          })}
        </div>
      </Row>
    </div>
  ) : (
    <div className='open-close-modal-message-container'>
      <img src={risk_icon} alt='info icon'/>
      <span className='open-close-modal-message'>When store is 'Open', OrderIn will receive Home Delivery orders until its status is manually changed to 'Closed'.</span>
    </div>
  );

  return (
    <div className="modalOverlay">
      <div className="open-close-modal">
        <div className='open-close-modal-header'>
          {title && <h2 className='open-close-modal-title'>{title}</h2>}
          <div className=''>
            <img
              src={cross_icon}
              height='21.98px'
              width='21.98px'
              onClick={toggleModal}
              alt='cross icon'
            />
          </div>
        </div>
        <div className="modal-divider"></div>
        {children}
        <Row className='mt-3'>
          <div className='modal-buttons'>
              <button type='button' className='green-outline-btn shadow-none' onClick={toggleModal}>Cancel</button>
              <button type='button' className={'shadow-none ms-3 success-btn-modal'} onClick={onConfirm}>Confirm</button>
          </div>
        </Row>
      </div>
    </div>
  );
};

const actionCreators = {
  updateStoreStatus: orderManagementActions.updateStoreStatus,
};

export function mapState(state) {
  const { orderManagementRelated } = state;
  const {
    storeDetails,
  } = orderManagementRelated;
  return {
    storeDetails,
  };
}

export default connect(mapState, actionCreators)(Modal);