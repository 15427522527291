import React from 'react';
import Xcross from '../../images/cross_mark.svg';
import search from '../../images/search.svg';
import menuPageHeaderIcon from '../../images/menuPageHeaderIcon.png';

import { withRouter } from 'react-router';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { orderManagementActions } from '../../action/SVTOrderManagementAction';
import { menuManagementAction } from '../../action/SVTMenuManagementAction';

function SVTMenuHeader(props) {
  const {
    currentActiveTab,
    totalUnavailableCount,
    getAllProductsAndModifiersListFromSearchQuery,
    storeSearchQuery,
  } = props;
  const [query, setQuery] = React.useState('');
  const [focusFlag, setFocusFlag] = React.useState(false);

  React.useEffect(() => {
    if (currentActiveTab !== 'search') {
      setQuery('');
    }
  }, [currentActiveTab]);

  const onKeyDownHandler = (event) => {
    if (event.key === 'Enter') {
      getAllProductsAndModifiersListFromSearchQuery(event.target.value);
      setFocusFlag(false);
    }
  };
  const onSearch = (event) => {
    storeSearchQuery(event.target.value);
    setQuery(event.target.value);
    setFocusFlag(true);
    if (event.target.value.length >= 3) {
      getAllProductsAndModifiersListFromSearchQuery(event.target.value);
    }
    if (event.target.value.length === 0) {
      setFocusFlag(false);
    }
  };
  const clearSearch = () => {
    storeSearchQuery('');
    setQuery('');
    setFocusFlag(false);
  };
  const setOnFocus = () => {
    setFocusFlag(true);
  };
  const setOnBlur = () => {
    setFocusFlag(false);
  };

  return (
    <>
      <main className='menu-header-container' data-test='menuHeaderContainer'>
        <section className='menu-header-left'>
          <div className='top-section-txt'>Menu Management</div>
          {totalUnavailableCount > 0 && (
            <div className='button-section-txt'>
              {totalUnavailableCount} Item(s) unavailable
            </div>
          )}
          {totalUnavailableCount === 0 && (
            <div className='button-section-txt'>All items available</div>
          )}
        </section>
        <section
          className={
            !focusFlag ? 'search-bar' : 'search-bar menu-search-box-shadow'
          }
        >
          <input
            type='text'
            className='form-control input-menu'
            placeholder='Search by Product Name or SAP Number'
            data-testid='searchQuery'
            value={query}
            onKeyDown={onKeyDownHandler}
            onChange={onSearch}
            onFocus={setOnFocus}
            onBlur={setOnBlur}
          />
          <img
            src={query.length === 0 ? search : Xcross}
            alt='cross'
            className=' float-end search-img cursor-pointer'
            style={{ zIndex: '9' }}
            width='22.93px'
            height='22.93px'
            onClick={clearSearch}
          />
        </section>
        <section className='right-container'>
          <img
            src={menuPageHeaderIcon}
            alt='menuPageHeaderIcon'
            className='float-end'
          />
        </section>
      </main>
    </>
  );
}

export function mapState(state) {
  const { orderManagementRelated, menuManagementRelated } = state;
  const { menuSearchResults } = orderManagementRelated;
  const { currentActiveCategory, totalUnavailableCount } =
    menuManagementRelated;
  return {
    menuSearchResults,
    currentActiveCategory,
    totalUnavailableCount,
  };
}
const actionCreators = {
  getSearchQuery: orderManagementActions.getSearchQuery,
  getAllProductsAndModifiersListFromSearchQuery:
    menuManagementAction.getAllProductsAndModifiersListFromSearchQuery,
  storeSearchQuery: menuManagementAction.storeSearchQuery,
};

export default compose(
  withRouter,
  connect(mapState, actionCreators)
)(SVTMenuHeader);
