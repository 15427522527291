import { constants } from '../constant/reducerConstant';
import { apiUrl } from '../url/apiUrl';
import { store } from '../helper/store';
import { getFlags } from './launchDarkly';

const contentType = 'application/json';

export const LogType = {
  INFO: 'INFO',
  ERROR: 'ERROR',
  WARNING: 'WARNING',
};

export async function log(title, message, logType) {
  const flags = getFlags();
  const enableFrontendLogs = flags['frontendLogging'];
  if (enableFrontendLogs) {
    const data = {
      title: title,
      message: message,
      type: logType,
    };
    const requestOptions = {
      method: 'POST',
      headers: {
        code: store.getState().authInfo?.accessToken,
        token:
          store.getState().orderManagementRelated?.storeDeviceToken?.device_token,
      },
      body: JSON.stringify(data),
    };
    try {
      const url = `${apiUrl.url}/frontend_logs`;
      //make a post request to save the logs
      await fetch(url, requestOptions);
    } catch (error) {
      console.log('Error:', error);
    }
  }

}

export function handleClickTouchEvents(clientX, clientY, screenX, screenY) {
  // const date = new Date();
  // const agent = navigator.userAgent;
  // const currentAccessToken = sessionStorage.getItem('persist:root');
  // const data = JSON.parse(JSON.parse(currentAccessToken)['orderManagementRelated']);
  // const storeDeviceToken = data['storeDeviceToken'];
  // const partnerList = data['partnerList']['partners'];
  // const storeDetails = JSON.parse(JSON.parse(currentAccessToken)['orderManagementRelated'])['storeDetails']
  // const siteId = storeDetails['site_id'];
  // const storeId = storeDetails['store_id'];
  // const currentUrl = window.location.href;
  //backend has not yet been implemented, so this one is for future development
  // console.error({data:data, storeDeviceToken:storeDeviceToken, partnerList:partnerList})
  //
  // console.warn({
  //   currentUrl:currentUrl,
  //   clientX:clientX, clientY:clientY,
  //   screenX:screenX, screenY:screenY,
  //   date:date,
  //   agent: agent,
  //   siteId: siteId,
  //   store: storeDetails
  // } );
  // orderManagementServices.postClickTouchEvents({
  //   date: date,
  //   agent: agent,
  //   siteId: siteId,
  //   storeId: storeId,
  //   clientX:clientX, clientY:clientY,
  //   screenX:screenX, screenY:screenY,
  // }).then(data => {
  //   return data;
  // });
}

export function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = new Error((data && data.message) || response.statusText);
      error.code = response?.status;
      throw error;
      //We are thowing error so below code will never runs
      // return Promise.reject(error);
    }
    return data;
  });
}
export const startLoader = () => {
  window.scrollTo(0, 0);
  return { type: constants.SHOW_LOADER };
};

export const stopLoader = () => {
  return { type: constants.HIDE_LOADER };
};

export function showErrorPage(dataError, methoddetails) {
  let errorMessages = {};
  if (dataError !== null) {
    if ('code' in dataError) {
      if (dataError?.code === 401) {
        sessionStorage.setItem('accessToken', '');
      }
      errorMessages = {
        methodDetails: methoddetails,
        message: dataError?.message,
        code: dataError?.code,
      };
    } else {
      errorMessages = {
        methodDetails: methoddetails,
        message: dataError?.message,
      };
    }
  } else {
    errorMessages = {
      methodDetails: methoddetails,
      message: '',
    };
  }
  log(constants.SHOW_ERROR_PAGE, errorMessages, LogType.ERROR);
  return { type: constants.SHOW_ERROR_PAGE, errorMessages };
}
export const hideErrorPage = () => {
  return { type: constants.HIDE_ERROR_PAGE };
};

export function delayForGivenTime(time) {
  return new Promise((res, rej) => {
    setTimeout(() => {
      res(24);
    }, time);
  });
}

export function isAllPartnerSelected(filterList = []) {
  const allPartnerList = ['uberEats', 'deliveroo'];
  for (let index = 0; index < allPartnerList.length; index++) {
    const element = allPartnerList[index];
    if (!filterList.includes(element)) {
      return false;
    }
  }
  return true;
}

export function handleAPIFailure(error, methodName) {
  if (error.code === 401 || error.code === 403) {
    return showErrorPage(error, methodName);
  } else {
    return showErrorNotification(error, methodName);
  }
}

function showErrorNotification(error, methodName) {
  const code = error?.code ?? '';
  const message = error?.message ?? 'Something went wrong, please try again.';
  const msg = `${code} ${methodName} : ${message}`;
  const headerMsgPayload = {
    show: true,
    type: 'failure',
    msg: msg,
    orderId: '',
    partnerId: '',
  };
  log(constants.SET_SHOW_TOP_MSG, headerMsgPayload, LogType.ERROR);
  return { type: constants.SET_SHOW_TOP_MSG, data: headerMsgPayload };
}

export function refreshToken(dispatch) {
  let idToken = store.getState().authInfo?.accessToken;

  if (isJwtExpired(idToken)) {
    console.log('Access token expired');
    const refreshToken = store.getState().authInfo?.sessionData?.refresh_token;

    refreshAccessToken(refreshToken).then((data) => {
      console.log('refreshed token...', data);
      idToken = data.id_token;
      console.log('refreshed ID token...', idToken);

      dispatch(storeAccessToken(idToken));
    });
  }
}

// write a function to test if a JWT token is expired
function isJwtExpired(token) {
  try {
    // Decode the token without verifying its signature
    const payload = JSON.parse(atob(token.split('.')[1]));

    // Check if the "exp" claim is present
    if (!payload.exp) {
      return false; // Token doesn't have an expiration claim
    }

    // Get the current time in seconds since the epoch
    const currentTime = Math.floor(Date.now() / 1000);

    // Compare the current time with the expiration time
    if (currentTime > payload.exp) {
      return true; // Token is expired
    } else {
      return false; // Token is not expired
    }
  } catch (e) {
    console.error('Error decoding JWT', e);
    return false; // Return false if there's an error decoding the token
  }
}

function refreshAccessToken(refreshToken) {
  var urlencoded = new URLSearchParams();
  urlencoded.append('grant_type', 'refresh_token');
  urlencoded.append('client_id', apiUrl.clientId);
  urlencoded.append('refresh_token', refreshToken);

  // Convert client_id and client_secret to a Basic Authentication header
  const basicAuth =
    'Basic ' + btoa(apiUrl.clientId + ':' + apiUrl.clientSecret);

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: basicAuth,
    },
    body: urlencoded,
  };

  return fetch(apiUrl.authUrl, requestOptions)
    .then(handleResponse)
    .then((data) => {
      console.log('refreshed token', data);
      return data;
    });
}

function storeAccessToken(token) {
  return { type: constants.SAVE_ACCESS_TOKEN, token };
}