import { log, LogType } from '../helper/common';
import { apiUrl } from '../url/apiUrl';

export const authServices = {
  callAuth,
  getUserData,
  refreshAccessToken,
};

function callAuth(code, retry = false) {
  const queryParams = new URLSearchParams({
    code: code,
    redirect_uri: apiUrl.redirectUrl
  });

  if (retry) {
    log("Amp redirect failed, Retry is true", "Calling AWS Cognito directly from Frontend", LogType.ERROR);
    return callAWSCognitoDirectlyForAuth(code);
  }

  const url = `${apiUrl.ampCognitoRedirectUri}?${queryParams.toString()}`;

  return fetch(url, {
    method: 'GET',
  })
    .then(handleResponse)
    .then((data) => {
      log('Amp login flow Success.', data, LogType.INFO);
      return data;
    })
    .catch((error) => {
      log('Amp login flow Failure. Redirecting to FE Auth', error, LogType.ERROR);
      //call the apiUrl.loginUrl but with appending the retry=true parameter
      const loginUrl = `${apiUrl.loginUrl}&retry=true`
      window.location.href = loginUrl;
    });
}

function callAWSCognitoDirectlyForAuth(code) {
  var urlencoded = new URLSearchParams();
  urlencoded.append('grant_type', 'authorization_code');
  urlencoded.append('code', code);
  urlencoded.append('client_id', apiUrl.clientId);
  urlencoded.append('redirect_uri', apiUrl.redirectUrl);
  urlencoded.append('client_secret', apiUrl.clientSecret);
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: urlencoded,
  };
  return fetch(`${apiUrl.authUrl}`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      //throw error;
      log('AWS Cognito login Failure in callAWSCognitoDirectlyForAuth.', error, LogType.ERROR);
      throw error;
    });
}

function getUserData(data) {
  const payload = {
    idToken: data,
  };
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: data },
    body: JSON.stringify(payload),
  };
  return fetch(`${apiUrl.url}/authorization-api`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      if (data.statusCode === 400) {
        return Promise.reject(data);
      } else {
        return data;
      }
    });
}

function refreshAccessToken(_refreshtoken) {
  var urlencoded = new URLSearchParams();
  urlencoded.append('client_id', apiUrl.clientId);
  urlencoded.append('token', _refreshtoken);
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: urlencoded,
  };
  return fetch(`${apiUrl.revokeUrl}`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function handleResponse(response) {
  return response.text().then((text) => {
    if (!response.ok) {
      // Check for non-JSON response (HTML error page)
      if (response.headers.get("content-type") === "text/html") {
        const error = "Server returned an error";
        throw new Error(error);
      }
      // Other non-200 responses
      const error = (data && data.message) || response.statusText;
      throw new Error(error);
    }

    // Handle successful response
    const data = text && JSON.parse(text);
    return data;
  });
}
