import React from 'react';
import { orderManagementActions } from '../../action/SVTOrderManagementAction';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { delayForGivenTime } from '../../helper/common';
function SVTOrderListScheduler(props) {
  const {
    getIncomingOrders,
    getAllNotificationsList,
    getJustCancelledOrderList,
    cancelledOrderNotifications,
    incomingOrderNotifications,
    incomingOrders,
    justCancelledOrderList,
    saveIncomingOrderNewNotification,
    saveCancelledOrderNewNotification,
    notificationList,
    currentPath,
    getOrderList,
    accessToken,
    getOrderReportCount,
    trigerEditOrder,
    getIncomingOrdersBackup,
    isOnline,
    getStoreDetails,
    showErrorPage,
  } = props;
  React.useEffect(() => {
    const notificationListSchedulerId = setInterval(() => {
      if (isOnline && !showErrorPage) {
        incomingOrderNewNotificationFilter();
        cancelledOrderNewNotificationFilter();
      }
    }, 600000); //10 mins
    return () => {
      clearInterval(notificationListSchedulerId);
    };
  }, [
    incomingOrderNotifications,
    cancelledOrderNotifications,
    isOnline,
    showErrorPage,
  ]);
  React.useEffect(() => {
    const orderListSchedulerId = setInterval(() => {
      if (isOnline && !showErrorPage) {
        orderListScheduler();
      }
    }, 180000); //3 mins
    return () => {
      clearInterval(orderListSchedulerId);
    };
  }, [trigerEditOrder, isOnline, showErrorPage]);
  React.useEffect(() => {
    const acceptedOrderSchedulerId = setInterval(() => {
      if (isOnline && !showErrorPage) {
        acceptedOrderScheduler();
      }
    }, 60000); // 1 min
    return () => clearInterval(acceptedOrderSchedulerId);
  }, [isOnline, showErrorPage]);

  React.useEffect(async () => {
    const current = new Date();
    const currentMins = current.getMinutes();
    const currentHr = current.getHours();
    const currentTime = current.getTime();
    let execTime;
    if (currentMins === 0) {
      execTime = new Date().setHours(currentHr, 1, 0, 0);
    } else if (currentMins <= 30) {
      execTime = new Date().setHours(currentHr, 31, 0, 0);
    } else {
      execTime = new Date().setHours((currentHr + 1) % 24, 1, 0, 0);
    }
    const timeLeft = execTime - currentTime;
    console.log('all times', execTime, currentTime, timeLeft);
    await delayForGivenTime(timeLeft);
    executeScheduler();
  }, [isOnline, showErrorPage]);

  const executeScheduler = () => {
    storeStatusScheduler();
    const storeStatusSchedulerId = setInterval(() => {
      if (isOnline && !showErrorPage) {
        storeStatusScheduler();
      }
    }, 1800000); //30 mins
    return () => {
      clearInterval(storeStatusSchedulerId);
    };
  };

  const isPresentInIncomingOrderList = (id) => {
    if (incomingOrders?.length === 0) {
      return false;
    }

    for (let index = 0; index < incomingOrders.length; index++) {
      const element = incomingOrders[index];
      if (element.id === id) {
        return true;
      }
    }

    return false;
  };
  const isPresentInCancelledOrderList = (id) => {
    if (getJustCancelledOrderList?.length === 0) {
      return false;
    }

    for (let index = 0; index < justCancelledOrderList.length; index++) {
      const element = justCancelledOrderList[index];
      if (element.id === id) {
        return true;
      }
    }
    return false;
  };
  const incomingOrderNewNotificationFilter = () => {
    if (accessToken && accessToken.length > 0) {
      const tmpArr = [];
      if (incomingOrderNotifications?.length > 0) {
        for (
          let index = 0;
          index < incomingOrderNotifications.length;
          index++
        ) {
          const element = incomingOrderNotifications[index];
          if (isPresentInIncomingOrderList(element.id)) {
            tmpArr.push(element);
          }
        }
      }
      saveIncomingOrderNewNotification(tmpArr);
    }
  };
  const cancelledOrderNewNotificationFilter = () => {
    if (accessToken && accessToken.length > 0) {
      const tmpArr = [];
      if (cancelledOrderNotifications?.length > 0) {
        for (
          let index = 0;
          index < cancelledOrderNotifications.length;
          index++
        ) {
          const element = cancelledOrderNotifications[index];
          if (isPresentInCancelledOrderList(element.id)) {
            tmpArr.push(element);
          }
        }
      }
      saveCancelledOrderNewNotification(tmpArr);
    }
  };
  const orderListScheduler = () => {
    if (trigerEditOrder === false && accessToken?.length > 0) {
      if (props.getIncomingOrders) {
        getIncomingOrders();
        getIncomingOrdersBackup();
        getJustCancelledOrderList();
        getOrderReportCount();
      }
    }
  };
  const acceptedOrderScheduler = () => {
    if (accessToken?.length > 0) {
      if (props.getOrderList) {
        getOrderList();
      }
    }
  };

  const storeStatusScheduler = () => {
    if (accessToken?.length > 0) {
      if (props.getStoreDetails) {
        getStoreDetails();
      }
    }
  };

  React.useEffect(() => {
    if (accessToken && accessToken?.length > 0 && isOnline && !showErrorPage) {
      if (getAllNotificationsList) {
        getAllNotificationsList();
      }
    }
  }, [notificationList, currentPath, isOnline, showErrorPage]);
  return <div></div>;
}
function mapState(state) {
  const { orderManagementRelated, authInfo, errorPageRelated } = state;
  const {
    incomingOrderNotifications,
    cancelledOrderNotifications,
    incomingOrders,
    justCancelledOrderList,
    currentPath,
    notificationList,
    trigerEditOrder,
  } = orderManagementRelated;
  const { accessToken } = authInfo;
  const { showErrorPage } = errorPageRelated;
  return {
    cancelledOrderNotifications,
    incomingOrderNotifications,
    incomingOrders,
    justCancelledOrderList,
    currentPath,
    notificationList,
    accessToken,
    trigerEditOrder,
    showErrorPage,
  };
}
const actionCreators = {
  getIncomingOrders: orderManagementActions.getIncomingOrders,
  getJustCancelledOrderList: orderManagementActions.getJustCancelledOrderList,
  saveIncomingOrderNewNotification:
    orderManagementActions.saveIncomingOrderNewNotification,
  saveCancelledOrderNewNotification:
    orderManagementActions.saveCancelledOrderNewNotification,
  getAllNotificationsList: orderManagementActions.getAllNotificationsList,
  getOrderList: orderManagementActions.getOrderList,
  getOrderReportCount: orderManagementActions.getOrderReportCount,
  getIncomingOrdersBackup: orderManagementActions.getIncomingOrdersBackup,
  getStoreDetails: orderManagementActions.getStoreDetails,
};
export default compose(connect(mapState, actionCreators))(
  SVTOrderListScheduler
);
