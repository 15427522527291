import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LoadingOverlay from 'react-loading-overlay';

import { orderManagementActions } from '../../action/SVTOrderManagementAction';
import Loading from '../../images/Loading.png';
import webIcon from '../../images/bp-icon-globe-web-bp-orange.svg';
import offlineNotificationSound from '../../sound/modem-dailing-connection.mp3';
import { orderManagementServices } from '../../services/SVTOrderManagementService';
import { sort } from 'fast-sort';
const offlineTone = new Audio(offlineNotificationSound);

function OfflinePopupComponent(props) {
  const { show, isLoading, history, setShowTopMsg, setStoreDetails } = props;
  React.useEffect(() => {
    if (!props.deviceTokenDetails) {
      return;
    }

    if (typeof show === 'boolean' && offlineTone.paused) {
      offlineTone.play()?.catch((err) => console.log(err?.message));
    }

    if (show === false) {
      if (history.location.pathname === '/') {
        props.getOrderList();
        props.getOrderReportCount();
        props.getIncomingOrders();
        props.getJustCancelledOrderList();
        props.getStoreDetails();
      } else {
        props.setCurrentPath('/');
        history.push('/');
      }
    }

    if (!show) {
      resumeConnection();
    }
  }, [!show, props.deviceTokenDetails]);

  const resumeConnection = async () => {
    const storeData = await orderManagementServices.getStoreDetails();
    setStoreDetails(storeData);
    console.log('in offline popup', storeData);
    if (storeData?.store_data[0]?.store_status === 'Offline') {
      const allNotificationList =
        await orderManagementServices.getNotificationsList();
      const fullNotificationsList = sort(allNotificationList.data).desc(
        (u) => u['updated_on']
      );

      const storeUnAttended = fullNotificationsList.find((notification) =>
        notification['message'].includes('Tablet Unattended')
      );
      const deviceOffline = fullNotificationsList.find((notification) =>
        notification['message'].includes('due to Connection lost')
      );
      if (storeUnAttended) {
        setShowTopMsg({
          show: true,
          type: 'store_unattended',
          msg: 'Your store has been closed to customer due to the device unattended. Please click here to resume.',
          orderId: '',
          partnerId: '',
        });
      } else if (deviceOffline) {
        const headerMsgPayload = {
          show: true,
          type: 'store_offline',
          msg: 'Your store has been closed to customer due to connectivity error. Please click here to resume.',
          orderId: '',
          partnerId: '',
        };
        setShowTopMsg(headerMsgPayload);
      }
    } else {
      const orderReportListCount =
        await orderManagementServices.getOrderReportCount();
      if (orderReportListCount?.incoming_orders_count > 0) {
        const headerMsgPayload = {
          show: true,
          type: 'store_online',
          msg: 'There were a few orders placed when the site lost connectivity. Refer the listing for details.',
          orderId: '',
          partnerId: '',
        };
        setShowTopMsg(headerMsgPayload);
      }
    }
  };

  return (
    <>
      <LoadingOverlay>
        <Modal
          show={show}
          backdrop='static'
          keyboard={false}
          animation={false}
          centered
          dialogClassName='offline-popup'
        >
          <Modal.Header className='m-1'>
            <Modal.Title>
              <div className='d-flex' data-test='offlinePopupTitle'>
                <p className='font-size-16 fw-bold letter-spacing-O50 m-0 p-0'>
                  Internet Connection Lost
                </p>
                {isLoading && (
                  <img
                    class='rotating-loader'
                    src={Loading}
                    alt='loader'
                    data-test='offlinePopupLoader'
                  />
                )}
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='p-0 m-0 ps-3 pe-3'>
            <Row>
              <Col sm={12} className='m-0 pb-4 pl-15 pr-15'>
                <hr className='m-0 contact-supp-hr' />
              </Col>
              <Col sm={2}>
                <img src={webIcon} alt='icon' />
              </Col>
              <Col sm={10}>
                <div className='letter-spacing-O50 mb-2'>You are offline</div>
                <div className='letter-spacing-O50 dark-shade-gray'>
                  Try checking your internet connection. The session will
                  automatically resume once the internet is back.
                </div>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </LoadingOverlay>
    </>
  );
}
export function mapState(state) {
  const { orderManagementRelated } = state;
  const { storeDetails, deviceTokenDetails } = orderManagementRelated;
  return { storeDetails, deviceTokenDetails };
}

const actionCreators = {
  setCurrentPath: orderManagementActions.setCurrentPath,
  getOrderList: orderManagementActions.getOrderList,
  getOrderReportCount: orderManagementActions.getOrderReportCount,
  getIncomingOrders: orderManagementActions.getIncomingOrders,
  getJustCancelledOrderList: orderManagementActions.getJustCancelledOrderList,
  getStoreDetails: orderManagementActions.getStoreDetails,
  setShowTopMsg: orderManagementActions.setShowTopMsg,
  getAllNotificationsList: orderManagementActions.getAllNotificationsList,
  setStoreDetails: orderManagementActions.setStoreDetails,
};

export default compose(
  withRouter,
  connect(mapState, actionCreators)
)(OfflinePopupComponent);
