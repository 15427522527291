import React from 'react';
import { Dropdown } from 'react-bootstrap';
function SVTOrderHistoryFilterDates(props) {
  let {
    lastSevenDates,
    selectDate,
    setSelectDate,
    startFrom,
    isCustomRadioFilterActive,
    endFrom,
  } = props;
  if (startFrom === 0) {
    lastSevenDates = lastSevenDates.slice(0, 1);
  }
  if (startFrom && startFrom !== 0) {
    lastSevenDates = lastSevenDates.slice(0, startFrom);
  }
  if (endFrom) {
    lastSevenDates = lastSevenDates.slice(endFrom, lastSevenDates.length);
  }

  return (
    <>
      <Dropdown drop='down' className='order-help-dropdown'>
        <Dropdown.Toggle
          id='dropdown-basic'
          className='order-filter-date-dropdown shadow-none'
          style={{ marginTop: '-10px' }}
          disabled={!isCustomRadioFilterActive}
        >
          {selectDate}
        </Dropdown.Toggle>
        <Dropdown.Menu className='order-help-dropdown-menu menu-table'>
          {lastSevenDates?.map((item) => {
            return (
              <>
                <Dropdown.Item
                  className='order-help-dropdown-menu-text ps-0 pt-2 pd-2'
                  onClick={() => setSelectDate(item.Date)}
                >
                  <p className='m-0 p-0 small_text gray_text'>{item.Day}</p>
                  <p className='m-0 p-0'>{item.Date}</p>
                </Dropdown.Item>
                <Dropdown.Divider />
              </>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

export default SVTOrderHistoryFilterDates;
