import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SVTIncomingOrderComponent from './SVTIncomingOrderComponent';
import SVTJustCancelledComponent from './SVTJustCancelledComponent';
import SVTViewOrderDetails from './SVTViewOrderDetails';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import SVTHeaderComponent from '../aggregators/SVTHeaderComponent';
import { orderManagementActions } from '../../action/SVTOrderManagementAction';

function SVTOrderViewComponent(props) {
  let initialOrderTab = 'Incoming';
  switch (props?.match?.params?.type) {
    case 'Incoming':
      break;
    case 'Cancelled':
      initialOrderTab = 'Cancelled';
      break;
    default:
      break;
  }
  const [orderTab, setOrderTab] = useState(initialOrderTab);
  React.useEffect(() => {
    props.setCurrentPath(props.location.pathname);
  });
  return (
    <>
      <SVTHeaderComponent />
      <Container fluid className='parent_block' data-test='orderViewComponent'>
        <Row>
          <Col md={4} sm={12} className='mt-4'>
            <div className='card_block'>
              <Container fluid className='px-0'>
                <Row className='mx-0'>
                  <Col md={6} sm={6} className='px-1'>
                    <div
                      className={`d-flex align-items-center pb-2 pt-3 cursor-pointer
                            ${
                              orderTab === 'Incoming'
                                ? 'active_class'
                                : 'non_active_class'
                            }`}
                      onClick={() => {
                        setOrderTab('Incoming');
                      }}
                      data-test='IncomingTab'
                    >
                      <div
                        className={
                          orderTab === 'Incoming'
                            ? 'active_text'
                            : 'non_active_text'
                        }
                      >
                        Incoming Orders
                      </div>
                      <div className='notification_style green_background-color ms-2'>
                        {props?.incomingOrders?.length}
                      </div>
                    </div>
                  </Col>
                  <Col md={6} sm={6}>
                    <div
                      className={`d-flex align-items-center pb-2 pt-3 cursor-pointer
                            ${
                              orderTab === 'Cancelled'
                                ? 'active_class'
                                : 'non_active_class'
                            }`}
                      onClick={() => {
                        setOrderTab('Cancelled');
                      }}
                      data-test='CancelledTab'
                    >
                      <div
                        className={
                          orderTab === 'Cancelled'
                            ? 'active_text'
                            : 'non_active_text'
                        }
                      >
                        Just Cancelled
                      </div>
                      <div
                        className={
                          props?.justCancelledOrderList?.length === 0
                            ? 'notification_style ms-2 gray_background_color'
                            : 'notification_style ms-2 org_background_color'
                        }
                      >
                        {props?.justCancelledOrderList?.length}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className='mx-0'>
                  <Col md={12} sm={12} className='px-0'>
                    {orderTab === 'Incoming' ? (
                      <SVTIncomingOrderComponent />
                    ) : (
                      <SVTJustCancelledComponent />
                    )}
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
          <Col md={8} sm={12} className='mt-4'>
            <div className='card_block'>
              <div>
                <SVTViewOrderDetails type={props?.match?.params?.type} />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

function mapState(state) {
  const { orderManagementRelated } = state;
  const { justCancelledOrderList, incomingOrders } = orderManagementRelated;
  return { justCancelledOrderList, incomingOrders };
}
const actionCreators = {
  setCurrentPath: orderManagementActions.setCurrentPath,
};

export default compose(
  withRouter,
  connect(mapState, actionCreators)
)(SVTOrderViewComponent);
