import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import UberEatsSVG from '../../images/UberEatsSVG.svg';
import MenuLogSVG from '../../images/logos/deliveroo/deliverooSVG.svg';
import STVOrderHelpDropdown from '../aggregators/SVTOrderHelpDropdown';
import { formatAMPM } from '../aggregators/SVTJustCancelledDetailsComponent';
import CheckBox from '../../images/CheckBox.svg';
import emptyCheckBox from '../../images/emptyCheckBox.svg';
import { orderManagementActions } from '../../action/SVTOrderManagementAction';

const dateTime = '2022-02-18T00:56:01.446804';
export class SVTDriverStatusComponent extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  showOrderList() {
    if (this.props?.path != null) {
      this.props.history.push('/order-history');
    } else {
      this.props.history.push('/order-list');
    }
  }

  checkboxHandler = (id) => {
    this.props.markSalesIngestion(id);
    this.props.history.push('/order-history');
  };
  formatDateToTimeMinutes(newDate) {
    const dateDate = new Date(newDate);
    const hours = dateDate?.getHours().toString().padStart(2, '0');
    const minutes = dateDate?.getMinutes().toString().padStart(2, '0');
    const createdTime = `${hours}:${minutes}`;
    return this.amOrPm(createdTime);
  }
  amOrPm(time = '') {
    const val = time !== undefined ? time.split(':')[0] : time;
    if (parseInt(val) >= 12) {
      return time + `PM`;
    } else if (parseInt(val) < 12) {
      return time + `AM`;
    } else {
      return time;
    }
  }

  render() {
    return (
      <>
        <div className={ifOrderDetailHeader(this.props)}>
          <div className='order-header svt-bold-text pt-2 col-md-6'>
            {this.props?.path != null ? (
              <span
                className='order-status-green-color cursor-pointer'
                onClick={this.showOrderList.bind(this)}
              >
                {'Order History :'}
              </span>
            ) : (
              <span
                className='order-status-green-color cursor-pointer'
                onClick={this.showOrderList.bind(this)}
              >
                {'Orders :'}
              </span>
            )}
            &nbsp;&nbsp;&nbsp;
            <span className='order-id-font-style'>
              #{this.props?.driverStatus?.partner_id?.toUpperCase()}
            </span>
            <div className='d-flex order-title-margin-driver'>
              <div>
                <img
                  className='img-responsive order-image'
                  alt=''
                  src={partenerImage(this.props)}
                />
              </div>
              <div className='order-title'>
                <span>
                  {this.props?.driverStatus?.partner_id?.toUpperCase()}
                  &nbsp;-&nbsp;
                  {this.props?.driverStatus?.customer_name}
                </span>
              </div>
            </div>
          </div>

          {driverStatusConditionCheck(this.props) ? (
            <div className='order-header pt-2 col-md-6 col-lg-6 col-xl-6 col-sm-6 col-xxl-6'>
              <div className='pos-manual-entry-details'>
                {driverStatusSuccessCheck(this.props, this.checkboxHandler)}

                <span>
                  <span className='pos-order-title'>Order entered in POS?</span>
                  <br />
                  <span className='pos-order-details'>
                    Please check the box if details have been entered.
                  </span>
                </span>
              </div>
            </div>
          ) : (
            ''
          )}

          {ifOrderHelpDropdown(this.props)}
        </div>

        {/* Green Status bar */}

        {driverStatusCheck(this.props)}

        {this.props?.driverStatus?.sale_ingestion?.toLowerCase() ===
          'success' && this.props?.type !== 'orderDetailHeader' ? (
          <div className='order-header-bar order-header-text d-flex white-text'>
            <div className=''>
              Completed at{' '}
              {formatAMPM(
                new Date(this.props?.driverStatus?.updated_on ?? dateTime)
              )}
            </div>
          </div>
        ) : (
          ''
        )}

        {/* Status bar for Cancelled */}
        {statusBarForCancelled(this.props)}

        {statusBarForCancelled02(this.props)}

        {/* Instruction section */}
        <div className='order-instructions d-flex'>
          <div className='order-instruction-text svt-bold-text'>
            {'Special Instructions:'}
          </div>
          <div className='pl-4'>
            {this.props?.driverStatus?.delivery_instructions}
          </div>
        </div>

        {}
      </>
    );
  }
}
function mapState(state) {
  const { orderManagementRelated } = state;
  return orderManagementRelated;
}
const actionCreators = {
  markSalesIngestion: orderManagementActions.markSalesIngestion,
};
const ifOrderDetailHeader = (props) => {
  return props?.type === 'orderDetailHeader'
    ? 'd-flex justify-content-between order-title-margin'
    : 'd-flex row order-title-margin';
};
const partenerImage = (props) => {
  return props?.driverStatus?.partner_name === 'Uber Eats'
    ? UberEatsSVG
    : MenuLogSVG;
};
const driverStatusConditionCheck = (props) => {
  return (
    props?.driverStatus?.sale_ingestion !== undefined &&
    props?.type !== 'orderDetailHeader' &&
    props?.driverStatus?.sale_ingestion?.toLowerCase() !== ''
  );
};
const driverStatusSuccessCheck = (props, checkboxHandler) => {
  return props?.driverStatus?.sale_ingestion?.toLowerCase() === 'success' ? (
    <img src={CheckBox} alt='checked' id='sale_ingestion' onClick=''></img>
  ) : (
    <img
      src={emptyCheckBox}
      alt='un-checked'
      id='failed'
      onClick={(e) => checkboxHandler(props?.driverStatus?.id)}
    ></img>
  );
};
const ifOrderHelpDropdown = (props) => {
  return props?.type === 'orderDetailHeader' ? (
    <div className=''>
      <STVOrderHelpDropdown
        btnClass={'success-border-btn me-2'}
        order={props?.driverStatus}
        path={'dummy'}
        partnerName={props?.driverStatus?.partner_name}
        orderType={props?.orderType}
        shouldShowCancelBtn={false}
        shouldShowEditBtn={false}
      />
    </div>
  ) : null;
};
const driverStatusCheck = (props) => {
  return (props?.driverStatus?.sale_ingestion?.toLowerCase() !== 'success' ||
    props?.type === 'orderDetailHeader') &&
    (props?.driverStatus?.partner_name.toLowerCase() === 'uber eats' ||
      // eslint-disable-next-line no-mixed-operators
      (props?.driverStatus?.partner_name.toLowerCase() === 'deliveroo' &&
        props?.driverStatus?.status.toLowerCase() === 'accepted')) ? (
    <div className='order-header-bar order-header-text d-flex white-text'>
      {props?.driverStatus?.partner_status.toLowerCase() ===
        'driver on the way' &&
      props?.driverStatus?.partner_name.toLowerCase() === 'uber eats' && (
        <div>
          ETA: {props?.driverStatus?.eta_pickup}
          &nbsp;mins
        </div>
      ) || null}
      <div className='order-contact text-capitalize'>
        {props?.driverStatus?.partner_name.toLowerCase() === 'uber eats'
          && props?.driverStatus?.partner_status
          || null}

        {props?.driverStatus?.partner_name.toLowerCase() === 'deliveroo' &&
        props?.driverStatus?.status.toLowerCase() === 'accepted'
          && props?.driverStatus?.partner_status
          || null}
      </div>
    </div>
  ) : null;
};
const statusBarForCancelled = (props) => {
  return props?.driverStatus?.sale_ingestion?.toLowerCase() !== 'success' &&
    props?.driverStatus?.partner_status.toLowerCase() === 'cancelled' ? (
    props?.driverStatus?.cancelled_reason && (
      <>
        <div className='cancel-order-header'>
          <div className='order-header-text'>
            Cancelled by {props?.driverStatus.cancelled_by} at &nbsp;
            {formatAMPM(
              new Date(props?.driverStatus?.cancelled_at ?? dateTime)
            )}
          </div>
          <div className='mt-1'>
            <span className='driver-status-reason'>Reason</span>
            :&nbsp;&nbsp;
            {props?.driverStatus?.cancelled_reason}
          </div>
        </div>
      </>
    ) || (
      <div className='cancel-order-header' style={{ height: '57px' }}>
        <div className='order-header-text'>
          Cancelled by {props?.driverStatus.cancelled_by} at &nbsp;
          {formatAMPM(new Date(props?.driverStatus?.cancelled_at ?? dateTime))}
        </div>
      </div>
    )
  ) : null;
};
const statusBarForCancelled02 = (props) => {
  return props?.driverStatus?.sale_ingestion?.toLowerCase() === 'failed' &&
    props?.type !== 'orderDetailHeader' ? (
    <div className='cancel-order-header' style={{ height: '57px' }}>
      <div className='order-header-text'>
        POS entry failed, please enter details manually
      </div>
    </div>
  ) : null;
};

export default compose(
  withRouter,
  connect(mapState, actionCreators)
)(SVTDriverStatusComponent);
