import React, { useEffect } from 'react';
import SVTJustCancelledDetailsComponent from './SVTJustCancelledDetailsComponent';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { orderManagementActions } from '../../action/SVTOrderManagementAction';
import uber_eats from '../../images/UberEats.svg';
import menuLog from '../../images/logos/deliveroo/deliverooSVG.svg';

function SVTJustCancelledComponent(props) {
  useEffect(() => {
    props.getJustCancelledOrderList();
  }, []);

  return (
    <div className='mt-1 fixed_block'>
      {props?.justCancelledOrderList
        ?.sort((a, b) => a.id - b.id)
        .map((order) => (
          <div
            className='Accordion_Style'
            key={order.id}
            id={order.partner_order_id}
          >
            <SVTJustCancelledDetailsComponent
              image={order.partner_name === 'Uber Eats' ? uber_eats : menuLog}
              ordersData={order}
            />
          </div>
        ))}
    </div>
  );
}
export function mapState(state) {
  const { orderManagementRelated } = state;
  const { justCancelledOrderList } = orderManagementRelated;
  return { justCancelledOrderList };
}
const actionCreators = {
  getJustCancelledOrderList: orderManagementActions.getJustCancelledOrderList,
};

export default compose(
  withRouter,
  connect(mapState, actionCreators)
)(SVTJustCancelledComponent);
