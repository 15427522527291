import React from 'react';
import menu_available from '../../images/menu_available.svg';
class SVTMenuAllItemsAvailable extends React.Component {
  render() {
    return (
      <>
        <div className='d-flex align-items-center justify-content-center menu-all-available-height'>
          <div className='row'>
            <img
              src={menu_available}
              alt='menu available'
              width='auto'
              height='96px'
            />
            <br />
            <div className='text-center black-txt-txt font-size-16 menu-all-item-available-text'>
              All items are available.
            </div>
            <br />
            <div className='text-center light-grey-txt font-size-16 '>
              Unavailable items will <br />
              show here
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default SVTMenuAllItemsAvailable;
