import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import cross_icon from '../../images/cross_icon.svg';
import { Row, Col, Modal } from 'react-bootstrap';
import risk_icon from '../../images/Risk.svg';
import moveToHistory from '../../images/move-to-history.svg';
import { orderManagementActions } from '../../action/SVTOrderManagementAction';
import crossCircle from '../../images/crossCircle.svg';
import { orderManagementServices } from '../../services/SVTOrderManagementService';

const orderUpdateActionString = 'Update Order';
const orderCompleteActionString = 'Completed';
const moveToOrderHistoryString = 'Move to Order History';
const orderCancelledString = 'Cancelled';
const orderSuccessString = 'SUCCESS';

const handlePopUpAction = async (
  action,
  props,
  order,
  selectedOrderDetails,
  updateOrderDetails,
  onHide
) => {
  if (action === moveToOrderHistoryString) {
    props.cancelOrder([selectedOrderDetails.id]);
  } else if (action === orderCancelledString) {
    const payload = {
      id: order?.id,
      status: 'Rejected',
      reason: 'OUT_OF_ITEMS',
    };
    const response = await orderManagementServices.updateOrderStatus(payload);

    if (response.status === orderSuccessString) {
      const headerMsgPayload = {
        show: false,
        type: '',
        msg: ``,
        orderId: '',
        partnerId: 0,
      };
      props.setShowTopMsg(headerMsgPayload);
      window.location.reload();
    }

    props.history.push('/');
  } else if (action === orderUpdateActionString) {
    const orderItemPayload = [];
    updateOrderDetails?.order_items.forEach((product) => {
      const orderItemObj = {
        item_id: product?.plu,
        quantity: product?.quantity,
        reason:
          product?.quantity === 0 ? 'OUT_OF_ITEM' : 'PARTIAL_AVAILABILITY',
      };
      orderItemPayload.push(orderItemObj);
    });
    const payload = {
      id: updateOrderDetails?.id,
      status: 'Accepted',
      order_items: orderItemPayload,
    };
    const response = await orderManagementServices.updateAcceptedOrder(payload);
    if (response?.status !== orderSuccessString) {
      return;
    }
    const headerMsgPayload = {
      show: true,
      type: 'partialAcceptedOrderAfterAccepted',
      msg: `*partail accept after accepting the order`,
      orderId: '',
      partnerId: updateOrderDetails?.partner_id?.toUpperCase(),
    };
    props.setShowTopMsg(headerMsgPayload);
    onHide();
    props.history.push('/');
  } else if (action === orderCompleteActionString) {
    props.completeOrder([selectedOrderDetails.id]);
  }
  onHide(false);
  props.history.push('/');
};

const onMountComponent = (action, setPopUpData, props) => {
  if (action === moveToOrderHistoryString) {
    setPopUpData({
      headerImg: <img src={risk_icon} alt='Risk' className='ps-2' />,
      headerMsg: 'Move to Order History?',
      sideImg: (
        <img
          src={moveToHistory}
          alt='move to order history'
          className='cancel-order-image'
        />
      ),
      firstMsg: 'Do you want to move this to Order History?',
      secondMsg:
        'Moving to Order History will not cancel the order. If you need to cancel the order, please contact Menulog Support.',
      acceptBtnText: 'Confirm',
    });
  } else if (action === orderCancelledString) {
    setPopUpData({
      headerImg: <img src={risk_icon} alt='Risk' className='ps-2' />,
      headerMsg: 'Cancel Order',
      sideImg: (
        <img
          src={crossCircle}
          alt='cancel order'
          className='cancel-order-image'
        />
      ),
      firstMsg: 'Do you want to cancel this order?',
      secondMsg:
        'Cancelling an order will change its status to cancelled and move it to the order history page.',
      acceptBtnText: 'Cancel Order',
    });
  } else if (action === orderUpdateActionString) {
    setPopUpData({
      headerImg: null,
      headerMsg: 'Confirm Changes',
      sideImg: null,
      firstMsg: (
        <>
          {' '}
          Are you sure you want to save the changes made to order{' '}
          <strong>
            #{props?.selectedOrderDetails?.partner_id?.toUpperCase()}{' '}
          </strong>
          ?
        </>
      ),
      secondMsg: null,
      acceptBtnText: orderUpdateActionString,
    });
  } else if (action === orderCompleteActionString) {
    setPopUpData({
      headerImg: null,
      headerMsg: 'Order picked up by driver?',
      sideImg: null,
      firstMsg: 'Do you want to mark this order as completed?',
      secondMsg:
        'If confirmed, you can view the order from the order history page.',
      acceptBtnText: 'Confirm',
    });
  }
};
function SVTCompleteSingleOrderPopUp(props) {
  const {
    show,
    onHide,
    selectedOrderDetails,
    action,
    updateOrderDetails,
    order,
  } = props;
  const [popUpData, setPopUpData] = React.useState({});

  React.useEffect(() => {
    onMountComponent(action, setPopUpData, props);
  }, [props.action]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      size='md'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      data-test='completeOrderPopUp'
    >
      <Modal.Header>
        <Modal.Title id='contained-modal-title-vcenter'>
          <div className='d-flex'>
            {popUpData['headerImg']}
            <p className='m-0 p-0 normal-font fw-bold ps-2'>
              {popUpData['headerMsg']}
            </p>
          </div>
        </Modal.Title>
        <img
          src={cross_icon}
          height='21.98px'
          width='21.98px'
          onClick={() => onHide(false)}
          className='cursor-pointer'
          data-test='closeIcon'
          alt='cross icon'
        />
      </Modal.Header>
      <Modal.Body className='p-0 m-0 ps-3 pe-3'>
        <Row>
          <Col sm={12} className='m-0 pb-3 pl-20'>
            <hr className='m-0 contact-supp-hr' />
          </Col>
          <div
            className={
              action === orderCompleteActionString ||
              action === orderUpdateActionString
                ? 'm-0 p-0'
                : 'modal-body-container'
            }
          >
            {popUpData['sideImg']}
            <div>
              <Col md={12} className='complete-order-body-text pl-20 mb-10'>
                {popUpData['firstMsg']}
              </Col>
              <Col md={12} className='complete-order-footer-text pl-20'>
                {popUpData['secondMsg']}
              </Col>
            </div>
          </div>
        </Row>
      </Modal.Body>
      <Modal.Footer className='pt-20'>
        <button
          type='button'
          data-test='closeBtn'
          className='green-outline-btn shadow-none letter-spacing-125'
          onClick={() => onHide(false)}
        >
          {action === orderCompleteActionString ||
          action === orderUpdateActionString
            ? 'Cancel'
            : 'Back'}
        </button>
        <button
          type='button'
          data-test='actionSuccessButton'
          className={' shadow-none ms-3 success-btn-modal letter-spacing-125'}
          onClick={() =>
            handlePopUpAction(
              action,
              props,
              order,
              selectedOrderDetails,
              updateOrderDetails,
              onHide
            )
          }
        >
          {popUpData['acceptBtnText']}
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export function mapState(state) {
  const { orderManagementRelated } = state;
  const { selectedOrderDetails } = orderManagementRelated;
  return { selectedOrderDetails };
}
const actionCreators = {
  setShowTopMsg: orderManagementActions.setShowTopMsg,
  completeOrder: orderManagementActions.completeOrder,
  cancelOrder: orderManagementActions.cancelOrder,
  cancelAcceptedOrder: orderManagementActions.cancelAcceptedOrder,
};

export default compose(
  withRouter,
  connect(mapState, actionCreators)
)(SVTCompleteSingleOrderPopUp);
